import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useEmailsValidation from '../../../../hooks/useEmailsValidation';
import useVatNoValidation from '../../../../hooks/useVatNoValidation';
import useNumLengthValidation from '../../../../hooks/useNumLengthValidation';
import useCurrencyStore from '../../../../store/currency';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import SelectComponent from '../../../../components/select';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllClients from '../getAllClients';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSearch from '../../../../components/selectSearch';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};

type Props = {
    t: Function;
    clickedClient: string;
    userId: string;
    setAddClientModal: Dispatch<SetStateAction<boolean>>;
    setClients: Dispatch<SetStateAction<ObjectKeys[]>>;
    tabIndex: number;
    setTabIndex?: Dispatch<SetStateAction<number>>;
    setNewCreatedClient?: Dispatch<SetStateAction<ObjectKeys>>;
    isDuplicate?: Function;
};

const AddClientModal: FunctionComponent<Props> = ({
    t,
    clickedClient,
    userId,
    setAddClientModal,
    setClients,
    tabIndex,
    setTabIndex,
    setNewCreatedClient,
    isDuplicate,
}) => {
    const { currentLang } = useLanguageStore();
    const { currency } = useCurrencyStore();
    const [formData, setFormData] = useState<ObjectKeys>({
        name: '',
        taxNumber: '',
        identificationNumber: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        clientCategory: 'legal',
        personalId: '',
        vatNumber: '',
        jbkjs: '',
        currency: 'EUR',
    });
    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [clickedField, setClickedField] = useState('');
    const [selectedClientCategory, setSelectedClientCategory] = useState('');
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [clientCategories, setClientCategories] = useState<Array<{ value: string; label: string }>>([]);

    const clientEmail = formData.email;
    const [showEmails, setShowEmails] = useState(true);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>(clientEmail ? [clientEmail] : []);
    const [showError, setShowError] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState<SelectOption[]>([]);
    const [jbkjsOptions, setJbkjsOptions] = useState<SelectOption[]>([]);

    const isNameValid = useCharLengthValidation(formData.name, 3, true);
    const isEmailValid = useEmailsValidation(formData.email, emails, false);
    const isAddressValid = useCharLengthValidation(
        formData.address,
        3,
        formData.clientCategory === 'civil' ? false : true,
    );
    const isCityValid = useCharLengthValidation(formData.city, 3, formData.clientCategory === 'civil' ? false : true);
    const isStateValid = useCharLengthValidation(formData.state, 3, true);
    const isVatNoValid = useVatNoValidation(formData.vatNumber);
    const isIdentificationNumValid = useNumLengthValidation(formData.identificationNumber, 8);
    const isJbkjsValid = useNumLengthValidation(formData.jbkjs, 5);
    const isPersonalIdValid = useNumLengthValidation(formData.personalId, 13);

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    useEffect(() => {
        setClientCategories([
            { value: 'civil', label: t('pages.clients.modal.form.domestic.clientCategory.civil').text },
            { value: 'legal', label: t('pages.clients.modal.form.domestic.clientCategory.legal').text },
            { value: 'public', label: t('pages.clients.modal.form.domestic.clientCategory.public').text },
        ]);
    }, []);

    useEffect(() => {
        setFormData({
            name: '',
            taxNumber: '',
            identificationNumber: '',
            phoneNumber: '',
            email: '',
            address: '',
            city: '',
            state: '',
            clientCategory: selectedClientCategory ? selectedClientCategory : 'legal',
            personalId: '',
            vatNumber: '',
            jbkjs: '',
            currency: 'EUR',
        });
        setClickedField('');
    }, [selectedClientCategory]);

    const handleGetAgencyData = async (): Promise<void> => {
        formData.vatNumber !== '' &&
            communication
                .getAgencyDataBasedOnVatNo(formData.vatNumber)
                .then((res: ObjectKeys) => {
                    const clientData = res?.data?.data;
                    if (Array.isArray(clientData.phone) && clientData.phone.length > 0) {
                        setPhoneNumbers(clientData.phone.map((phone: any) => ({ value: phone, label: phone })));
                    } else {
                        setPhoneNumbers([]); // Reset if it's not an array or has only one value
                    }
                    setJbkjsOptions(
                        clientData?.public_company_ids?.map(({ jbbk, naziv, address, mesto }: any) => ({
                            value: {
                                jbbk: String(jbbk).padStart(5, '0'),
                                address,
                                mesto,
                            },
                            label: `${String(jbbk).padStart(5, '0')} - ${naziv}`,
                        })) || [],
                    );

                    setEmails(clientData.email);
                    setFormData({
                        name:
                            clientData?.company_name?.length > 1
                                ? clientData?.company_name.slice(0, 170)
                                : clientData?.company_name_short
                                ? clientData?.company_name_short.slice(0, 170)
                                : '',
                        taxNumber: clientData?.pib ? clientData?.pib : '',
                        identificationNumber: clientData?.identification_number
                            ? clientData?.identification_number
                            : '',
                        phoneNumber: '',
                        email: '',
                        address: clientData?.address ? clientData?.address.slice(0, 170) : '',
                        city: clientData?.city ? clientData?.city : '',
                        state: clientData?.country ? clientData?.country : '',
                        personalId: clientData?.jmbg ? clientData?.jmbg : '',
                        clientCategory: selectedClientCategory ? selectedClientCategory : formData.clientCategory,
                        vatNumber: clientData?.pib ? clientData?.pib : '',
                        jbkjs: '',
                        currency:
                            clientData?.currency !== null
                                ? {
                                      value: clientData?.currency,
                                      label: clientData?.currency,
                                  }
                                : null,
                    });
                })
                .catch((error: ObjectKeys) => {
                    toast.error(error.response.data.message);
                });
    };

    const getValidation = (): any => {
        if (clickedClient === 'domestic') {
            if (formData.clientCategory === 'civil') {
                const personalId = formData.personalId;
                if (
                    (isEmailValid === '' &&
                        isNameValid === '' &&
                        personalId.length > 0 &&
                        personalId.length === 13 &&
                        isAddressValid === '' &&
                        isCityValid === '') ||
                    (isEmailValid === '' &&
                        isNameValid === '' &&
                        personalId.length === 0 &&
                        isAddressValid === '' &&
                        isCityValid === '')
                ) {
                    return ''; // No validation errors
                }
            } else if (formData.clientCategory === 'legal') {
                if (
                    isVatNoValid === '' &&
                    isIdentificationNumValid === '' &&
                    isNameValid === '' &&
                    isAddressValid === '' &&
                    isCityValid === '' &&
                    isEmailValid === ''
                ) {
                    return '';
                }
            } else if (formData.clientCategory === 'public') {
                if (
                    isVatNoValid === '' &&
                    isJbkjsValid === '' &&
                    isIdentificationNumValid === '' &&
                    isNameValid === '' &&
                    isAddressValid === '' &&
                    isCityValid === '' &&
                    isEmailValid === ''
                ) {
                    return '';
                }
            }
        } else {
            if (
                isNameValid === '' &&
                isAddressValid === '' &&
                isCityValid === '' &&
                isStateValid === '' &&
                isEmailValid === ''
            ) {
                return '';
            }
        }
    };

    const addNewClient = (): void => {
        setShowValidationMessage(true);

        if (getValidation() === '') {
            setAddClientModal(false);
            communication
                .addClient({
                    address: formData.address,
                    city: formData.city,
                    company_name: formData.name,
                    country: clickedClient === 'domestic' ? 'RSD' : formData.state,
                    currency: clickedClient === 'domestic' ? 'RSD' : formData.currency,
                    emails: emails,
                    identification_number: formData.identificationNumber,
                    is_foreign: clickedClient === 'domestic' ? false : true,
                    jmbg: formData.personalId ? formData.personalId : null,
                    phone: formData.phoneNumber,
                    pib: clickedClient === 'domestic' ? formData.vatNumber : formData.taxNumber,
                    public_company_id: formData.jbkjs ? formData.jbkjs : null,
                    type: clickedClient === 'domestic' ? formData.clientCategory : null,
                    user_id: userId,
                })
                .then((res: ObjectKeys) => {
                    const duplicated = res.data.data.duplicated;
                    if (duplicated && isDuplicate) {
                        setAddClientModal(false);
                        isDuplicate(res.data.data.duplicated_clients);
                    }
                    if (res) {
                        if (setTabIndex) {
                            if (clickedClient === 'domestic') {
                                setTabIndex(0);
                            } else setTabIndex(1);
                        }
                        if (setNewCreatedClient) {
                            setNewCreatedClient(res?.data?.data);
                        }
                        setShowValidationMessage(false);
                    }
                    toast.success(t('pages.clients.addSuccess').text);
                })
                .then(() => {
                    getAllClients(setClients, userId, tabIndex);
                });
        }
    };

    return (
        <AddClientModalWrapper>
            <ToastContainer />
            <ModalText>
                <h4>
                    {t('pages.clients.addClientModal.title').text}{' '}
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: 'var(--gray)' }}
                        onClick={() => setAddClientModal(false)}
                    />
                </h4>
                <p>{t('pages.clients.addClientModal.description').text}</p>
            </ModalText>
            <Form>
                {clickedClient === 'domestic' && (
                    <SelectComponent
                        className="select-container"
                        optionList={clientCategories}
                        label={t('pages.clients.modal.form.domestic.clientCategory.label').text}
                        handleSelectedValue={(value: string) => {
                            setFormData({ ...formData, clientCategory: value });
                            setSelectedClientCategory(value);
                        }}
                        defaultSelectedOption={clientCategories[1]}
                    />
                )}
                {clickedClient === 'domestic' &&
                    (formData.clientCategory === 'legal' || formData.clientCategory === 'public') && (
                        <>
                            <Input
                                type={InputComponentType.Number}
                                label={t('pages.clients.modal.form.domestic.legal.vatNumber').text}
                                validation={showValidationMessage || clickedField === 'vatNumber' ? isVatNoValid : ''}
                                blurHandler={() => handleValidation('vatNumber')}
                                value={formData.vatNumber}
                                onChange={(value: string) => {
                                    setFormData({ ...formData, vatNumber: value });
                                }}
                                maxLength={9}
                            />
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'auto'}
                                disabled={formData.vatNumber === ''}
                                className="agency-info-btn"
                                onClick={handleGetAgencyData}
                            >
                                {t('pages.clients.modal.form.domestic.legal.getData').text}
                            </Button>
                        </>
                    )}
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.name').text}
                    validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                    value={formData.name}
                    blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, name: value });
                    }}
                    maxLength={170}
                />
                {clickedClient === 'domestic' && formData.clientCategory === 'civil' && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.civil.personalId').text}
                        validation={
                            formData.personalId.length < 13 && formData.personalId.length > 0 ? isPersonalIdValid : ''
                        }
                        blurHandler={() => handleValidation('personalId')}
                        value={formData.personalId}
                        onChange={(value: string) => {
                            setFormData({ ...formData, personalId: value });
                        }}
                        maxLength={13}
                    />
                )}
                {clickedClient === 'foreign' && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.taxNumber').text}
                            value={formData.taxNumber}
                            blurHandler={() => handleValidation('taxNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, taxNumber: value });
                            }}
                            maxLength={50}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                            value={formData.identificationNumber}
                            blurHandler={() => handleValidation('identificationNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, identificationNumber: value });
                            }}
                            maxLength={50}
                        />
                    </>
                )}
                {formData.clientCategory !== 'civil' && clickedClient === 'domestic' && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                        validation={
                            showValidationMessage || clickedField === 'identificationNumber'
                                ? isIdentificationNumValid
                                : ''
                        }
                        blurHandler={() => handleValidation('identificationNumber')}
                        value={formData.identificationNumber}
                        onChange={(value: string) => {
                            setFormData({ ...formData, identificationNumber: value });
                        }}
                        maxLength={8}
                    />
                )}
                {formData.clientCategory === 'public' && (
                    <>
                        {jbkjsOptions && jbkjsOptions.length > 0 && (
                            <SelectSearch
                                optionList={jbkjsOptions}
                                handleSelectedValue={(data: { value: ObjectKeys; label: string }) => {
                                    setFormData({
                                        ...formData,
                                        jbkjs: data.value.jbbk || '',
                                        address: data.value.address || '',
                                        city: data.value.mesto || '',
                                    });
                                }}
                                searchPlaceholder={t('pages.clients.chooseJbkjs').text}
                                placeholder={t('pages.clients.chooseJbkjs').text}
                            />
                        )}
                        <Input
                            type={InputComponentType.Number}
                            label={t('pages.clients.modal.form.domestic.public.jbkjs').text}
                            validation={showValidationMessage || clickedField === 'jbkjs' ? isJbkjsValid : ''}
                            blurHandler={() => handleValidation('jbkjs')}
                            value={formData.jbkjs}
                            onChange={(value: string) => {
                                setFormData({ ...formData, jbkjs: value });
                            }}
                            maxLength={5}
                        />
                    </>
                )}
                {phoneNumbers && phoneNumbers.length > 0 && (
                    <SelectSearch
                        optionList={phoneNumbers}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            const value = data.label;

                            setFormData({
                                ...formData,
                                phoneNumber: value,
                            });
                        }}
                        searchPlaceholder={t('pages.register.rightColumn.form.secondStep.choosePhoneNumber').text}
                        placeholder={t('pages.register.rightColumn.form.secondStep.choosePhoneNumber').text}
                    />
                )}
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.phoneNumber').text}
                    value={formData.phoneNumber}
                    blurHandler={() => handleValidation('phoneNumber')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, phoneNumber: value });
                    }}
                    maxLength={170}
                />
                <div>
                    <p className="label-class">{t('pages.clients.addClientModal.emails').text}</p>
                    {showEmails && emails.length > 0 ? (
                        <div className="emails">
                            {emails
                                .filter((email: string) => email !== '')
                                .map((email: string) => (
                                    <Email key={email}>
                                        {email}
                                        <span onClick={() => handleRemoveEmail(email)}>x</span>
                                    </Email>
                                ))}
                        </div>
                    ) : null}
                    <EmailFieldWrapper>
                        <Input
                            className="email"
                            type={InputComponentType.Text}
                            value={formData.email}
                            onChange={(value: string) => {
                                setFormData({ ...formData, email: value });
                            }}
                            onEnter={() => {
                                if (formData.email !== '' && isEmailValid === '') {
                                    setShowEmails(true);
                                    setEmails([...emails, formData.email]);
                                    setFormData({ ...formData, email: '' });
                                }
                            }}
                            validation={isEmailValid ? isEmailValid : ''}
                            handleBlurAction={() => {
                                setShowError(true);
                                if (emails.includes(formData.email)) {
                                    setFormData({ ...formData, email: '' });
                                    return;
                                }
                                if (formData.email !== '' && isEmailValid === '') {
                                    setShowEmails(true);
                                    setEmails([...emails, formData.email]);
                                    setFormData({ ...formData, email: '' });
                                }
                            }}
                            hideBlurOnChange
                            maxLength={170}
                        />
                    </EmailFieldWrapper>
                </div>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.address').text}
                    value={formData.address}
                    validation={
                        showValidationMessage ||
                        clickedField === 'address' ||
                        ((showValidationMessage || clickedField === 'address') && clickedClient === 'foreign')
                            ? isAddressValid
                            : ''
                    }
                    blurHandler={() => handleValidation('address')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, address: value });
                    }}
                    maxLength={170}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.city').text}
                    value={formData.city}
                    validation={
                        showValidationMessage ||
                        clickedField === 'city' ||
                        ((showValidationMessage || clickedField === 'city') && clickedClient === 'foreign')
                            ? isCityValid
                            : ''
                    }
                    blurHandler={() => handleValidation('city')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, city: value });
                    }}
                    maxLength={170}
                />
                {clickedClient === 'foreign' && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.state').text}
                            value={formData.state}
                            validation={showValidationMessage || clickedField === 'state' ? isStateValid : ''}
                            blurHandler={() => handleValidation('state')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, state: value });
                            }}
                            maxLength={170}
                        />
                        <SelectComponent
                            className="select-container currency-select"
                            optionList={currencies}
                            label={t('pages.clients.modal.form.foreign.currency').text}
                            handleSelectedValue={(value: string) => setFormData({ ...formData, currency: value })}
                            defaultSelectedOption={currencies[0]}
                        />
                    </>
                )}
            </Form>
            <ButtonWrapper>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'130px'}
                    onClick={() => {
                        addNewClient();
                    }}
                >
                    {t('buttons.save').text}
                </Button>
            </ButtonWrapper>
        </AddClientModalWrapper>
    );
};

const AddClientModalWrapper = styled.div`
    .select-wrapper {
        width: 100%;
        margin-bottom: 20px;
        label {
            font-size: 15px;
            color: var(--gray);
            margin-bottom: 2px;
            display: flex;
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--black);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
        div {
            font-size: 15px;
            color: var(--gray);
            .select__menu-list {
                .select__option {
                    font-size: 14px;
                    &:hover {
                        color: var(--white);
                        background: var(--purple);
                    }
                    &.select__option--is-selected {
                        color: var(--white);
                        background: var(--purple);
                        border-radius: 0;
                    }
                }
                .select__option:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    .agency-info-btn {
        margin-bottom: 20px;
        padding: 5px;
        height: auto;
        line-height: 15px;
    }
`;

const EmailFieldWrapper = styled.div`
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;

const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;

const ModalText = styled.div`
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
    }
`;
const Form = styled.div`
    margin-top: 30px;
    .label-class {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
            cursor: default;
        }
    }
`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`;
export default AddClientModal;
