import React from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../../components/button';
import colors from '../../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../../../../types/objectKeys';
import { formatTwoDecimals } from '../../../../../functions/format';

interface PackageProps {
    data?: ObjectKeys;
    onEdit: () => void;
    onDelete: () => void;
    onClick: () => void;
}

const AdminServiceCard: React.FC<PackageProps> = ({ data, onEdit, onDelete, onClick }) => {
    const servicesWithoutCourier = data?.services?.data?.filter((item: ObjectKeys) => !item.is_courier);
    const allServices = data?.services?.data;

    const priceForAllServices = allServices?.reduce((acc: number, item: ObjectKeys) => {
        return acc + item.price;
    }, 0);

    const priceWithoutCourier = servicesWithoutCourier?.reduce((acc: number, item: ObjectKeys) => {
        return acc + item.price;
    }, 0);

    return (
        <StyledPackage onClick={() => onClick()}>
            <Title>
                <h2>{data?.name_rs}</h2>
            </Title>
            <ActionContainer>
                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => onEdit()}>
                    <FontAwesomeIcon icon={faEdit} color={colors.lightBlue} />
                    <span className="buton-title">Izmeni</span>
                </Button>
                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => onDelete()}>
                    <FontAwesomeIcon icon={faTrash} color={colors.danger} />
                    <span className="buton-title">Obrisi</span>
                </Button>
            </ActionContainer>
            <Content>
                <div>
                    <p className="prices">
                        Cena za usluge sa kurirom: {formatTwoDecimals(priceForAllServices.toString())} RSD
                    </p>
                </div>
                <div>
                    <p className="prices">
                        Cena za usluge bez kurira: {formatTwoDecimals(priceWithoutCourier.toString())} RSD
                    </p>
                </div>
                <p className="note">*Cene su izrazene bez popusta koji ide uz odgovarajuci paket</p>
            </Content>
            <Content>
                <br />
                <div>
                    <p className="services">Usluge koje su uključene u paket:</p>
                </div>
                <div className="items">
                    {data?.services?.data && data.services.data.length > 0 ? (
                        data.services.data
                            .filter((item: ObjectKeys) => !item.is_courier) // Filter items where is_courier is false
                            .map((item: ObjectKeys) => <div key={item.id}>{item.name_rs}</div>)
                    ) : (
                        <div>Nema usluga u paketu</div>
                    )}
                </div>

                <div>
                    <p className="services">Kurirska usluga:</p>
                </div>
                <div className="items">
                    {data?.services?.data && data.services.data.length > 0
                        ? data.services.data
                              .filter((item: ObjectKeys) => item.is_courier)
                              .map((item: ObjectKeys) => <div key={item.id}>{item.name_rs}</div>)
                        : null}
                </div>
            </Content>
        </StyledPackage>
    );
};

const StyledPackage = styled.div`
    margin-bottom: 20px;
    cursor: pointer;
`;

const Title = styled.div`
    text-align: center;
    width: 100%;
    background-color: var(--purple);
    padding: 20px;
    color: var(--white);
    padding-bottom: 20px;
    margin-bottom: 20px;

    h2 {
        font-size: 18px;
    }
`;

const Content = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    .services {
        margin-top: 10px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .prices {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 20px;
        margin-bottom: 5px;
    }
    .note {
        margin-top: 10px;
        font-size: 12px;
        color: var(--gray);
    }
`;
const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .buton-title {
        margin-left: 5px;
        color: black;
    }
`;

export default AdminServiceCard;
