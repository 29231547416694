import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Input, { InputComponentType } from '../../../../components/input';
import { Bank } from '@/communication/banks/types';
import { FunctionComponent } from 'react';
import SelectSearch from '../../../../components/selectSearch';
import styled from 'styled-components';
import { PaymentInstruction } from '@/communication/agency/types';
import { faArrowUpFromBracket, faCircleQuestion, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../../components/tooltipMain';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import useTheme from '../../../../hooks/useTheme';

interface Props {
    isNew?: boolean;
    index: number;
    allBanks: Bank[];
    isAllBanksLoading: boolean;
    currencyOptions: { label: string; value: string }[];
    paymentInstructions: PaymentInstruction[];
    orderNumber?: number;
    onDelete: () => void;
    onUploadInstruction: () => void;
    onDeleteInstruction: (id: string) => void;
    disabled?: boolean;
}

const ForeignBankAccountNumber: FunctionComponent<Props> = ({
    isNew = false,
    index,
    allBanks,
    currencyOptions,
    paymentInstructions,
    orderNumber,
    onDelete,
    onUploadInstruction,
    onDeleteInstruction,
    disabled = false,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { control, setValue } = useFormContext();
    const { isLaptopMediumAndDown } = useResponsive();

    const bankIdPath = `${isNew ? 'newAccount' : 'accounts'}[${index}].bank_id`;
    const bankIdValue = useWatch({ control, name: bankIdPath });

    const iban = useWatch({ control, name: `${isNew ? 'newAccount' : 'accounts'}[${index}].iban` });

    const isOtherBank = allBanks.find((bank) => bank.id === bankIdValue)?.code === '000';

    const bankOptions = allBanks.map((bank) => ({ label: bank.name, value: bank.id }));

    const isDarkTheme = useTheme();

    const handleIbanChange = (value: string) => {
        setValue(`${isNew ? 'newAccount' : 'accounts'}[${index}].iban`, value.toUpperCase(), {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const handleSwiftChange = (value: string) => {
        setValue(`${isNew ? 'newAccount' : 'accounts'}[${index}].swift`, value.toUpperCase(), {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const onBankChange = (bankId: string) => {
        const selectedBank = allBanks.find((bank) => bank.id === bankId);
        handleSwiftChange(selectedBank?.swift || '');
        handleIbanChange(iban);
    };

    return (
        <BankAccountWrapper isDarkTheme={isDarkTheme}>
            <div className="bank-account-content">
                {orderNumber && <span className="orderNumber">{`${orderNumber}.`}</span>}
                <Container className={isNew ? 'isNew' : ''}>
                    <div className="inner1">
                        <Controller
                            name={`${isNew ? 'newAccount' : 'accounts'}[${index}].bank_id`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <SelectSearch
                                    optionList={bankOptions}
                                    validation={error?.message}
                                    placeholder={t('pages.agency.bankAccounts.foreign.select_bank').text}
                                    defaultValue={bankOptions.find((option) => option.value === value)}
                                    disabled={disabled}
                                    handleSelectedValue={(option: { label: string; value: string }) => {
                                        onChange(option.value);
                                        onBankChange(option.value);
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={`${isNew ? 'newAccount' : 'accounts'}[${index}].currency`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <SelectSearch
                                    optionList={currencyOptions}
                                    validation={error?.message}
                                    placeholder={t('pages.agency.bankAccounts.foreign.select_currency').text}
                                    defaultValue={currencyOptions.find((option) => option.value === value)}
                                    disabled={disabled || !bankIdValue}
                                    handleSelectedValue={(option: { label: string; value: string }) => {
                                        onChange(option.value);
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={`${isNew ? 'newAccount' : 'accounts'}[${index}].iban`}
                            control={control}
                            render={({ field: { value, name }, fieldState: { error } }) => (
                                <div className="iban-input-wrapper">
                                    <Input
                                        onChange={handleIbanChange}
                                        value={value}
                                        name={name}
                                        type={InputComponentType.Text}
                                        validation={error?.message}
                                        placeholder="IBAN"
                                        isDisabled={disabled || !bankIdValue}
                                        maxLength={isOtherBank ? 50 : 22}
                                    />
                                    <Tooltip
                                        text={t('pages.agency.bankAccounts.foreign.iban_tooltip_text').text}
                                        isHtml={true}
                                        position={!isLaptopMediumAndDown ? 'top' : 'left'}
                                        label={
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                fontSize={'13px'}
                                                color={'var(--purple)'}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            name={`${isNew ? 'newAccount' : 'accounts'}[${index}].swift`}
                            control={control}
                            render={({ field: { value, name }, fieldState: { error } }) => (
                                <Input
                                    onChange={handleSwiftChange}
                                    value={value}
                                    name={name}
                                    type={InputComponentType.Text}
                                    validation={error?.message}
                                    placeholder="SWIFT"
                                    isDisabled={!isOtherBank}
                                    maxLength={50}
                                />
                            )}
                        />
                    </div>
                </Container>
                <div className="action-icons-wrapper">
                    {!isNew && paymentInstructions.length < 1 && (
                        <>
                            <FontAwesomeIcon
                                className="uploadIcon"
                                icon={faArrowUpFromBracket}
                                onClick={onUploadInstruction}
                                fontSize={'1.2rem'}
                            />

                            <Tooltip
                                text={t('pages.agency.bankAccounts.foreign.tooltip_text').text}
                                isHtml={true}
                                position={!isLaptopMediumAndDown ? 'top' : 'left'}
                                label={
                                    <FontAwesomeIcon
                                        icon={faCircleQuestion}
                                        fontSize={'13px'}
                                        color={'var(--purple)'}
                                    />
                                }
                            />
                        </>
                    )}
                    <FontAwesomeIcon icon={faTrashCan} className="deleteIcon" onClick={onDelete} />
                </div>
            </div>
            {paymentInstructions.length > 0 && (
                <PaymentInstructions>
                    <span>{t('pages.agency.bankAccounts.foreign.payment_instructions').text}</span>
                    {paymentInstructions.map((pi) => (
                        <div key={pi.id} className="instruction">
                            <span className="file-name">{pi.file_name}</span>
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="deleteIcon"
                                onClick={() => onDeleteInstruction(pi.id)}
                            />
                        </div>
                    ))}
                </PaymentInstructions>
            )}
        </BankAccountWrapper>
    );
};
export default ForeignBankAccountNumber;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const BankAccountWrapper = styled.div<DarkThemeProps>`
    margin-bottom: 2rem;
    .action-icons-wrapper {
        display: flex;
        align-items: center;
        height: fit-content;
        gap: 1.2rem;
        justify-content: flex-end;
    }
    .deleteIcon {
        color: var(--danger);
        cursor: pointer;
    }
    .bank-account-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 1.5rem;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
        }
        .orderNumber {
            height: 100%;
            padding: 5px;
            text-align: left;
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92A5AC')};
        }

        .uploadIcon {
            cursor: pointer;
            font-size: '3rem';
        }
    }
    .iban-input-wrapper {
        display: flex;
        .input {
            flex: 1;
        }
    }
`;

const Container = styled.div`
    overflow-y: visible;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &.isNew {
        margin-left: 48px;
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }

    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
        }
    }

    .inner1 {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        gap: 1rem;

        @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) {
            grid-template-columns: 1fr 1fr;
        }
    }
`;

const PaymentInstructions = styled.div`
    text-align: left;
    margin-left: 2.6rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #fdf6ff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .file-name {
        font-weight: 600;
    }
    .instruction {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        margin-left: 0;
    }
`;
