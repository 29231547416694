import { useState, useEffect } from 'react';

const useTheme = (): boolean => {
    const [isDarkTheme, setIsDarkTheme] = useState(document.documentElement.getAttribute('data-theme') === 'dark');

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'data-theme') {
                    setIsDarkTheme(document.documentElement.getAttribute('data-theme') === 'dark');
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
        });

        return () => observer.disconnect();
    }, []);

    return isDarkTheme;
};

export default useTheme;
