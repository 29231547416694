import { Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useCurrencyStore from '../../../../store/currency';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import SelectComponent from '../../../../components/select';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSearch from '../../../../components/searchCreateNew';
import Loader from '../../../../components/loader';
import useTheme from '../../../../hooks/useTheme';

type Props = {
    t: Function;
    clickedArticle: ObjectKeys;
    userId: string;
    setEditClientModal: Dispatch<SetStateAction<boolean>>;
    setClients: Dispatch<SetStateAction<ObjectKeys[]>>;
    tabIndex: number;
    success: Function;
    measurementUnits: { value: string; label: string }[];
    articleTypeOptions: { value: string; label: string }[];
};

const EditArticleModal: FunctionComponent<Props> = ({
    t,
    clickedArticle,
    setEditClientModal,
    success,
    measurementUnits,
    articleTypeOptions,
}) => {
    const { currentLang } = useLanguageStore();
    const { currency } = useCurrencyStore();
    const [formData, setFormData] = useState<any>({
        name: '',
        default_measurement_unit: '',
        default_price: '',
        default_currency: '',
        id: '',
        type: '',
        description: '',
    });
    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [clickedField, setClickedField] = useState('');
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const [loader, setLoader] = useState(false);

    const isNameValid = useCharLengthValidation(formData.name, 3, true);
    // const isPriceValid = useCharLengthValidation(formData.default_price, 3, true);

    const isDarkTheme = useTheme();

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const getValidation = (): any => {
        return isNameValid;
    };

    const editArticle = (): void => {
        setLoader(true);
        if (getValidation() !== '') {
            setLoader(false);
            setShowValidationMessage(true);
            return;
        }
        setShowValidationMessage(false);
        setEditClientModal(false);
        communication
            .editArticle(formData.id, {
                name: formData.name,
                default_measurement_unit: formData.default_measurement_unit,
                default_price: formData.default_price && parseFloat(formData.default_price.replace(',', '.')),
                default_currency: formData.default_currency,
                type: formData.type,
                description: formData.description,
            })
            .then((res: ObjectKeys) => {
                if (res) {
                    setLoader(false);
                    success();
                    toast.success(t('pages.listOfArticles.modal.form.edditedSuccess').text);
                    setShowValidationMessage(false);
                    setEditClientModal(false);
                }
            })
            .then(() => {
                // const is_foreign = articleInfo?.is_foreign ? true : false;
                // getAllClients(setClients, userId, tabIndex, is_foreign);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoader(false);
                    toast.error(t('pages.listOfArticles.error').text);
                }
            });
    };

    useEffect(() => {
        if (clickedArticle) {
            setFormData({
                name: clickedArticle.name,
                default_measurement_unit: clickedArticle.default_measurement_unit,
                default_price:
                    clickedArticle.default_price && clickedArticle.default_price.toString().replace('.', ','),
                default_currency: clickedArticle.default_currency,
                id: clickedArticle.id,
                type: clickedArticle.type,
                description: clickedArticle.description,
            });
        }
    }, [clickedArticle]);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    return (
        <EditClientModalWrapper>
            <ToastContainer />
            {loader && <Loader />}
            <ModalText>
                <h4>
                    {t('pages.listOfArticles.modal.form.edit').text}
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: 'var(--gray)' }}
                        onClick={() => setEditClientModal(false)}
                    />
                </h4>
            </ModalText>
            <Form isDarkTheme={isDarkTheme}>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.articleCard.infoWrapper.name').text}
                    validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                    value={formData.name ? formData.name : ''}
                    blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, name: value });
                    }}
                    maxLength={500}
                />
                <UnitSelectWrapper>
                    <label htmlFor="">{t('pages.listOfArticles.modal.form.unitOfMeasure').text}</label>
                    <SelectSearch
                        optionList={measurementUnits}
                        defaultValue={measurementUnits.find((unit) => unit.value === formData.default_measurement_unit)}
                        placeholder={t('pages.invoices.add_measurement_unit').text}
                        handleSelectedValue={(unit: { label: string; value: string }) => {
                            setFormData({ ...formData, default_measurement_unit: unit.value });
                        }}
                        handleCreateOption={(unit) => {
                            setFormData({ ...formData, default_measurement_unit: unit.value });
                        }}
                    />
                </UnitSelectWrapper>

                <Input
                    type={InputComponentType.Amount}
                    label={t('pages.articleCard.infoWrapper.price').text}
                    value={formData.default_price ? formData.default_price : ''}
                    blurHandler={() => {
                        handleValidation('email');
                    }}
                    onChange={(value: string) => {
                        setFormData({ ...formData, default_price: value });
                    }}
                    limitDecimal={2}
                />
                <SelectComponent
                    className="select-container currency-select"
                    optionList={currencies}
                    value={formData.default_currency ? formData.default_currency : 'EUR'}
                    label={t('pages.clients.modal.form.foreign.currency').text}
                    defaultSelectedOption={{
                        label: formData.default_currency?.toUpperCase() || '',
                        value: formData.default_currency || '',
                    }}
                    handleSelectedValue={(value: string) => {
                        setFormData({ ...formData, default_currency: value });
                    }}
                />
                <SelectComponent
                    label={t('pages.listOfArticles.modal.form.type').text}
                    optionList={articleTypeOptions}
                    defaultSelectedOption={articleTypeOptions.find((typeOption) => typeOption.value === formData.type)}
                    value={formData.type}
                    handleSelectedValue={(value: string) => {
                        setFormData({ ...formData, type: value });
                    }}
                />
                <Input
                    type={InputComponentType.TextArea}
                    label={t('pages.editInvoice.articleDescription').text}
                    value={formData.description ? formData.description : ''}
                    blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, description: value });
                    }}
                    className="articleDescriptionField"
                    placeholder={t('pages.editInvoice.insertArticleDescription').text}
                    maxLength={1490}
                />
            </Form>
            <ButtonWrapper>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'130px'}
                    onClick={() => {
                        editArticle();
                    }}
                >
                    {t('buttons.save').text}
                </Button>
            </ButtonWrapper>
        </EditClientModalWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const UnitSelectWrapper = styled.div`
    flex: 1;
    width: 100%;
    min-width: 180px;

    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        display: flex;
        padding: 5px 0;
    }
    > div {
        width: 100%;
    }
    .select-search {
        > div {
            box-shadow: none;
        }
        .input-dropdown {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            .selected-value {
                height: 28px;
                display: flex;
                align-items: center;
            }
        }
    }
`;

const EditClientModalWrapper = styled.div`
    .select-wrapper {
        width: 100%;
        margin-bottom: 20px;
        label {
            font-size: 15px;
            color: var(--gray);
            margin-bottom: 2px;
            display: flex;
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--black);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
        div {
            font-size: 15px;
            color: var(--gray);
            .select__menu-list {
                .select__option {
                    &:hover {
                        color: var(--white);
                        background: var(--purple);
                    }
                    &.select__option--is-selected {
                        color: var(--white);
                        background: var(--purple);
                        border-radius: 0;
                    }
                }
                .select__option:last-child {
                    border-bottom: none;
                }
            }
        }
        .currency-select {
            display: flex;
            flex-direction: column;
            > div,
            label {
                width: 100%;
            }
        }
    }
`;

const ModalText = styled.div`
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
    }
`;
const Form = styled.div<DarkThemeProps>`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .fnvQbu {
        width: 100%;
        margin: 1rem 0;
    }

    .articleDescriptionField {
        textarea {
            outline: none;
            border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#ccc')};
            font-size: 14px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
            background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#fff')};
            padding: 5px 0 0 5px;
            height: 150px;
        }

        textarea::placeholder {
            padding: 5px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`;
export default EditArticleModal;
