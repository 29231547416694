import React, { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useAllUsersStore from '../../../store/admin/allUsers';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import SelectComponent from '../../../components/select';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';
import { formatTwoDecimals } from '../../../functions/format';
import ConfirmModal from '../../../components/confirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import NewTransactionModal from './newTransaction';
import AddPayment from './addPayment';
import EditTransactionModal from './editTransaction';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';
import { transformDateFormat } from '../../../functions/formatDateTime';
import Input, { InputComponentType } from '../../../components/input';

const Transactions: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const { allUsers } = useAllUsersStore();

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [addTransactionModal, setAddTransactionModal] = useState(false);
    const [editTransactionModal, setEditTransactionModal] = useState(false);

    const [addCustomTransactionModal, setAddCustomTransactionModal] = useState(false);

    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        include: 'user',
        orderBy: 'transaction_date',
        sortedBy: 'desc',
        search: '',
        searchFields: '',
        searchJoin: 'and',
    });
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');

    const [selectedType, setSelectedType] = useState<string>('');
    const [transactions, setTransactions] = useState<ObjectKeys>([]);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [packages, setPackages] = useState<ObjectKeys[]>([]);

    const [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
    const [searchDateTo, setSearchDateTo] = useState<Date | null>(null);

    useBackOfficePasswordProtect();

    const [cart, setCart] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        status: '',
        total_price: 0,
        type: 'package',
        payment_type: 'transfer',
        note: '',
        is_upgrade: false,
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    const today = new Date();

    const [resendModal, setResendModal] = useState(false);
    const [clickedCartId, setClickedCartId] = useState('');

    const handleResendFiscalBill = (id: string): void => {
        setClickedCartId(id);
        setResendModal(true);
    };

    const generateFiscalBill = (): void => {
        setLoaderVisible(true);
        communication
            .resendFiscalBill(clickedCartId)
            .then(() => {
                updateData();
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                console.error(error);
                updateData();
                setLoaderVisible(false);
            });
    };

    const types = [
        { value: 'all', label: 'All' },
        { value: 'transfer', label: 'Transfer' },
        { value: 'credit_card', label: 'Credit Card' },
    ];

    const getAllPackages = (): void => {
        setLoaderVisible(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                setPackages(response.data.data);
                setCart({
                    ...cart,
                    items: cart.items.map((prev: ObjectKeys) => ({
                        ...prev,
                        id: response.data.data[0].id,
                        price: response.data.data[0].monthly,
                        months: response.data.data[0].name === 'Basic' ? 1 : 6,
                    })),
                });
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        setSelectedUserId(userBOid);
    }, [userBOid]);

    const returnUserEmail = (id: string): string => {
        const user = allUsers.find((user_data: ObjectKeys) => user_data.id === id);
        return user?.email || 'N/A';
    };

    const updateData = async (): Promise<void> => {
        setLoaderVisible(true);

        const updatedParams = { ...params }; // Work with a new constant instead of modifying params

        // Handle user_id filter
        if (selectedUserId.length > 0) {
            updatedParams.search = `user_id:${selectedUserId}`;
            updatedParams.searchFields = 'find:like;user_id:like';
            updatedParams.searchJoin = 'and';
        } else {
            updatedParams.search = '';
            updatedParams.searchFields = '';
            updatedParams.searchJoin = '';
        }

        // Handle date filters
        if (searchDateFrom && searchDateTo) {
            const dateFilter = `transaction_date:${formatDateFromForQuery(searchDateFrom)},${formatDateToForQuery(
                searchDateTo,
            )}`;
            const dateField = 'transaction_date:between';

            updatedParams.search = updatedParams.search ? `${updatedParams.search};${dateFilter}` : dateFilter;
            updatedParams.searchFields = updatedParams.searchFields
                ? `${updatedParams.searchFields};${dateField}`
                : dateField;
        } else {
            // Remove date filters if one or both are missing
            updatedParams.search =
                updatedParams.search
                    ?.split(';')
                    .filter((s) => !s.startsWith('transaction_date:'))
                    .join(';') || '';

            updatedParams.searchFields =
                updatedParams.searchFields
                    ?.split(';')
                    .filter((s) => !s.startsWith('transaction_date:'))
                    .join(';') || '';
        }

        // Handle payment type filter (optional)
        if (selectedType) {
            const typeFilter = `cart.payment_type:${selectedType}`;
            const typeField = 'cart.payment_type:like';

            updatedParams.search = updatedParams.search ? `${updatedParams.search};${typeFilter}` : typeFilter;
            updatedParams.searchFields = updatedParams.searchFields
                ? `${updatedParams.searchFields};${typeField}`
                : typeField;
        } else {
            // Remove payment type filter if not selected
            updatedParams.search =
                updatedParams.search
                    ?.split(';')
                    .filter((s) => !s.startsWith('cart.payment_type:'))
                    .join(';') || '';

            updatedParams.searchFields =
                updatedParams.searchFields
                    ?.split(';')
                    .filter((s) => !s.startsWith('cart.payment_type:'))
                    .join(';') || '';
        }

        // Ensure `searchJoin` is only set when filters exist
        updatedParams.searchJoin = updatedParams.search ? 'and' : '';

        try {
            const response =
                selectedUserId.length > 0
                    ? await communication.getFilteredTransaction(updatedParams)
                    : await communication.getAllTransactions(updatedParams);

            if (response.status === 200) {
                setTransactions(response.data);
            }
        } catch (err: any) {
            setErrorMessage(err?.response?.data?.message);
            setOpenErrorModal(true);
        } finally {
            setLoaderVisible(false);
        }
    };

    useEffect(() => {
        updateData();
    }, [params, selectedType]);

    useEffect(() => {
        setParams({
            ...params,
            page: 1,
        });
        updateData();
    }, [selectedUserId]);

    const handlePageClick = (e: any): void => {
        setParams({
            ...params,
            page: e.selected + 1,
        });
    };

    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const compareDates = (dateStr1: string, dateStr2: string): boolean => {
        if (!dateStr1 || !dateStr2) return false;
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Convert dates to ISO strings (UTC timezone) for accurate comparison
        const isoDate1 = date1.toISOString();
        const isoDate2 = date2.toISOString();

        // Compare the ISO date strings
        return isoDate1 === isoDate2;
    };

    const formatDateFromForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const formatDateToForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        date.setDate(date.getDate()); // Add one day
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const updateSearchParams = (fromDate: Date | null, toDate: Date | null): void => {
        setParams((prevParams) => {
            const newParams = { ...prevParams, page: 1 };

            if (fromDate && toDate) {
                // Add date filter when both dates exist
                const newSearch = `${formatDateFromForQuery(fromDate)},${formatDateToForQuery(toDate)}`;
                const newSearchFields = 'transaction_date:between';

                return {
                    ...newParams,
                    search: prevParams.search ? `${prevParams.search};${newSearch}` : newSearch,
                    searchFields: prevParams.searchFields
                        ? `${prevParams.searchFields};${newSearchFields}`
                        : newSearchFields,
                };
            }

            if (!fromDate && !toDate) {
                // Remove date filter when both are missing
                return {
                    ...newParams,
                    search:
                        prevParams.search
                            ?.split(';')
                            .filter((s) => !s.startsWith('transaction_date:'))
                            .join(';') || '',
                    searchFields:
                        prevParams.searchFields
                            ?.split(';')
                            .filter((s) => !s.startsWith('transaction_date:'))
                            .join(';') || '',
                };
            }

            return prevParams; // Do nothing if only one date is missing
        });
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {resendModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setResendModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setResendModal(false);
                            generateFiscalBill();
                        }}
                        close={() => {
                            setResendModal(false);
                        }}
                        active={resendModal}
                        message={'Da li ste sigurni da zelite da izdate fiskalni racun?'}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            <PageWrapper className=" page">
                {editTransactionModal && (
                    <EditTransactionModal
                        success={() => {
                            updateData();
                            toast.success('Uspešno izmenjena transakcija');
                        }}
                        closeModal={() => {
                            setAddTransactionModal(false);
                        }}
                    />
                )}
                {addTransactionModal && (
                    <NewTransactionModal
                        t={t}
                        success={() => {
                            updateData();
                            toast.success('Uspešno kreirana transakcija');
                        }}
                        closeModal={() => {
                            setAddTransactionModal(false);
                        }}
                        packages={packages}
                    />
                )}
                {addCustomTransactionModal && (
                    <AddPayment
                        t={t}
                        success={() => {
                            updateData();
                            toast.success('Uspešno kreirana transakcija');
                        }}
                        closeModal={() => {
                            setAddCustomTransactionModal(false);
                        }}
                        packages={packages}
                    />
                )}
                <Header>
                    <h1>{t('pages.admin.transactions.title').text}</h1>
                    <div>
                        {/* <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddCustomTransactionModal(true);
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Produži pretplatu
                        </Button> */}
                        <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddTransactionModal(true);
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Nova Transakcija
                        </Button>
                    </div>
                </Header>
                <SelectWrapper>
                    <div className="agency-select-wrapper">
                        <label>{t('pages.admin.users.chooseUser').text}</label>
                        <div className="select-container">
                            <SearchCustomComponent
                                value={allUsersOptions.find((v) => v.value === userBOid)}
                                allUsersOptions={allUsersOptions}
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    useUserStore.setState({ userBOid: data.value });
                                    setSelectedUserId(data.value);
                                }}
                                placeholder={t('pages.admin.users.chooseUser').text}
                                className="user-selector"
                            />
                        </div>
                    </div>
                    <div className="selecttype">
                        <label>TYPE: </label>
                        <SelectComponent
                            optionList={types}
                            defaultSelectedOption={types[0]}
                            handleSelectedValue={(value: string) => {
                                value === 'all' ? setSelectedType('') : setSelectedType(value);
                            }}
                        />
                    </div>
                </SelectWrapper>
                <Filters>
                    <div className="date-picker">
                        <label htmlFor="date-from">Od:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-from"
                            isClearable
                            date={searchDateFrom}
                            onChange={(date: Date) => {
                                setSearchDateFrom(date);
                                updateSearchParams(date, searchDateTo);
                            }}
                        />
                    </div>
                    <div className="date-picker">
                        <label htmlFor="date-to">Do:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-to"
                            isClearable
                            date={searchDateTo}
                            onChange={(date: Date) => {
                                setSearchDateTo(date);
                                updateSearchParams(searchDateFrom, date);
                            }}
                        />
                    </div>
                </Filters>

                <TableContainer>
                    <StyledTable>
                        <TableHeader>
                            <tr>
                                <th>Email</th>
                                <th>PIB</th>
                                <th>Datum uplate</th>
                                <th>Datum pocetka paketa</th>
                                <th>Datum isteka paketa</th>
                                <th>Paket</th>
                                <th>Iznos</th>
                                <th>Meseci</th>
                                <th>Način plaćanja</th>
                                {/* <th>Akcije</th> */}
                                <th>Račun</th>
                            </tr>
                        </TableHeader>
                        <TableBody>
                            {transactions?.data?.length > 0 &&
                                transactions?.data.map((transaction: ObjectKeys) => (
                                    <tr key={transaction.id}>
                                        <TableCell>{returnUserEmail(transaction?.user_id)}</TableCell>
                                        <TableCell>{transaction.pib}</TableCell>
                                        <TableCell>
                                            {moment(transaction.transaction_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {moment(transaction.transaction_start_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>{transformDateFormat(transaction.transaction_end_date)}</TableCell>
                                        <TableCell>
                                            {transaction?.cart?.data?.items?.data[0]?.package?.data?.name || '-'}
                                        </TableCell>
                                        <TableCell>{formatTwoDecimals(transaction.amount.toString())}</TableCell>
                                        <TableCell>{transaction.package}</TableCell>
                                        <TableCell>{transaction?.cart?.data?.payment_type || 'trial'}</TableCell>
                                        {/* <TableCell>
                                            {transaction.cart ? null : (
                                                <ActionButtons>
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.blue}
                                                        icon={faEdit}
                                                        onClick={() => {
                                                            setSelectedTransactionId(transaction.id);
                                                            setEditTransactionModal(true);
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.danger}
                                                        icon={faTrashCan}
                                                        onClick={() => handleDeleteTransaction(transaction.id)}
                                                    />
                                                </ActionButtons>
                                            )}
                                        </TableCell> */}
                                        {/* <TableCell>{transaction?.cart?.data?.invoice_pdf_url || '-'}</TableCell> */}
                                        {transaction?.cart?.data?.invoice_pdf_url ? (
                                            <TableCell
                                                onClick={() =>
                                                    handleDownloadClick(transaction.cart.data.invoice_pdf_url)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                                            </TableCell>
                                        ) : (
                                            <>
                                                {compareDates(
                                                    today.toISOString(),
                                                    transaction?.cart?.data?.created_at,
                                                ) ? (
                                                    <TableCell
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleResendFiscalBill(transaction?.cart?.data?.id);
                                                        }}
                                                    >
                                                        <p className="billDownload">Ponovi slanje</p>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell>-</TableCell>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>

                {transactions?.data?.length > 0 && transactions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={transactions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            transactions.meta?.pagination?.current_page === transactions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${transactions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={transactions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .create-button {
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 20px;
        display: inline-block;
        vertical-align: top;
        margin: 4px 0 0 35px;
        border: 1px solid var(--purple);
        border-radius: 100px;
        cursor: pointer;
        background: var(--purple);
        color: var(--white);
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .agency-select-wrapper {
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        label {
            display: inline-block;
            color: #92a5ac;
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 10px;
        }
        .select-container {
            width: 350px;
            display: inline-block;
        }
    }
    .selecttype {
        display: flex;
        align-items: center;
        margin-left: 20px;

        label {
            text-transform: uppercase;
            line-height: 20px;
            font-size: 14px;
            margin-right: 6px;
            color: var(--gray);
        }
    }
`;
const TableContainer = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.thead`
    background-color: var(--purple);
    color: var(--white);

    th {
        font-weight: bold;
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
`;

const TableBody = styled.tbody`
    tr {
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
`;
const Filters = styled.div`
    display: flex;
    gap: 2rem;
    width: 100%;
    padding: 20px 0;

    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .filter-search {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: inline-block;
        }
    }
    .date-picker {
        display: flex;
        align-items: center;
        gap: 1rem;
        .datepicker-input-wrapper {
            background: var(--white);
            border: 1px solid var(--border-color);
            margin-top: 5px;
            svg {
                margin-right: 5px;
            }
        }
    }
`;

export default Transactions;
