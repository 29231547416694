import React, { useState, ReactNode } from 'react';

interface TooltipProps {
    children: ReactNode;
    text?: string;
}

const CustomTooltip: React.FC<TooltipProps> = ({ children, text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="tooltip-container"
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {text ? (
                <div
                    className="tooltip"
                    style={{
                        position: 'absolute',
                        top: showTooltip ? 'calc(50% - 70px)' : 'calc(50% - 50px)', // Move up on hover
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'gray',
                        color: '#fff',
                        padding: '10px',
                        fontSize: '13px',
                        borderRadius: '4px',
                        zIndex: 9999,
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        transition: 'top 0.5s ease, opacity 0.5s ease', // Transition for top and opacity
                        opacity: showTooltip ? 1 : 0, // Fade in/out
                        pointerEvents: showTooltip ? 'auto' : 'none', // Prevent interactions when hidden
                    }}
                >
                    {text}
                </div>
            ) : null}
        </div>
    );
};

export default CustomTooltip;
