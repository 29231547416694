import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import { GET_TipsResponse } from '../../communication/tips/types';

export default class TipsEndpoints {
    public getAllGroups(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.tips.getGroups,
            params: params,
        });
    }
    public finishGroup(id: string, is_finished: boolean): ObjectKeys {
        return fetch({
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.tips.updateGroup(id),
            data: { is_finished: is_finished },
        });
    }
}
