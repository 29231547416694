import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import useTheme from '../../hooks/useTheme';

interface Props {
    title: string;
    price: string;
    onEdit: Function;
    onDelete: Function;
    onPrint: Function;
    onClick: Function;
    currency: string;
    t: Function;
}

const TransferOrderCard: FunctionComponent<Props> = ({
    title,
    price,
    onEdit,
    onDelete,
    onPrint,
    currency,
    onClick,
    t,
}) => {
    const isDarkTheme = useTheme();
    return (
        <Content
            onClick={(event) => {
                const element = event.target.toString().slice(0, 11);
                if (element.includes('SVG')) return;
                onClick();
            }}
            isDarkTheme={isDarkTheme}
        >
            <p>{title}</p>
            <div className="right">
                <div>
                    <p className={'price'}>
                        {price} <span className={'currency'}>{currency}</span>
                    </p>
                </div>
                <div className="iconDiv">
                    <Icon className={'icon'}>
                        <FontAwesomeIcon
                            onClick={() => onEdit()}
                            icon={faEdit}
                            style={{ color: 'var(--blue)' }}
                            width={'33px'}
                            height={'22px'}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                    </Icon>
                    <Icon className={'icon'}>
                        <FontAwesomeIcon
                            onClick={() => onDelete()}
                            icon={faTrashCan}
                            style={{ color: 'var(--danger)' }}
                            width={'33px'}
                            height={'22px'}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                    </Icon>
                    <Icon className={'icon'}>
                        <FontAwesomeIcon
                            onClick={() => onPrint()}
                            icon={faPrint}
                            style={{ color: 'var(--green)' }}
                            width={'33px'}
                            height={'22px'}
                        />
                        <div className="label">{t('pages.editInvoice.print').text}</div>
                    </Icon>
                </div>
            </div>
        </Content>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Content = styled.div<DarkThemeProps>`
    margin-bottom: 15px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : '#fff')};
    border: 1px solid #9b7fe9;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
        padding: 15px 10px;
    }
    &:hover {
        -webkit-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    }
    p {
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        font-size: 14px;
        display: inline-block;
        line-height: 25px;
        margin-right: 15px;
    }
    .right {
        display: flex;
        align-items: center;
    }

    .price {
        font-size: 20px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        margin-right: 15px;

        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            margin-right: 5px;
        }
    }

    .iconDiv {
        display: flex;
        align-items: center;
    }

    svg {
        cursor: pointer;
    }

    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
        flex-direction: column;
        p {
            margin-right: 0;
        }
        .right {
            justify-content: space-between;
        }
    }
`;

export const Icon = styled.div`
    position: relative;
    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            top: -40px;
            opacity: 1;
            transition: top 0.5s, opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;

export default TransferOrderCard;
