import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../../components/loader';
import styled from 'styled-components';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useAllUsersStore from '../../../../store/admin/allUsers';
import Pagination from '../../../../components/pagination';
import { ObjectKeys } from '@/types/objectKeys';
import communication from '../../../../communication';
import AdminSolutionCard from '../adminSolutionCard';
import Input, { InputComponentType } from '../../../../components/input';
import DragAndDrop from '../../../../components/dragAndDrop';
import SearchCustomComponent from '../../../../components/customSelect';
import useUserStore from '../../../../store/user';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import SelectComponent from '../../../../components/select';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';

const AdminTaxSolutions: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const { allUsers } = useAllUsersStore();
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [solutions, setSolutions] = useState<ObjectKeys>([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>('');

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        searchJoin: 'and',
        search: `year:${selectedYear}`,
        searchFields: 'year:=',
        orderBy: 'year',
        sortedBy: 'desc',
    });

    const yearsArrayBO: Array<{ value: any; label: any }> = [];
    yearsArrayBO.unshift({ value: 0, label: t('pages.invoices.filters.status.all').text });

    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1999; year--) {
        yearsArrayBO.push({ value: year, label: year });
    }

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };
    useEffect(() => {
        setLoaderVisible(true);
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib };
        });
        setAllUsersOptions([{ value: 'All users', label: 'All users' }, ...userData]);
        setParams({ ...params, page: 1 });
        setLoaderVisible(false);
    }, [allUsers]);

    useEffect(() => {
        if (selectedUserId === 'All users' || selectedUserId === '') {
            getAllSolutions();
        } else {
            getSolutionsByUserId(selectedUserId);
        }
    }, [params]);

    useEffect(() => {
        setParams({ ...params, page: 1 });
    }, [selectedUserId]);

    const getAllSolutions = async (): Promise<void> => {
        setLoaderVisible(true);
        communication
            .getAllAdminEcotaxSolution(params)
            .then((res: any) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setSolutions(res?.data);
                }
            })
            .catch(() => {
                setLoaderVisible(false);
            });
    };

    const getSolutionsByUserId = async (userId: string): Promise<void> => {
        setSelectedUserId(userId);
        setLoaderVisible(true);
        const updatedParams = { ...params, user_id: userId };
        await communication.getAllEcotaxSolution(updatedParams).then((res: any) => {
            if (res.status === 200) {
                setLoaderVisible(false);
                setSolutions(res?.data);
            }
        });
    };

    const handleUpload = async (files: Blob[]): Promise<void> => {
        await communication
            .uploadEcotaxSolutions(selectedUserId, files[0], selectedYear.toString())
            .then((res: any) => {
                if (res.status === 200) {
                    setSelectedYear(new Date().getFullYear());
                    setLoaderVisible(false);
                    getSolutionsByUserId(selectedUserId);
                }
            });
    };

    const handleDelete = async (id: string): Promise<void> => {
        setLoaderVisible(true);
        communication
            .deleteEcotaxSolution(id)
            .then((res: any) => {
                if (res.status === 200 || res.status === 204 || res.status === 202) {
                    setLoaderVisible(false);
                    if (selectedUserId === 'All users' || selectedUserId === '') {
                        getAllSolutions();
                    } else {
                        getSolutionsByUserId(selectedUserId);
                    }
                }
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error('error', error);
            });
    };

    return (
        <>
            {loaderVisible && <Loader />}
            {modalActive && (
                <Modal modalVisible={modalActive} closeModal={() => setModalActive(!modalActive)}>
                    <ConfirmModal
                        action={async () => {
                            setModalActive(false);
                            await handleDelete(selectedId);
                        }}
                        close={() => setModalActive(false)}
                        active={modalActive}
                        message={'Da li ste sigurni da želite da obrišete rešenje za eko taksu?'}
                    />
                </Modal>
            )}
            <PageWrapper className="page">
                <Header>
                    <h1>Rešenja za eko taksu</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                </SelectWrapper>
                {selectedUserId !== 'All users' && (
                    <UploaderWrapper>
                        <p className="tax-info">Prvo unesite godinu za koju unosite rešenje za eko taksu za klijenta</p>
                        <Input
                            type={InputComponentType.Number}
                            value={selectedYear.toString()}
                            label={'Godina:'}
                            className={'year'}
                            onChange={(value: number) => setSelectedYear(value)}
                            maxLength={4}
                        />
                        {typeof selectedYear === 'number' &&
                            selectedYear.toString().length === 4 &&
                            selectedYear >= 1900 &&
                            selectedYear <= new Date().getFullYear() + 10 && (
                                <div>
                                    <DragAndDrop
                                        description={' '}
                                        className={'uploader'}
                                        multiple={true}
                                        uploadFunction={handleUpload}
                                    />
                                </div>
                            )}
                    </UploaderWrapper>
                )}
                <Filters>
                    <label>{`Prikaz poreskih rešenja za godinu: ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={yearsArrayBO}
                            defaultSelectedOption={yearsArrayBO.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear.toString()}
                            handleSelectedValue={(value: number) => {
                                if (value === 0) {
                                    setParams({ ...params, search: '', searchFields: '', page: 1 });
                                } else {
                                    setParams({ ...params, search: `year:${value}`, searchFields: 'year:=', page: 1 });
                                }
                                setSelectedYear(value);
                            }}
                            key={selectedYear}
                        />
                    </div>
                </Filters>
                <Content>
                    {solutions?.data?.map((solution: ObjectKeys, index: number) => (
                        <AdminSolutionCard
                            key={index}
                            solution={solution}
                            showEmail={selectedUserId === 'All users'}
                            type={'ecotax'}
                            deleteSolution={(id: string) => {
                                setSelectedId(id);
                                setModalActive(true);
                            }}
                        />
                    ))}
                </Content>
                {solutions?.data?.length > 0 && solutions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={solutions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            solutions.meta?.pagination?.current_page === solutions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${solutions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={solutions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const Content = styled.div`
    margin-top: 50px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        min-width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const UploaderWrapper = styled.div`
    .year {
        input {
            color: var(--gray);
            background: var(--white);
            border: 1px solid var(--light-gray);
            transition: opacity 0.4s ease-out;
            border-radius: 0;
            line-height: 2em;
            outline: none;
            border-radius: 2px;
            height: 34px;
            padding: 5px 5px 5px 10px;
            width: 100px;
            font-size: 14px;
            padding-right: 10px;
        }
    }
    .uploader {
        height: 150px;
        width: 150px;
        border: 2px solid var(--light-gray);
        background: #fff;
        padding: 0px;
        box-shadow: none;
        color: var(--light-gray);
        label {
            height: 150px;
            width: 150px;
        }
        .description {
            display: none;
        }
        .uploader-wrapper {
            min-height: 150px;
            height: 150px;
            width: 150px;
            padding: 0px;
            border: none;
            .upload-icon {
                height: 70px;
            }
        }
    }
`;
const Filters = styled.div`
    height: 80px;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: flex;
        float: right;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }

    .search-filter {
        display: flex;
        float: right;
        margin-right: 10px;
        right: 0;
        top: 78px;
        width: 250px;
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
`;
export default AdminTaxSolutions;
