import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatTwoDecimals } from '../../../../functions/format';
import useServicesStore from '../../../../store/services';
import getCurrentLanguage from '../../../../functions/getCurrentLanguage';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useTheme from '../../../../hooks/useTheme';

interface CartProps {
    cart: {
        active_until: string;
        created_at: string;
        id: string;
        items: { data: any[] };
        status: string;
        total_price: number;
        updated_at: string;
        user_id: string;
        type: string;
        invoice_pdf_url: string;
        packet_service_discount: number;
    };
    serialNumber?: number;
}

const CartCard: React.FC<CartProps> = ({ cart, serialNumber }) => {
    const [expandedService, setExpandedService] = useState(false);
    const [expandedPackage, setExpandedPackage] = useState(false);
    const { servicesData } = useServicesStore();

    const lang = getCurrentLanguage();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const isDarkTheme = useTheme();

    const returnServiceData = (id: string): string => {
        if (servicesData && servicesData.length > 0) {
            const service = servicesData.find((serviceS: any) => serviceS.id === id);
            return service ? `${service[`name_${lang}`]}` : 'N/A';
        } else {
            return 'N/A';
        }
    };

    const transformDateFormat = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();

        // Ensure leading zeros for single-digit day and month
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Return the transformed date in the desired format
        return `${formattedDay}.${formattedMonth}.${year}.`;
    };

    useEffect(() => {
        if (cart.type === 'service') {
            setExpandedService(true);
        } else if (cart.type === 'package') {
            setExpandedPackage(true);
        }
    }, [cart]);

    return (
        <>
            <TableRow>
                <TableCell>{serialNumber}</TableCell>
                {cart && cart.type === 'service' ? (
                    <TableCell>{t('pages.agency.wallet.services').text}</TableCell>
                ) : cart.type === 'package' ? (
                    <TableCell>
                        {t('pages.agency.wallet.package_info').text} - {cart.items.data[0].package.data.name}
                    </TableCell>
                ) : (
                    <TableCell>{t('pages.agency.wallet.payment').text}</TableCell>
                )}
                <TableCell>{transformDateFormat(cart.created_at)}</TableCell>
                <TableCell>{formatTwoDecimals(cart.total_price.toString())}</TableCell>
            </TableRow>
            {expandedService && (
                <ExpandedRow isDarkTheme={isDarkTheme}>
                    <ExpandedCell colSpan={5}>
                        <ItemTable>
                            <thead>
                                <tr>
                                    <TableHeader>{t('pages.agency.wallet.service_info').text}</TableHeader>
                                    <TableHeader>{t('pages.agency.wallet.price').text}</TableHeader>
                                    <TableHeader>{t('pages.agency.wallet.quantity').text}</TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {cart.items.data &&
                                    cart.items.data.length > 0 &&
                                    cart.items.data.map((item: any) => (
                                        <tr key={item.id}>
                                            <TableCell>{returnServiceData(item?.service?.data?.id)}</TableCell>
                                            <TableCell>
                                                {formatTwoDecimals(
                                                    (
                                                        item.price -
                                                        (item.price * cart.packet_service_discount) / 100
                                                    ).toString(),
                                                )}
                                            </TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                        </tr>
                                    ))}
                            </tbody>
                        </ItemTable>
                    </ExpandedCell>
                </ExpandedRow>
            )}
            {expandedPackage && (
                <ExpandedRow isDarkTheme={isDarkTheme}>
                    <ExpandedCell colSpan={5}>
                        <ItemTable>
                            <thead>
                                <tr>
                                    <TableHeader>{t('pages.agency.wallet.package_info').text}</TableHeader>
                                    <TableHeader>{t('pages.agency.wallet.price').text}</TableHeader>
                                    <TableHeader>{t('pages.agency.wallet.months_number').text}</TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {cart.items.data &&
                                    cart.items.data.length > 0 &&
                                    cart.items.data.map((item: any) => (
                                        <tr key={item.id}>
                                            <TableCell>{item.package.data.name}</TableCell>
                                            <TableCell>{formatTwoDecimals(item.price.toString())}</TableCell>
                                            <TableCell>{item.months}</TableCell>
                                        </tr>
                                    ))}
                            </tbody>
                        </ItemTable>
                    </ExpandedCell>
                </ExpandedRow>
            )}
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const ItemTable = styled.table`
    width: 100%;
    border-collapse: separate; /* Adjusted to handle outside borders correctly */
    border-spacing: 0;
`;

const TableRow = styled.tr`
    font-weight: normal;
`;

const TableCell = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Only bottom border */
    text-align: center;
    border-right: none; /* Remove right border */
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
`;

const ExpandedCell = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Only bottom border */
`;

const TableHeader = styled.th`
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Only bottom border */
    color: white;
    border-right: none; /* Remove right border */
`;

const ExpandedRow = styled.tr<DarkThemeProps>`
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? 'rgb(33, 33, 33)' : 'var(--purple)')};
    color: white;
`;

export default CartCard;
