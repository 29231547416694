import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { formatTwoDecimals } from '../../../functions/format';
import useStatisticStore from '../../../store/statistics';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import SingleItem from './SingleItem';
import { useNavigate } from 'react-router-dom';
import { transformDateFormat } from '../../../functions/formatDateTime';

const Statistics: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const { statisticData } = useStatisticStore();

    const today = new Date();
    const year = today.getFullYear();
    const lastYear = new Date(today);
    lastYear.setDate(today.getDate() - 365);
    const startDate = lastYear.toISOString().split('T')[0];
    const endDate = today.toISOString().split('T')[0];

    const getStatistics = (): Promise<ObjectKeys> => {
        return new Promise((resolve, reject) => {
            communication
                .getInvoiceStatistics()
                .then((res: ObjectKeys) => {
                    if (res?.status === 200) {
                        useStatisticStore.setState({ statisticData: res?.data });
                        setIsLoading(false);
                        resolve(res);
                    } else {
                        reject(new Error(`Failed to fetch statistics. Status: ${res?.status}`));
                    }
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        getStatistics();
    }, []);

    return (
        <>
            <TopSectionWrapper>
                <SingleItem
                    limit={t('pages.invoices.statistics.limitOfSixMilions').text}
                    value={formatTwoDecimals(String(statisticData.limit6issued))}
                    rest={statisticData.limit6left}
                    isLoading={isLoading}
                    onClick={() => navigate('/statistics/reports')}
                    startDate={`01.01.${year}.`}
                    endDate={`31.12.${year}.`}
                />
                <SingleItem
                    limit={t('pages.invoices.statistics.limitOfEightMilions').text}
                    value={formatTwoDecimals(String(statisticData.limit8issued))}
                    rest={statisticData.limit8left}
                    isLoading={isLoading}
                    onClick={() =>
                        navigate(`/statistics/reports/limit8milion?startDate=${startDate}&endDate=${endDate}`)
                    }
                    startDate={transformDateFormat(startDate)}
                    endDate={transformDateFormat(endDate)}
                />
            </TopSectionWrapper>
        </>
    );
};

const TopSectionWrapper = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem auto 3rem;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        width: 90%;
        gap: 10px;
    }
`;

export default Statistics;
