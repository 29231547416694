import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTheme from '../../hooks/useTheme';
import { useTipsQuery } from '../../react-query/useTipsQuery';
import { ObjectKeys } from '../../types/objectKeys';
import ConfirmModal from '../confirmModal';
import Modal from '../modal';
import { breakpoints } from '../../constants/breakpoints';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useResponsive from '../../hooks/responsive/useResponsive';

interface TooltipProps {
    targetRef: React.RefObject<HTMLElement>;
    position?: 'top' | 'right' | 'bottom' | 'left';
    isDarkMode?: boolean;
}

const InstructionsTooltip: React.FC<TooltipProps> = ({ targetRef, position = 'top' }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [currentContent, setCurrentContent] = useState<ObjectKeys>();
    const [modalActive, setModalActive] = useState<boolean>(false);
    const currentLanguage = getCurrentLanguage();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { isLaptopDevice } = useResponsive();
    const offsetValue = isLaptopDevice ? 105 : 115;
    const offsetXAxis = isLaptopDevice ? 10 : 0;

    const isDarkMode = useTheme();

    const { tips, setNextTipActive, skipGroup } = useTipsQuery({ page: 1, per_page: 5 }, 1);

    const handleNextTip = (): void => {
        setNextTipActive(1);
    };

    const handleSkipGroup = (): void => {
        skipGroup();
    };

    useEffect(() => {
        if (tips) {
            const activeTip = tips?.tips?.data?.find((tip: any) => tip.is_active);
            setCurrentContent(activeTip);
        }
    }, [tips]);

    useEffect(() => {
        const updateTooltipPosition = (): void => {
            if (targetRef.current) {
                const rect = targetRef.current.getBoundingClientRect();
                const tooltipOffset = 10;

                let top = rect.top;
                let left = rect.left;

                switch (position) {
                    case 'top':
                        top = rect.top - tooltipOffset - offsetValue;
                        left = rect.left + rect.width / 2 + offsetXAxis;
                        break;
                    case 'right':
                        top = rect.top + rect.height / 2;
                        left = rect.right + tooltipOffset;
                        break;
                    case 'bottom':
                        top = rect.bottom + tooltipOffset;
                        left = rect.left + rect.width / 2 + offsetXAxis;
                        break;
                    case 'left':
                        top = rect.top + rect.height / 2;
                        left = rect.left - tooltipOffset - 350;
                        break;
                    default:
                        break;
                }

                setTooltipPosition({ top, left });
            }
        };

        const scrollAndShowTooltip = (): void => {
            if (targetRef.current) {
                const rect = targetRef.current.getBoundingClientRect();
                const isInView =
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

                if (!isInView) {
                    targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

                    setTimeout(() => {
                        updateTooltipPosition();
                        setIsContentVisible(true);
                    }, 700);
                } else {
                    updateTooltipPosition();
                    setIsContentVisible(true);
                }
            }
        };

        scrollAndShowTooltip();

        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = '';
        };
    }, [targetRef, position]);

    const handleClose = (): void => {
        document.body.style.overflow = '';
    };

    const handleModalYes = (): void => {
        handleSkipGroup();
        handleClose();
        setModalActive(false);
        setIsVisible(false);
    };

    const handleModalNo = (): void => {
        setModalActive(false);
    };

    const [customClass, setCustomClass] = useState(false);

    const handleOutsideClick = (): void => {
        setCustomClass(true);
        setTimeout(() => {
            setCustomClass(false);
        }, 300);
    };

    const handleInsideClick = (e: any): void => {
        e.stopPropagation();
    };

    const finishTooltips = (): void => {
        handleNextTip();
        handleSkipGroup();
    };

    if (!isVisible) return null;

    return (
        <>
            {modalActive && (
                <Modal modalVisible={modalActive} closeModal={() => setModalActive(false)}>
                    <ConfirmModal
                        action={handleModalYes}
                        close={handleModalNo}
                        active={modalActive}
                        message={t('pages.agency.notifications.instructionsNotification').text}
                    />
                </Modal>
            )}
            {/* {isVisible && targetRect && <Backdrop targetRect={targetRect} tooltipRect={tooltipPosition} />} */}
            <TooltipOverlay onClick={handleOutsideClick}>
                <ContentWrapper
                    position={position}
                    isDarkMode={isDarkMode}
                    style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
                    onClick={handleInsideClick}
                >
                    <CloseButton isDarkMode={isDarkMode} onClick={() => setModalActive(true)}>
                        <span>X</span>
                    </CloseButton>
                    <span className="contentText">
                        {isContentVisible && currentContent?.[`content_${currentLanguage}`]}
                    </span>
                    <BottomActionButton
                        isDarkMode={isDarkMode}
                        onClick={currentContent?.order < 32 ? handleNextTip : finishTooltips}
                        className={`${customClass && 'tempRed'}`}
                    >
                        {currentContent?.order < 32 ? t('buttons.next').text : t('buttons.done').text}
                    </BottomActionButton>
                </ContentWrapper>
            </TooltipOverlay>
        </>
    );
};

export default InstructionsTooltip;

const TooltipOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    pointer-events: auto;
    zoom: normal;

    @media (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        zoom: 110%;
    }

    @media (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.laptopL - 1}px) {
        zoom: 110%;
    }

    @media (min-width: ${breakpoints.laptopL}px) and (max-width: ${breakpoints.desktop - 1}px) {
        zoom: 110%;
    }

    @media (min-width: ${breakpoints.desktop}px) and (max-width: ${breakpoints.desktopL - 1}px) {
        zoom: normal;
    }
`;

const ContentWrapper = styled.div<{ position: TooltipProps['position']; isDarkMode: TooltipProps['isDarkMode'] }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(325px, 90vw);
    min-width: 180px;
    height: fit-content;
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#616161' : '#fff')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'black')};
    text-align: center;
    padding: clamp(5px, 1.5vw, 10px) clamp(15px, 5vw, 30px);
    position: absolute;
    border: ${({ isDarkMode }) => (isDarkMode ? 'none' : '1px solid #616161')};
    z-index: 10000;
    transition: opacity 0.3s;
    pointer-events: auto;
    font-weight: 600;
    font-size: clamp(10px, 1.5vw, 14px);
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;

    .tempRed {
        opacity: 0.5;
        background-color: #ff6565;
    }

    .contentText {
        min-height: 38px;
    }

    ${({ position }) => {
        switch (position) {
            case 'top':
                return 'bottom: 125%; left: 50%; transform: translateX(-50%);';
            case 'right':
                return 'top: 50%; left: 125%; transform: translateY(-50%);';
            case 'bottom':
                return 'top: 125%; left: 50%; transform: translateX(-50%);';
            case 'left':
                return 'top: 50%; right: 125%; transform: translateY(-50%);';
            default:
                return '';
        }
    }}

    &::after {
        content: '';
        position: fixed;
        border-width: 5px;
        border-style: solid;
        ${({ position }) => {
            switch (position) {
                case 'top':
                    return 'top: 100%; left: 50%; margin-left: -5px; border-color: #616161 transparent transparent transparent;';
                case 'right':
                    return 'top: 50%; right: 100%; margin-top: -5px; border-color: transparent #616161 transparent transparent;';
                case 'bottom':
                    return 'bottom: 100%; left: 50%; margin-left: -5px; border-color: transparent transparent #616161 transparent;';
                case 'left':
                    return 'top: 50%; left: 100%; margin-top: -5px; border-color: transparent transparent transparent #616161;';
                default:
                    return '';
            }
        }}
    }

    @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px) {
        width: 180px;
        padding: 35px 3px 10px 3px;
    }
    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        width: 250px;
        font-size: 12px;
    }
    @media screen and (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.laptopL - 1}px) {
        font-size: 12px;
    }
`;

const CloseButton = styled.button<{ isDarkMode: TooltipProps['isDarkMode'] }>`
    position: absolute;
    top: 6px;
    right: 8px;
    background: none;
    border: none;
    padding: 4px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'var(--black)')};
    height: auto;
    cursor: pointer;
    font-size: 12px;
    transition: opacity 0.3s ease;
    font-weight: ${({ isDarkMode }) => (isDarkMode ? 'normal' : '800')};

    &:hover {
        opacity: 0.7;
    }
`;

const BottomActionButton = styled.button<{ isDarkMode: TooltipProps['isDarkMode'] }>`
    display: block;
    width: 60%;
    margin-top: 8px;
    padding: 4px;
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'var(--purple)')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#fff')};
    font-weight: 600;
    border: ${({ isDarkMode }) => (isDarkMode ? 'none' : '1px solid #616161')};
    margin-top: 20px;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        font-size: 12px;
    }
    @media screen and (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.laptopL - 1}px) {
        font-size: 12px;
    }
`;
