import styled from 'styled-components';
import { NotificationsController } from './NotificationsController';
import { UserSettingsController } from './UserSettingsController';
import DarkThemeButton from '../themeToggleButton';
import InstructionsTooltip from '../../components/instructionsTooltip/index';
import { useRef } from 'react';
import useTheme from '../../hooks/useTheme';

export const TopBar = () => {
    const isDarkTheme = useTheme();
    const role = localStorage.getItem('role');
    const isAdmin = role === 'admin';
    const navbarRef = useRef<HTMLDivElement>(null);
    return (
        <TopBarContainer isDarkTheme={isDarkTheme} ref={navbarRef}>
            {/* <InstructionsTooltip position="bottom" content="IDEMO BRE" targetRef={navbarRef} /> */}
            <DarkThemeButton />
            {!isAdmin ? <NotificationsController /> : null}
            <UserSettingsController />
        </TopBarContainer>
    );
};

interface DarkThemeProps {
    isDarkTheme: boolean;
}
const TopBarContainer = styled.div<DarkThemeProps>`
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--body-gray)')};
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    padding: 1.07rem 50px;
    height: 55px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .bell-icon {
        font-size: 1.8rem;
    }
    .user-icon {
        font-size: 1.6rem;
    }
`;
