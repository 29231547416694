import React from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../../components/tooltipMain';
import { ObjectKeys } from '../../../../../types/objectKeys';
import colors from '../../../../../global/colors';
import { formatTwoDecimals } from '../../../../../functions/format';
import useResponsive from '../../../../../hooks/responsive/useResponsive';
import { breakpoints } from '../../../../../constants/breakpoints';

interface PackageProps {
    data?: ObjectKeys;
    onEdit: () => void;
    onDelete: () => void;
    onClick: () => void;
}

const AdminConsultationsCard: React.FC<PackageProps> = ({ data, onEdit, onDelete, onClick }) => {
    const tooltipText = data?.description_rs;

    const { isTabletAndDown } = useResponsive();
    return (
        <Card onClick={onClick}>
            <div className="cardTitle">
                <p>{data?.name_rs}</p>
            </div>

            <ActionContainer>
                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => onEdit()}>
                    <FontAwesomeIcon icon={faEdit} color={colors.lightBlue} />
                    <span className="buton-title">Izmeni</span>
                </Button>
                <Button variant={ButtonVariant.solid} color={'transparent'} size={'30%'} onClick={() => onDelete()}>
                    <FontAwesomeIcon icon={faTrash} color={colors.danger} />
                    <span className="buton-title">Obriši</span>
                </Button>
            </ActionContainer>

            <div className="courier-services">
                <ul>
                    {data?.services?.data?.map((service: any) => (
                        <li key={service.id} className="price-container">
                            <span className="service-name">{service.name_rs}</span>
                            <span className="dots"></span>
                            <span className="price">{formatTwoDecimals(service.price.toString())} RSD</span>
                        </li>
                    ))}
                </ul>
                <p className="note">*Cene su izrazene bez popusta koji ide uz odgovarajuci paket</p>
            </div>

            <div className="tooltip">
                <Tooltip
                    text={tooltipText}
                    isHtml={true}
                    position="custom"
                    show={tooltipText}
                    customPositioning={
                        isTabletAndDown
                            ? { top: '50%', right: '100%', transform: 'translateY(-50%)' }
                            : { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
                    }
                    zIndex={10}
                    label={
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            fontSize={'15px'}
                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                            color={colors.purple}
                        />
                    }
                />
            </div>
        </Card>
    );
};

const Card = styled.div`
    height: 100%;
    width: 90%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--purple);
    position: relative;

    .cardTitle {
        display: flex;
        justify-content: center;

        p {
            font-weight: 600;
            font-size: 20px;
            max-width: 250px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .services {
        margin: 20px 0;
        height: 95px;

        @media screen and (min-width: ${breakpoints.tablet}px) {
            height: 75px;
        }
    }

    .price-container {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 5px 0;

        .needCourier,
        .noService {
            white-space: nowrap;
        }
    }

    .price-label {
        white-space: nowrap;
    }

    .dots {
        flex-grow: 1;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }

    .courier-services {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 20px;

        .needCourier,
        .noService {
            display: flex;
            align-items: center;
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 20px;
            width: 15px;
            margin-right: 10px;
        }
    }

    .callToAction {
        display: flex;
        justify-content: center;
        margin-top: auto;
    }

    .tooltip {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .note {
        margin-top: 10px;
        font-size: 12px;
        color: var(--gray);
    }
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .buton-title {
        margin-left: 5px;
        color: black;
    }
`;

export default AdminConsultationsCard;
