import { useQuery } from '@tanstack/react-query';
import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { queryClient } from '../App';

const fetchMemos = async (role: string, user_id: string) => {
    try {
        let response;
        if (role === 'admin') {
            response = await communication.getAllMemosForAdminUser(user_id);
        } else {
            response = await communication.getAllMemos();
        }

        if (isStatusSuccess(response.status)) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch memos');
        }
    } catch (error) {
        console.error(error);
    }
};

export const useMemosQuery = (role: string, user_id: string) => {
    const invalidate = (): Promise<void> => {
        return queryClient.invalidateQueries({ queryKey: ['memos', role, user_id] });
    };

    const remove = (): void => {
        return queryClient.removeQueries({ queryKey: ['memos', role, user_id] });
    };

    const {
        data: memos,
        refetch,
        isLoading,
        isFetching,
        isRefetching,
        error,
    } = useQuery({
        queryKey: ['memos', role, user_id], // Key changes based on role and user_id
        queryFn: () => fetchMemos(role, user_id),
        staleTime: 1000 * 60 * 10, // 10 minutes
    });

    return { memos, refetch, isLoading, isFetching, error, invalidate, isRefetching, remove };
};
