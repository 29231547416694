const apiUrl = process.env.REACT_APP_API_URL;
import { ObjectKeys } from '../types/objectKeys';

const routes = {
    guest: {
        getServices: `${apiUrl}/package-services`,
        createCart: `${apiUrl}/carts-no-user`,
        getSignature: `${apiUrl}/payment-gateway/signature/get`,
        getCartById: (id: string) => `${apiUrl}/carts/${id}`,
        paymentResponse: `${apiUrl}/payment-gateway/payments`,
    },
    auth: {
        login: `${apiUrl}/clients/web/login`,
        logout: `${apiUrl}/logout`,
        activityCodes: `${apiUrl}/activity-codes`,
        municipalities: `${apiUrl}/municipalities?orderBy=name&sortedBy=asc`,
        register: `${apiUrl}/register`,
        agencyDataBasedOnVatNum: (id: string) => `${apiUrl}/clients/apr/${id}`,
        activationLink: `${apiUrl}/users/send-confirmation-mail`,
        confirmEmail: `${apiUrl}/confirm-email`,
        refreshToken: `${apiUrl}/clients/web/refresh`,
    },
    getCurrencies: `${apiUrl}/currency/api`,
    getPickedCurrencies: (date: string) => `${apiUrl}/currency/api?date=${date}`,
    years: `${apiUrl}/my/agency/years`,
    quotationYears: `${apiUrl}/my/agency/quotation/years`,
    getCountries: `${apiUrl}/countries`,
    notifications: {
        create: `${apiUrl}/create-draft-alerts`,
        createAndSend: `${apiUrl}/alerts`,
        getNotifications: `${apiUrl}/count/read/alerts`,
        getUserNotifications: `${apiUrl}/alerts`,
        getNotificationById: (id: string) => `${apiUrl}/alerts/${id}`,
        getUnreadCound: `${apiUrl}/alerts/unread/count`,
        update: (id: string) => `${apiUrl}/alerts/${id}`,
        send: (id: string) => `${apiUrl}/sent-alert-to-all-active-user/${id}`,
        delete: (id: string) => `${apiUrl}/delete-alerts-from-bo/${id} `,
        markAsRead: `${apiUrl}/alert/read`,
        markAllAsRead: `${apiUrl}/alerts/read`,
    },
    invoices: {
        invoice: (id: string) => `${apiUrl}/invoices/${id}`,
        myInvoices: `${apiUrl}/my/invoices`,
        statistics: `${apiUrl}/invoice/statistics`,
        getInvoiceNumber: (year: string) => `${apiUrl}/get-feature-invoice-number-and-last-prefix/${year}`,
        importInvoicesFromExcel: `${apiUrl}/import-excel-invoices`,
        multipleActionInvoices: (action: string): string => `${apiUrl}/invoices-multiple/${action}`,
    },
    invoice: {
        byId: (id: string) => `${apiUrl}/invoice/${id}`,
        copy: (id: string) => `${apiUrl}/invoices/${id}/copy`,
        cancel: (id: string) => `${apiUrl}/invoices/${id}/cancel`,
        payment: (id: string) => `${apiUrl}/invoices/${id}/charge`,
        eInvoice: `${apiUrl}/einvoices`,
        sendInvoice: (id: string) => `${apiUrl}/invoices/${id}/send`,
        sendIos: `${apiUrl}/statistics/send/ios`,
        downloadInvoice: (id: string) => `${apiUrl}/invoices/${id}/pdf`,
        refreshEInvoice: `${apiUrl}/einvoices-refresh-status`,
        new: (type: string) => `${apiUrl}/invoices/${type}`,
        specificPayment: (invoiceId: string, paymentId: string) =>
            `${apiUrl}/invoices/${invoiceId}/payments/${paymentId}`,
        removeAllPayments: (id: string) => `${apiUrl}/invoices/${id}/remove-payments`,
        prepaidCheck: `${apiUrl}/invoices/prepaid-invoice-check`,
    },
    services: {
        service: `${apiUrl}/services`,
        archived: `${apiUrl}/services/archived`,
        byId: (id: string) => `${apiUrl}/services/${id}`,
        activate: (id: string) => `${apiUrl}/services/${id}/activate`,
    },
    clients: {
        archivedClients: `${apiUrl}/my/clients/archived`,
        users: (id: string) => `${apiUrl}/clients/users/${id}`,
        byId: (id: string) => `${apiUrl}/clients/${id}`,
        activate: (id: string) => `${apiUrl}/clients/${id}/activate`,
        addClient: `${apiUrl}/clients`,
    },
    proforma: {
        myProforma: `${apiUrl}/my/quotations`,
        byId: (id: string) => `${apiUrl}/quotation/${id}`,
        getById: (id: string) => `${apiUrl}/quotations/${id}`,
        copy: (id: string) => `${apiUrl}/quotations/${id}/copy`,
        sendProforma: (id: string) => `${apiUrl}/quotations/${id}/send`,
        downloadProforma: (id: string) => `${apiUrl}/quotations/${id}/pdf`,
        domesticProforma: `${apiUrl}/invoices/domestic`,
        foreignProforma: `${apiUrl}/invoices/foreign`,
        newProforma: (type: string) => `${apiUrl}/quotations/${type}`,
        multipleActionProformas: (action: string): string => `${apiUrl}/quotations-multiple/${action}`,
    },
    agency: {
        //profile and personal info
        get: `${apiUrl}/my/agency`,
        uploadLogo: (id: string) => `${apiUrl}/agency/${id}/logo`,
        deleteLogo: (id: string) => `${apiUrl}/agency/${id}/logo`,
        update: `${apiUrl}/agency`,
        //send data
        sendData: `${apiUrl}/agency`,
        //notifications
        getNotifications: `${apiUrl}/notifications/settings`,
        updateNotifications: `${apiUrl}/notifications/settings/update`,
        //documents
        getDocuments: `${apiUrl}/files`,
        updateDocument: (id: string) => `${apiUrl}/files/${id}`,
        downloadDocument: (documentId: string, token: string) =>
            `${apiUrl}/files/${documentId}/download?token=${token}`,
        sendDataDocument: (id: string) => `${apiUrl}/files/${id}/send`,
        deleteDocument: (id: string) => `${apiUrl}/files/${id}`,
        uploadDocument: `${apiUrl}/files`,
        //change password
        changePassword: `${apiUrl}/password/change`,
        //payment instructions
        sendDocument: (id: string) => `files/${id}/send`,
        sendForeginInstruction: `${apiUrl}/instructions`,
        getAllInstruction: `${apiUrl}/instructions`,
        deleteInstruction: (id: string) => `${apiUrl}/instructions/${id}`,
        downloadInstruction: (id: string, token: string | null) =>
            `${apiUrl}/instructions/${id}/download?token=${token}`,
        getAllTaxSolution: () => `${apiUrl}/solutions`,
        sendTaxSolutions: `${apiUrl}/solutions`,
        sendNotification: `${apiUrl}/solutions/send-notifications`,
        deleteTaxSolution: (id: string) => `${apiUrl}/solutions/${id}`,
        downloadTaxSolution: (id: string, token: string | null) => `${apiUrl}/solutions/${id}/download?token=${token}`,
        updateEinvoice: (id: string) => `${apiUrl}/agency/${id}`,
        getTemplates: `${apiUrl}/preferred-user-templates`,
    },
    profile: {
        get: `${apiUrl}/my/profile?include=agency,payee`,
    },
    memorandums: {
        main: (page: string, limit: any, status: any, find: string) => {
            const baseUrl = `${apiUrl}/my/memorandums/`;
            const params = new URLSearchParams({
                search: status ? `status:${status};preferred:0` : 'preferred:0',
                page: String(page),
                limit: String(limit),
                find: find,
                searchJoin: 'and',
                searchFields: status ? 'status:=;preferred:=' : 'preferred:=',
                orderBy: 'created_at',
                sortedBy: 'desc',
            });

            return `${baseUrl}?${params.toString()}`;
        },
        second: `${apiUrl}/my/memorandums/?search=preferred:1&page=1&limit=5000&`,
        forInvoice: `${apiUrl}/my/memorandums/?search=preferred:0&page=1&limit=5000`,
        forAdminUser: `${apiUrl}/memorandums`,
        create: `${apiUrl}/memorandums`,
        send: (id: string | undefined) => `${apiUrl}/memorandums/${id}/send`,
        specific: (id: string | undefined) => `${apiUrl}/memorandums/${id}`,
        download: (id: string | undefined) => `${apiUrl}/memorandums/${id}/download`,
        downloadWord: (id: string | undefined) => `${apiUrl}/memorandums/${id}/download/word`,
    },
    suggestion: {
        get: `${apiUrl}/suggestions`,
    },
    forgotPassword: {
        sendRequest: `${apiUrl}/password/email`,
        resetPassword: `${apiUrl}/password/reset`,
        getEmailFromToken: `${apiUrl}/user/password-reset-token`,
    },
    user: {
        put: `${apiUrl}/users`,
        authorize: (id: string) => `${apiUrl}/users/${id}`,
        tawkTo: `${apiUrl}/get-tawk-password`,
    },
    healthCards: {
        get: `${apiUrl}/mybooks?include=municipality`,
        renew: (id: string) => `${apiUrl}/books/${id}/renew`,
        mform: (id: string) => `${apiUrl}/books/${id}/m-form`,
        cancel: (id: string) => `${apiUrl}/books/${id}/checkout`,
        update: (id: string) => `${apiUrl}/book/${id}`,
        create: `${apiUrl}/book`,
    },
    accountExpiration: {
        generateQr: 'https://nbs.rs/QRcode/api/qr/generate/',
        generateTemplateTransfer: `${apiUrl}/template-transfers`,
        downloadTransferOrder: (id: string) => `${apiUrl}/transfers/${id}/pdf`,
    },
    transfers: {
        createTransferOrder: () => `${apiUrl}/template-transfers`,
        updateTransferOrder: (id: string) => `${apiUrl}/template-transfers/${id}`,
        createTaxOrder: () => `${apiUrl}/my/template-transfers/tax`,
        getCallNumber: (year: string, id: string) => `${apiUrl}/transfers/call-number/${id}?year=${year}`,
        deleteTransferOrder: (id: string) => `${apiUrl}/template-transfers/${id}`,
        getTransfers: () => `${apiUrl}/my/template-transfers`,
        downloadTax: (id: string) => `${apiUrl}/transfers/${id}/pdf`,
        downloadIndependence: () => `${apiUrl}/transfers-print-pdf`,
        downloadTaxes: (year: string, month: string | number) => `${apiUrl}/my/transfers/${year}/${month}/pdf`,
        searchTransfers: (search: string) =>
            `${apiUrl}/my/template-transfers?search=payment_slip_heading:${search}&orderBy=created_at&sortedBy=desc&searchFields=payment_slip_heading:like&searchJoin=and`,
        getTransferTemplates: (year: string, month: string) =>
            `${apiUrl}/my/template-transfers?search=month:${month}%3Byear:${year}%3Btype:tax&searchJoin=and&searchFields=year:%3D%3Bmonth:%3D%3Btype:%3D`,
        getTransferByAgencyId: (id: string, year: string, month: string) =>
            `${apiUrl}/my/agency/pausals/${id}/transfers?search=year:${year}%3Bmonth:${month}%3B&searchJoin=and&searchFields=year:%3D%3Bmonth:%3D`,
        downloadTaxesAdmin: (id: string, year: string, month: string) =>
            `${apiUrl}/my/agency/pausals/${id}/transfers/tax/${year}/${month}/pdf`,
        downloadTaxAdmin: (id: string) => `${apiUrl}/transfers/${id}/pdf`,
        createTransferOrderAdmin: (id: string) => `${apiUrl}/my/agency/pausals/${id}/my/transfers`,
    },
    ecotax: {
        getEcotaxTransfers: (year: string) =>
            `${apiUrl}/eko-taxes?search=year:${year}%3B&searchJoin=and&searchFields=year:%3D`,
        getAdminEcotaxTransfers: (year: string, user_id: string) =>
            `${apiUrl}/eko-taxes?search=year:${year}%3B&searchJoin=and&searchFields=year:%3D&user_id=${user_id}`,
        createEcotaxTransfer: `${apiUrl}/create-quarterly-eko-tax`,
        updateEcotaxTransfer: (id: string) => `${apiUrl}/update-all-quarters-for-eko-tax/${id}`,
        getEcotaxSolution: `${apiUrl}/eko-tax-solutions`,
        getAllAdminEcotaxSolution: `${apiUrl}/list-all-eko-tax-solutions`,
        sendEcotaxSolution: `${apiUrl}/eko-tax-solutions`,
        deleteEcotaxSolution: (id: string) => `${apiUrl}/eko-tax-solutions/${id}`,
        downloadEcotaxes: (year: string) => `${apiUrl}/download-quarterly-for-year/${year}`,
        downloadSingleEcotax: `${apiUrl}/download-eko-tax-template-pdf`,
        updatePayment: `${apiUrl}/pay_eko-tax-year`,
        updateQuarterPayment: (id: string) => `${apiUrl}/quarterly-eko-taxes/${id}`,
        downloadEcotaxSolution: (id: string) => `${apiUrl}/eko-tax-solution/${id}/download`,
    },
    taxCalendar: {
        get: (year: number, status?: string, month?: string) =>
            `${apiUrl}/my/tax-calendars?search=${month ? `month:${month}%3B` : ''}${
                status ? `%3Bstatus:${status}%3B` : ''
            }${year ? `year:${year}%3B` : ''}&searchJoin=and&searchFields=year:%3D%3B${status ? 'status:%3D%3B' : ''}${
                month ? 'month:%3D' : ''
            }`,
        create: `${apiUrl}/tax-calendars`,
        update: (id: string) => `${apiUrl}/tax-calendars/${id}`,
        getSums: (year: number) =>
            `${apiUrl}/my/tax-calendars/sums?search=year:${year}&searchJoin=and&searchFields=year:%3D`,
    },
    paymentAccounts: {
        getAllAccounts: `${apiUrl}/accounts`,
    },
    paymentGateway: {
        getSignature: `${apiUrl}/payment-gateway/signature/get`,
        validateSignature: `${apiUrl}/payment-gateway/signature/validate`,
        paymentResponse: `${apiUrl}/payment-gateway/payments`,
        createCart: `${apiUrl}/carts`,
        getCarts: `${apiUrl}/carts`,
        getCartById: (id: string) => `${apiUrl}/carts/${id}`,
        getUserCarts: `${apiUrl}/my/carts`,
        getUserCartById: (id: string) => `${apiUrl}/carts/get-by-service-name/${id}`,
        getItems: `${apiUrl}/cart-items`,
        getUserPaymentHistory: (id: string) => `${apiUrl}/user-payment-history/${id}`,
        getActivePackagePeriod: `${apiUrl}/user-payment-history-current`,
        payViaToken: `${apiUrl}/payment-gateway/execute-manual-recurring-payment`,
    },
    bankAccounts: {
        get: `${apiUrl}/bank-accounts?orderBy=created_at&sortedBy=asc`,
        create: `${apiUrl}/bank-accounts`,
        update: (id: string) => `${apiUrl}/bank-accounts/${id}`,
        delete: (id: string) => `${apiUrl}/bank-accounts/${id}`,
        setDefault: `${apiUrl}/bank-accounts/set-default`,
    },
    banks: {
        get: (number: number) => `${apiUrl}/banks/by-number/${number}`,
        getAllBanks: () => `${apiUrl}/get-all-banks`,
    },
    kpo: {
        sendToEmail: (year: string) => `${apiUrl}/kpo/${year}/email`,
        downloadKpo: (year: string) => `${apiUrl}/kpo/${year}/download`,
    },
    reports: {
        getAllReports: (page: number) => `${apiUrl}/statistics?limit=20&page=${page}`,
        getReports: () => `${apiUrl}/statistics?limit=2000`,
        getDoughnut: () => `${apiUrl}/invoices/statistics/donut`,
        getTop5: () => `${apiUrl}/clients/statistic/top-5`,
        getTopDebtors: () => `${apiUrl}/get-top-5-debtors-statistic`,
        getAllClients: (userid: string) =>
            `${apiUrl}/clients/users/${userid}?page=1&limit=2000&searchJoin=and&search=&include_archived=true`,
        downloadPdf: `${apiUrl}/statistics/download/pdf`,
        downloadXlsx: `${apiUrl}/statistics/download/xlsx`,
    },
    tips: {
        getGroups: `${apiUrl}/group-user`,
        updateGroup: (id: string) => `${apiUrl}/group-user/${id}`,
    },
    admin: {
        users: {
            allUsers: `${apiUrl}/search-users`,
            usersById: (id: string) => `${apiUrl}/users/${id}?include=agency`,
            profile: `${apiUrl}/my/profile?include=agency,payee`,
            byId: (id: string) => `${apiUrl}/users/${id}`,
            confirm: (id: string) => `${apiUrl}/users/${id}/confirm`,
        },
        invoices: {
            invoicesByUserId: (id: string) => `${apiUrl}/back-office/invoices/${id}/get`,
            statisticsByUserId: (id: string) => `${apiUrl}/invoice/statistics/${id}`,
        },
        proforma: {
            proformaByUserId: `${apiUrl}/back-office/quotations`,
        },
        suggestions: {
            get: `${apiUrl}/suggestions`,
            sendResponse: (id: string) => `${apiUrl}/suggestions/${id}/answer`,
        },
        transactions: {
            getAllTransactions: `${apiUrl}/transactions`,
            getFilteredTransactions: `${apiUrl}/transactions`,
            createNewTransaction: `${apiUrl}/users/transaction`,
            createTransaction: `${apiUrl}/transactions/carts`,
            createManualTransaction: `${apiUrl}/user-payment-history/transaction`,
            updateTransaction: (id: string) => `${apiUrl}/users/transaction/${id}`,
            deleteTransaction: (id: string) => `${apiUrl}/users/transaction/${id}`,
            getUsersByPib: (pib: string) => `${apiUrl}/users/pib/${pib}`,
            getAgencyById: (id: string) => `${apiUrl}/agency/${id}`,
        },
        notifications: {
            sendNotification: `${apiUrl}/notifications/new-feature`,
        },
        statistics: {
            registration: `${apiUrl}/user/registration/statistics`,
            sendResponse: (id: string) => `${apiUrl}/suggestions/${id}/answer`,
            transactions: `${apiUrl}/transactions/statistics`,
            getUsers: `${apiUrl}/users`,
            getUsersByBank: (bankId: string) => `${apiUrl}/users/banks/${bankId}`,
            getBanks: `${apiUrl}/banks`,
            downloadUsers: (filters: ObjectKeys) =>
                `${apiUrl}/users/banks/${filters.bank_id}?start_date=${filters.start_date}&end_date=${filters.end_date}&bank_id=${filters.bank_id}`,
        },
        tax: {
            getAllSolutions: `${apiUrl}/solutions-all`,
            getSolutionsByUser: (id: string) => `${apiUrl}/solutions/${id}/list`,
            downloadSolution: (id: string) => `${apiUrl}/solutions/${id}/download`,
            uploadSolution: (id: string) => `${apiUrl}/solutions/${id}/create`,
            sendEmail: `${apiUrl}/transfers_created/send`,
        },
        packages: {
            getAllPackages: `${apiUrl}/payment-packages`,
            createPackage: `${apiUrl}/payment-packages`,
            updatePackage: (id: string) => `${apiUrl}/payment-packages/${id}`,
            deletePackage: (id: string) => `${apiUrl}/payment-packages/${id}`,
        },
        services: {
            getAllAdminServices: `${apiUrl}/package-services`,
            createAdminService: `${apiUrl}/package-services`,
            updateAdminService: (id: string) => `${apiUrl}/package-services/${id}`,
            deleteAdminService: (id: string) => `${apiUrl}/package-services/${id}`,
        },
        servicePackages: {
            getServicePackages: `${apiUrl}/service-bundles`,
            createServicePackage: `${apiUrl}/service-bundles`,
            editServicePackage: (id: string) => `${apiUrl}/service-bundles/${id}`,
            deleteServicePackage: (id: string) => `${apiUrl}/service-bundles/${id}`,
        },
        requests: {
            servicesPdf: `${apiUrl}/cart-items-download-pdf`,
            cartsPdf: `${apiUrl}/download-all-carts-in-pdf`,
            cartsWithoutPagination: `${apiUrl}/get-all-cards-without-pagination`,
            servicesWithoutPagination: `${apiUrl}/cart-items-without-pagination`,
            updateClickUp: (id: string) => `${apiUrl}/carts/items/${id}`,
            resendFiscalBill: `${apiUrl}/generate-fiscal-invoice-from-bo`,
        },
    },
};

export default routes;
