import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../../components/select';
import moment from 'moment';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import useAgencyStore from '../../../../store/agency';
import Loader from '../../../../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportToExcel from '../../../../functions/downloadXlsx';
import Input, { InputComponentType } from '../../../../components/input';
import { formatTwoDecimals } from '../../../../functions/format';
import Tooltip from '../../../../components/tooltipMain';
import Success from '../../transferOrders/successModal';
import Modal from '../../../../components/modal';
import NoItemsPhoto from '../../../../assets/empty-book.png';
import ToggleSwitch from '../../../../components/input/switch';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import useTheme from '../../../../hooks/useTheme';

const PaymentList: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const navigate = useNavigate();
    const paramsYear = useParams().year;
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [annualTaxData, setAnnualTaxData] = useState<ObjectKeys>([]);
    const [quarterlyTaxData, setQuarterlyTaxData] = useState<ObjectKeys>([]);

    const [pendingSum, setPendingSum] = useState('');
    const [paidSum, setPaidSum] = useState('');

    const [warningModal, setWarningModal] = useState(false);
    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());
    const { isTabletAndDown } = useResponsive();

    const [params, setParams] = useState({
        year: paramsYear ? paramsYear : moment().year().toString(),
        status: '',
    });

    const [annualShow, setAnnualShow] = useState<boolean>(false);

    const [loaderVisible, setLoaderVisible] = useState(false);

    const isDarkTheme = useTheme();

    async function getEcoTaxes(): Promise<void> {
        setLoaderVisible(true);
        try {
            await communication
                .getAllEcotaxTransfers(params.year)
                .then((res: ObjectKeys) => {
                    setLoaderVisible(false);
                    if (res.data.data.length > 0) {
                        setPaidSum(res.data.data[0].paid_amount.toString());
                        const pending = res.data.data[0].total_amount - res.data.data[0].paid_amount;
                        setPendingSum(pending.toString());
                        setAnnualTaxData(res.data.data[0]);
                        const parentData = res.data.data[0];
                        const quarterlyData = parentData.quarterly_eko_taxes.data;

                        const ecotaxQuarterly = quarterlyData
                            .map((item: any) => {
                                return {
                                    ...parentData,
                                    id: item.id,
                                    paid_amount: item.paid_amount,
                                    paid_date: item.paid_date,
                                    quarter: item.quarter,
                                    status: item.status,
                                    total_amount: item.total_amount,
                                };
                            })
                            .sort((a: any, b: any) => {
                                const quarterA = parseInt(a.quarter.split('-')[0], 10);
                                const quarterB = parseInt(b.quarter.split('-')[0], 10);
                                return quarterA - quarterB; // Sort by quarter number
                            });

                        setQuarterlyTaxData(ecotaxQuarterly);
                    } else {
                        setAnnualTaxData([]);
                        setQuarterlyTaxData([]);
                    }
                })
                .catch((error: any) => {
                    if (error) {
                        setLoaderVisible(false);
                        toast.error(error.response.data.message);
                    }
                });
        } catch (syncError: any) {
            setLoaderVisible(false);
        }
    }

    const removePayment = (item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            id: item.id,
            paid_date: null,
        };
        try {
            communication
                .updateEcotaxAnnual(payload)
                .then(() => {
                    toast.success('Uspesno ste uneli datum placanja');
                    getEcoTaxes();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const updateEcotaxPayment = (date: Date, item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            id: item.id,
            paid_date: moment(date).format('YYYY-MM-DD'),
        };
        try {
            communication
                .updateEcotaxAnnual(payload)
                .then(() => {
                    toast.success('Uspesno ste uneli datum placanja');
                    getEcoTaxes();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const updateQuarterPayment = (date: Date, item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            paid_amount: item.total_amount,
            paid_date: moment(date).format('YYYY-MM-DD'),
        };
        try {
            communication
                .updateEcotaxQuarterly(item.id, payload)
                .then(() => {
                    toast.success('Uspesno ste uneli datum placanja');
                    getEcoTaxes();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const removeQuarterPayment = (item: ObjectKeys): void => {
        setLoaderVisible(true);
        const payload = {
            paid_amount: 0,
            paid_date: null,
        };
        try {
            communication
                .updateEcotaxQuarterly(item.id, payload)
                .then(() => {
                    toast.success('Uspesno ste uneli datum placanja');
                    getEcoTaxes();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    useEffect(() => {
        generateYearsForFilters();
    }, []);

    useEffect(() => {
        getEcoTaxes();
    }, [params]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
        }
    }, [paramsYear]);

    const generateYearsForFilters = (): void => {
        setLoaderVisible(true);
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
        setLoaderVisible(false);
    };
    const formatDate = (inputDate: string): string => {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are zero-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}.`;
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            <Container>
                <Filters isDarkTheme={isDarkTheme}>
                    <div className="filter-single">
                        <label className="second-title">{t('pages.transfers.payment_lists.year').text}</label>
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find(
                                (option) =>
                                    option.value === parseInt(paramsYear ? paramsYear : moment().year().toString()),
                            )}
                            className={'select-year'}
                            handleSelectedValue={(value: string) => {
                                setParams((prevState) => ({ ...prevState, year: value }));
                                navigate(`/transfer-orders/payment-list/${value}`);
                                setSelectedYear(value);
                            }}
                        />
                    </div>
                    {pendingSum && paidSum ? (
                        <div className="amount-rest">
                            <p className="third-title">
                                {t('pages.transfers.payment_lists.paid_sum').text} (RSD): {formatTwoDecimals(paidSum)}
                            </p>
                            <p className="third-title">
                                {t('pages.transfers.payment_lists.unpaid_sum').text} (RSD):{' '}
                                {formatTwoDecimals(pendingSum)}
                            </p>
                        </div>
                    ) : null}
                </Filters>
                {annualTaxData.id ? (
                    <>
                        <ToggleRow>
                            <ToggleSwitch
                                isChecked={annualShow}
                                onChange={() => {
                                    setAnnualShow(!annualShow);
                                }}
                                isDisabled={false}
                                offLabel={t('pages.eco_tax_transfer.annual').text}
                                onLabel={t('pages.eco_tax_transfer.quarterly').text}
                            />
                        </ToggleRow>
                        {annualShow ? (
                            <TableContainer>
                                {annualTaxData ? (
                                    <Table isDarkTheme={isDarkTheme}>
                                        <thead>
                                            <tr>
                                                <th>{t('pages.eco_tax_transfer.year').text}</th>
                                                <th>{t('navbar.pausal.ecotax').text} (RSD)</th>
                                                <th>{t('pages.transfers.payment_lists.paid').text}</th>
                                                <th>{t('pages.transfers.payment_lists.date').text}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="gray-style">{params.year}</td>
                                                <td className="gray-style">{annualTaxData?.total_amount}</td>
                                                <td>
                                                    {annualTaxData?.status === 'paid' ? (
                                                        <div className="icon-container-paid">
                                                            <FontAwesomeIcon
                                                                icon={faCheckCircle}
                                                                className="check-icon"
                                                            />
                                                        </div>
                                                    ) : annualTaxData?.status === 'pending' ? (
                                                        <div className="icon-container-unpaid">
                                                            <FontAwesomeIcon
                                                                icon={faExclamationCircle}
                                                                className="check-icon"
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                                <td>
                                                    {annualTaxData?.status === 'paid' ? (
                                                        <div className="date-div">
                                                            {formatDate(annualTaxData?.paid_date) || ''}
                                                            <div
                                                                onClick={() => {
                                                                    removePayment(annualTaxData);
                                                                }}
                                                                className="checkbox"
                                                            >
                                                                <div className="checkbox">
                                                                    <Tooltip
                                                                        text={
                                                                            t('pages.transfers.remove_payment_date')
                                                                                .text
                                                                        }
                                                                        position={`${
                                                                            isTabletAndDown ? 'hardLeft' : 'top'
                                                                        }`}
                                                                        label={
                                                                            <FontAwesomeIcon
                                                                                icon={faTimes}
                                                                                className="checked"
                                                                                color="var(--danger)"
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : annualTaxData?.status === 'pending' ? (
                                                        <>
                                                            <DateWrapper isDarkTheme={isDarkTheme}>
                                                                <Input
                                                                    type={InputComponentType.Date}
                                                                    date={null}
                                                                    onChange={(value: Date) => {
                                                                        updateEcotaxPayment(value, annualTaxData);
                                                                    }}
                                                                    inputClassName="payment-tax-date"
                                                                    placeholder={t('pages.transfers.enter_date').text}
                                                                />
                                                            </DateWrapper>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                ) : (
                                    <NoItems>
                                        <div>
                                            <img src={NoItemsPhoto} alt="photo" />
                                            <div className="description">
                                                <h4>{t('pages.transfers.payment_lists.noData_1').text}</h4>
                                                <h4
                                                    onClick={() => {
                                                        navigate(`/transfer-orders/${paramsYear}`);
                                                    }}
                                                    className="link"
                                                >
                                                    {t('pages.eco_tax_transfer.noData_link').text}
                                                </h4>
                                            </div>
                                        </div>
                                    </NoItems>
                                )}
                            </TableContainer>
                        ) : (
                            <TableContainer>
                                {quarterlyTaxData && quarterlyTaxData.length > 0 ? (
                                    <Table isDarkTheme={isDarkTheme}>
                                        <thead>
                                            <tr>
                                                <th>{t('pages.eco_tax_transfer.quarter').text}</th>
                                                <th>{t('navbar.pausal.ecotax').text} (RSD)</th>
                                                <th>{t('pages.transfers.payment_lists.paid').text}</th>
                                                <th>{t('pages.transfers.payment_lists.date').text}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quarterlyTaxData.map((taxData: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="gray-style">{taxData.quarter}</td>
                                                    <td className="gray-style">
                                                        {formatTwoDecimals(taxData.total_amount.toString())}
                                                    </td>
                                                    <td>
                                                        {taxData.status === 'paid' ? (
                                                            <div className="icon-container-paid">
                                                                <FontAwesomeIcon
                                                                    icon={faCheckCircle}
                                                                    className="check-icon"
                                                                />
                                                            </div>
                                                        ) : taxData.status === 'pending' ? (
                                                            <div className="icon-container-unpaid">
                                                                <FontAwesomeIcon
                                                                    icon={faExclamationCircle}
                                                                    className="check-icon"
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        {taxData.status === 'paid' ? (
                                                            <div className="date-div">
                                                                {formatDate(taxData.paid_date) || ''}
                                                                <div
                                                                    onClick={() => {
                                                                        removeQuarterPayment(taxData);
                                                                    }}
                                                                    className="checkbox"
                                                                >
                                                                    <Tooltip
                                                                        text={
                                                                            t('pages.transfers.remove_payment_date')
                                                                                .text
                                                                        }
                                                                        position={`${
                                                                            isTabletAndDown ? 'hardLeft' : 'top'
                                                                        }`}
                                                                        label={
                                                                            <FontAwesomeIcon
                                                                                icon={faTimes}
                                                                                className="checked"
                                                                                color="var(--danger)"
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : taxData.status === 'pending' ? (
                                                            <DateWrapper isDarkTheme={isDarkTheme}>
                                                                <Input
                                                                    type={InputComponentType.Date}
                                                                    date={null}
                                                                    onChange={(value: Date) => {
                                                                        updateQuarterPayment(value, taxData);
                                                                    }}
                                                                    inputClassName="payment-tax-date"
                                                                    placeholder={t('pages.transfers.enter_date').text}
                                                                />
                                                            </DateWrapper>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <NoItems>
                                        <div>
                                            <img src={NoItemsPhoto} alt="photo" />
                                            <div className="description">
                                                <h4>{t('pages.transfers.payment_lists.noData_1').text}</h4>
                                                <h4
                                                    onClick={() => {
                                                        navigate(`/transfer-orders/${params.year}`);
                                                    }}
                                                    className="link"
                                                >
                                                    {t('pages.eco_tax_transfer.noData_link').text}
                                                </h4>
                                            </div>
                                        </div>
                                    </NoItems>
                                )}
                            </TableContainer>
                        )}
                    </>
                ) : (
                    <NoItems>
                        <div>
                            <img src={NoItemsPhoto} alt="photo" />
                            <div className="description">
                                <h4>{t('pages.transfers.payment_lists.noData_1').text}</h4>
                                <h4
                                    onClick={() => {
                                        navigate(`/ecotax/${paramsYear}`);
                                    }}
                                    className="link"
                                >
                                    {t('pages.eco_tax_transfer.noData_link').text}
                                </h4>
                            </div>
                        </div>
                    </NoItems>
                )}

                <Table style={{ display: 'none' }} id="downloadAnnual" isDarkTheme={isDarkTheme}>
                    {annualTaxData && annualTaxData.total_amount ? (
                        <tbody>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.paid_sum').text} (RSD)</th>
                                <th>{paidSum ? formatTwoDecimals(paidSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.unpaid_sum').text} (RSD)</th>
                                <th>{pendingSum ? formatTwoDecimals(pendingSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>Godina</th>
                                <th>Eko taksa (RSD)</th>
                                <th>Placeno</th>
                                <th>Datum placanja</th>
                            </tr>
                            <tr>
                                <td>{annualTaxData.year}</td>
                                <td>{formatTwoDecimals(annualTaxData.total_amount.toString())}</td>
                                <td>
                                    {annualTaxData.status === 'paid' ? (
                                        <div className="icon-container-paid">DA</div>
                                    ) : (
                                        <div className="icon-container-unpaid">NE</div>
                                    )}
                                </td>
                                <td>
                                    {annualTaxData.status === 'paid' ? (
                                        <div>{formatDate(annualTaxData.paid_date)}</div>
                                    ) : (
                                        <div>/</div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    ) : null}
                </Table>
                <Table style={{ display: 'none' }} id="downloadQuarterly" isDarkTheme={isDarkTheme}>
                    {quarterlyTaxData && quarterlyTaxData.length > 0 ? (
                        <tbody>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.paid_sum').text} (RSD)</th>
                                <th>{paidSum ? formatTwoDecimals(paidSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>{t('pages.transfers.payment_lists.unpaid_sum').text} (RSD)</th>
                                <th>{pendingSum ? formatTwoDecimals(pendingSum) : ''}</th>
                            </tr>
                            <tr>
                                <th>Kvartal</th>
                                <th>Eko taksa (RSD)</th>
                                <th>Placeno</th>
                                <th>Datum placanja</th>
                            </tr>
                            {quarterlyTaxData.map((taxData: any, index: number) => (
                                <tr key={index}>
                                    <td>{taxData.quarter}</td>
                                    <td>{formatTwoDecimals(taxData.total_amount.toString())}</td>
                                    <td>
                                        {taxData.status === 'paid' ? (
                                            <div className="icon-container-paid">DA</div>
                                        ) : (
                                            <div className="icon-container-unpaid">NE</div>
                                        )}
                                    </td>
                                    <td>
                                        {taxData.status === 'paid' ? (
                                            <div>{formatDate(taxData.paid_date)}</div>
                                        ) : (
                                            <div>/</div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </Table>
                <ButtonContainer>
                    <Button
                        variant={ButtonVariant.solid}
                        color={'var(--purple)'}
                        icon
                        onClick={() => {
                            if (annualShow) {
                                exportToExcel('downloadAnnual', 'eco-tax-annual', 'eco-tax.xlsx');
                            } else {
                                exportToExcel('downloadQuarterly', 'eco-tax-quarterly', 'eco-tax.xlsx');
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                        {t('pages.transfers.payment_lists.export_to_xlsx').text}
                    </Button>
                </ButtonContainer>
            </Container>
        </>
    );
};
export default PaymentList;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
    .icon-container-paid {
        bottom: 5px;
        color: var(--green);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .icon-container-unpaid {
        bottom: 5px;
        color: red;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check-icon {
        font-size: 18px;
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
    .select-status {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        border-radius: 6px;
    }
`;

const TableContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
`;
const Filters = styled.div<DarkThemeProps>`
    width: 100%;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'var(--white)')};
    font-size: 14px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        padding: 0 10px 0 0;
    }
    .filter-single {
        display: flex;
        align-items: center;
        width: 200px;
    }
    .amount-rest {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
    }
    .third-title {
        font-size: 14px;
        line-height: 34px;
        padding: 0 10px 0 0;
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--white)')};
        border-radius: 5px;
        margin: 2px;
        padding: 5px;
    }
`;

const Table = styled.table<DarkThemeProps>`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    th,
    td {
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--border-color)')};
        text-align: left;
        background-color: var(--white);
        font-weight: normal;
    }

    tbody td {
        padding: 0;
    }

    th {
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'var(--white)')};
    }

    tr:nth-child(even) {
        background-color: var(--light-gray);
    }
    .date-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkbox {
            cursor: pointer;
        }
    }
    .gray-style {
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'rgb(146, 165, 172)')};
        font-size: 15px;
        line-height: 20px;
        min-height: 5.4em;
        margin-right: 0.83em;
        -webkit-box-flex: 1;
        flex: 1 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }
    .link {
        color: var(--purple);
        cursor: pointer;
        font-size: 15px;
    }

    @media screen and (max-width: 387px) {
        thead tr th {
            padding: 5px;
            overflow: hidden;
            word-wrap: break-word;
        }
        tbody td {
            padding: 5px;
        }

        .date-div {
            word-wrap: break-word;
            font-size: 9px;
        }
        table-layout: fixed;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) {
        thead tr th {
            padding: 5px;
        }
        tbody td {
            padding: 5px;
        }

        .date-div {
            font-size: 12px;
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
        thead tr th {
            padding: 10px;
        }
        tbody td {
            padding: 10px;
        }

        .date-div {
            font-size: 15px;
        }
    }
`;
const ButtonContainer = styled.div`
    padding-bottom: 250px;
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
    }
`;
const ToggleRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
    p {
        color: var(--gray);
        font-size: 15px;
    }
    .left {
        margin-right: 10px;
    }
    .right {
        margin-left: 10px;
    }
`;
const DateWrapper = styled.div<DarkThemeProps>`
    margin-left: auto;
    display: flex;
    align-items: center;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
    input::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92A5AC')};
    }
    .text {
        margin-right: 10px;
        margin-top: 10px;
    }
    .checkbox {
        margin-left: 10px;
        cursor: pointer;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 18px;
            color: var(--white);
        }
        .check-icon.checked {
            background-color: var(--purple);
        }
    }
`;
const NoItems = styled.div`
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    > div {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img {
        width: 130px;
    }
    h4 {
        margin: 5px 0;
        text-align: center;
        line-height: 1.4;
    }
    .description {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .link {
        color: var(--purple);
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        h4 {
            margin: 10px 0;
        }
    }
`;
