import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import CityPhoto from '../../assets/city.png';
import Modal from '../../components/modal';
import SelectComponent from '../../components/select';
import Success from '../../components/successModal';
import styled from 'styled-components';
import useChangeModalStore from '../../store/changeModal';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import useWindowSize from '../../hooks/useResize';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';

interface Props {
    saveChanges?: Function;
}

const MyAgency: FunctionComponent<Props> = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const navigation = useMemo(
        () => [
            { label: t('pages.agency.navbar.profile').text, link: '/agency/profile', value: '/agency/profile' },
            { label: t('pages.agency.navbar.personal').text, link: '/agency/user', value: '/agency/user' },
            { label: t('pages.agency.navbar.tax').text, link: '/agency/tax-solution', value: '/agency/tax-solution' },
            {
                label: t('pages.agency.navbar.authorizationTransfer').text,
                link: '/agency/authorization-transfer',
                value: '/agency/authorization-transfer',
            },
            {
                label: t('pages.agency.navbar.bankAccounts').text,
                link: '/agency/bank-accounts',
                value: '/agency/bank-accounts',
            },
            // {
            //     label: t('pages.agency.navbar.paymentInstr').text,
            //     link: '/agency/payment-instructions',
            //     value: '/agency/payment-instructions',
            // },
            { label: t('pages.agency.navbar.sendInfo').text, link: '/agency/send-data', value: '/agency/send-data' },
            { label: t('pages.agency.navbar.addLogo').text, link: '/agency/add-logo', value: '/agency/add-logo' },
            { label: t('pages.agency.navbar.documents').text, link: '/agency/documents', value: '/agency/documents' },
            {
                label: t('pages.agency.invoiceTemplate.invoice_template').text,
                link: '/agency/invoice-template',
                value: '/agency/invoice-template',
            },
        ],
        [t],
    );

    const [selected, setSelected] = useState(navigation.findIndex((nav) => pathname.includes(nav.link)) || 0);
    const [changeVisible, setChangeVisible] = useState(useChangeModalStore.getState().changeModalVisible);
    const size = useWindowSize();
    const { isMobile } = useResponsive();
    const [successfullySaved, setSuccessfullySaved] = useState(false);

    useEffect(() => {
        setChangeVisible(useChangeModalStore.getState().changeModalVisible);
    }, [size.width, selected]);

    return (
        <Container>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <HeaderContainer>
                <Header />
            </HeaderContainer>

            <h2>{navigation[navigation.findIndex((nav) => pathname.includes(nav.link))].label}</h2>
            <Inner>
                {!isMobile && (
                    <Navigation>
                        {navigation?.map((i, index) => (
                            <Link
                                key={index}
                                onClick={() => {
                                    if (!changeVisible) setSelected(index);
                                }}
                                className={
                                    index === navigation.findIndex((nav) => pathname.includes(nav.link))
                                        ? 'selected'
                                        : ''
                                }
                                to={i.link}
                            >
                                <div>{i.label?.toUpperCase()}</div>
                            </Link>
                        ))}
                    </Navigation>
                )}

                {isMobile && (
                    <SelectContainer>
                        <SelectComponent
                            handleSelectedValue={(v: string) => {
                                if (!changeVisible) {
                                    const ind = navigation.findIndex((x) => x.link === v);
                                    navigate(v);
                                    setSelected(ind);
                                }
                            }}
                            optionList={navigation}
                            width="100%"
                            defaultSelectedOption={navigation[selected]}
                            isSearchable={false}
                        />
                    </SelectContainer>
                )}

                <div className="outletWrapper">
                    <Outlet />
                </div>
            </Inner>
        </Container>
    );
};
export default MyAgency;

const Container = styled.div`
    background: var(--white);
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        background: var(--body-gray);
    }
    .select__control {
        background: var(--light-gray);
    }
    h2 {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 20px;
        font-size: 32px;
        width: 100%;
    }
`;

const HeaderContainer = styled.div`
    background: var(--body-gray);

    @media only screen and (max-width: ${breakpoints.laptop - 1}px) {
        margin-top: 100px;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        margin-top: 0px;
    }
`;

const Header = styled.div`
    height: 160px;
    background: url(${CityPhoto}) bottom no-repeat;
    background-size: 100% auto;
    border-bottom: 2px solid var(--border-color);
    position: relative;
    text-align: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: 60px;
    }
`;

const Inner = styled.div`
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 50px 50px;
    clear: both;
    display: flex;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-wrap: wrap;
    }

    //Media Queries
    @media only screen and (max-width: ${breakpoints.laptop + 225}px) {
        padding: 20px 10px 50px 40px;
    }
    @media only screen and (max-width: ${breakpoints.laptop - 1}px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 5% 0px;
    }
    .outletWrapper {
        flex: 4;
        max-width: 100%;
    }
`;

const Navigation = styled.div`
    height: 100%;
    color: var(--black);
    padding-right: 60px;
    flex: 1;
    a {
        font-size: 14px;
        display: block;
        padding: 15px;
        color: var(--black);
        &.selected {
            border-left: 3px solid var(--purple);
            background: var(--body-gray);
        }
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptop + 225}px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

const SelectContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    > div {
        background: var(--white);
    }
    text-transform: uppercase;
`;
