import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ButtonsBottom } from '../../style';
import Button, { ButtonVariant } from '../../../../../components/button';
import { ObjectKeys } from '../../../../../types/objectKeys';
import colors from '../../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faPlus, faPrint, faSave, faTrashCan, faA } from '@fortawesome/free-solid-svg-icons';
import InstructionsTooltip from '../../../../../components/instructionsTooltip';
import { useTipsQuery } from '../../../../../react-query/useTipsQuery';
import useResponsive from '../../../../../hooks/responsive/useResponsive';

interface Props {
    t: Function;
    setPreviewModal: Function;
    setSendModal: Function;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
    handleCreateAdvanceFromProforma?: Function;
    name?: string;
    invoice?: ObjectKeys;
}

const WebFooter: FunctionComponent<Props> = ({
    t,
    setPreviewModal,
    setSendModal,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
    handleCreateAdvanceFromProforma,
    name,
    invoice,
}) => {
    const role = localStorage.getItem('role');

    const createInvoice32 = useRef(null);

    const { tips, setNextTipActive, skipGroup } = useTipsQuery({ page: 1, per_page: 5 }, 1);

    const refs = [{ nameRef: 'createInvoice32', ref: createInvoice32 }];

    const [tooltipTargetRef, setTooltipTargetRef] = useState<React.RefObject<HTMLElement> | null>(null);

    const { isMobile: isMobilePhone } = useResponsive();

    useEffect(() => {
        if (tips && !isMobilePhone) {
            const activeTip = tips?.tips?.data?.find((tip: any) => tip.is_active);

            if (activeTip) {
                const orderStr = activeTip.order.toString();

                const matchingRef = refs.find(({ nameRef }) => {
                    const orderInNameRef = nameRef.replace('createInvoice', '');
                    return orderInNameRef === orderStr;
                });

                if (matchingRef) {
                    setTooltipTargetRef(matchingRef.ref);
                } else {
                    setTooltipTargetRef(null);
                }
            }
        }
    }, [tips]);

    return (
        <>
            <ButtonsBottom>
                {tooltipTargetRef?.current && !isMobilePhone && (
                    <InstructionsTooltip targetRef={tooltipTargetRef} position="top" />
                )}
                {role !== 'admin' ? (
                    <>
                        <Button
                            onClick={() => handleDownloadInvoice()}
                            variant={ButtonVariant.outlined}
                            color={colors.purple}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                            <span ref={createInvoice32}>{t('pages.editInvoice.print').text}</span>
                        </Button>
                        <Button
                            onClick={() => setSendModal(true)}
                            variant={ButtonVariant.solid}
                            color={colors.blue}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                            {t('pages.editInvoice.send').text}
                        </Button>
                        <Button
                            onClick={() => handleUpdateInvoice()}
                            variant={ButtonVariant.solid}
                            color={colors.green}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                            {t('pages.editInvoice.save').text}
                        </Button>
                        {name === 'proforma' ? (
                            <>
                                <Button
                                    onClick={() => handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()}
                                    variant={ButtonVariant.solid}
                                    icon={true}
                                    color={colors.green}
                                    size={'auto'}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                    {t('pages.invoiceCard.dropdownItems.invoice').text}
                                </Button>
                                <Button
                                    onClick={() => handleCreateAdvanceFromProforma && handleCreateAdvanceFromProforma()}
                                    variant={ButtonVariant.solid}
                                    icon={true}
                                    color={colors.brown}
                                    size={'auto'}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.white} icon={faA} />
                                    {t('pages.advanceInvoices.single').text}
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {role !== 'admin' ? (
                    <>
                        <Button
                            variant={ButtonVariant.outlined}
                            color={colors.purple}
                            icon={true}
                            size={'auto'}
                            onClick={() => setPreviewModal(true)}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                            {t('pages.editInvoice.preview').text}
                        </Button>
                        {invoice?.id ? (
                            <Button
                                variant={ButtonVariant.outlined}
                                color={colors.danger}
                                icon={true}
                                size={'auto'}
                                onClick={() => setDeleteInvoiceModal(true)}
                            >
                                <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                {t('pages.editInvoice.delete').text}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {invoice?.id && role === 'admin' ? (
                    <>
                        <Button
                            onClick={() => handleUpdateInvoice()}
                            variant={ButtonVariant.solid}
                            color={colors.green}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                            {t('pages.editInvoice.save').text}
                        </Button>
                        <Button
                            variant={ButtonVariant.outlined}
                            color={colors.danger}
                            icon={true}
                            size={'auto'}
                            onClick={() => setDeleteInvoiceModal(true)}
                        >
                            <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                            {t('pages.editInvoice.delete').text}
                        </Button>
                    </>
                ) : (
                    <></>
                )}
            </ButtonsBottom>
        </>
    );
};

export default WebFooter;
