import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import { RegisterData, RegisterDataWithPromoCode } from '../types/communication/register';
import getCurrentLanguage from '../functions/getCurrentLanguage';
export default class Auth {
    public login(options: { email: string; password: string }): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.login,
            data: options,
        });
    }
    public logout(): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.logout,
        });
    }
    public getActivityCodes(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.activityCodes,
        });
    }
    public getMunicipalities(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.municipalities,
        });
    }
    public register(options: RegisterData | RegisterDataWithPromoCode): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.register,
            data: options,
        });
    }
    public getAgencyDataBasedOnVatNo(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.agencyDataBasedOnVatNum(id),
        });
    }
    public sendActivationLink(options: { email: string }): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.activationLink,
            data: options,
        });
    }
    public confirmEmailToken(token: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.auth.confirmEmail,
            data: { token: token },
        });
    }
    public getRefreshToken(options: { refresh_token: string }): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.auth.refreshToken,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
}
