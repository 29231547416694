import { useNotificationsQuery } from '../../react-query/useNotificationsQuery';
import useOutsideClick from '../../hooks/useOutsideClick';
import { faArrowTurnUp, faBell, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import { useMarkAsRead } from '../../pages/notifications/useMarkAsRead';
import DangerouslySetInnerHTML from '../common/DangerouslySetInnerHtml';
import colors from '../../global/colors';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
interface Props {
    closeDropdownMenu?: () => void;
}

export const NotificationsController = ({ closeDropdownMenu }: Props) => {
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const { currentLang } = useLanguageStore();

    const t = useTranslations(currentLang);

    const params = {
        page: 1,
        limit: 10,
        search: '',
        searchFields: '',
        searchJoin: 'and',
        orderBy: 'sent_at',
        sortedBy: 'desc',
    };

    const { notifications } = useNotificationsQuery(params);

    const unreadNotificationsCount = notifications?.unreadNotificationsCount || 0;

    const ref = useRef<HTMLDivElement>(null);

    useOutsideClick(ref, setShowNotifications);

    const { markAsRead, markAllAsRead } = useMarkAsRead(params);

    const toggleNotifications = (): void => {
        setShowNotifications((prev) => !prev);
        closeDropdownMenu && closeDropdownMenu();
    };

    const handleMarkAllAsRead = (): void => {
        markAllAsRead.mutate();
    };

    const lang = localStorage.language
        ? localStorage.language === 'English'
            ? 'en'
            : localStorage.language === 'Русский'
            ? 'ru'
            : 'rs'
        : 'rs';

    return (
        <NotificationsWrapper ref={ref}>
            <button className="bell-button" onClick={toggleNotifications}>
                <FontAwesomeIcon
                    className={`bell-icon ${unreadNotificationsCount && 'has-unread-notifications'}`}
                    icon={faBell}
                />
                {!!unreadNotificationsCount && (
                    <span className="notification-count">
                        {unreadNotificationsCount > 9 ? (
                            <>
                                <span className="count">9</span>
                                <span className="plus-sign">+</span>
                            </>
                        ) : (
                            unreadNotificationsCount
                        )}
                    </span>
                )}
            </button>
            {showNotifications && (
                <div className="notifications-dropdown">
                    <Caret>
                        <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.purple} icon={faCaretDown} />
                    </Caret>
                    {notifications?.data.length !== 0 && notifications?.unreadNotificationsCount !== 0 ? (
                        <button className="mark-all-as-read-button" onClick={handleMarkAllAsRead}>
                            {t('pages.notifications.marked_as_read').text}
                        </button>
                    ) : null}

                    {notifications?.data.length !== 0 ? (
                        <>
                            <ul className="notifications-list pausal-scroll">
                                {notifications?.data?.slice(0, 4).map((notification, index) => (
                                    <li
                                        key={'notification-' + index}
                                        onClick={() => {
                                            if (!notification.alert_users.data[0].is_read) {
                                                markAsRead.mutate([notification.id]);
                                            }
                                        }}
                                    >
                                        <Link
                                            className={`notification-item ${
                                                !notification.alert_users.data[0].is_read && 'is-unread'
                                            }`}
                                            to={`/notifications?id=${notification.id}`}
                                            onClick={toggleNotifications}
                                        >
                                            {/* <FontAwesomeIcon
                                                icon={faArrowTurnUp}
                                                className="arrow-icon"
                                                fontSize={'1.2rem'}
                                            /> */}
                                            <div>
                                                <p className="title">{notification[`title_of_alert_${lang}`]}</p>
                                                <DangerouslySetInnerHTML
                                                    className="description"
                                                    htmlContent={notification[`description_${lang}`]}
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <Link to={'/notifications'}>
                                <button className="view-all-button" onClick={toggleNotifications}>
                                    {t('pages.notifications.look_all_notf').text}
                                </button>
                            </Link>
                        </>
                    ) : (
                        <button className="no-notifications">{t('pages.notifications.noNotf').text}</button>
                    )}
                </div>
            )}
        </NotificationsWrapper>
    );
};

const NotificationsWrapper = styled.div`
    .bell-button {
        all: unset;
        cursor: pointer;
        position: relative;
        color: var(--danger);

        .bell-icon {
            color: var(--purple);
            height: 0.8em;
            &.has-unread-notifications {
                color: var(--toastify-color-error);
            }
        }
        .notification-count {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            margin-top: -2px;
            @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
                margin-top: 0;
            }
            .count {
                font-size: 11px;
                @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
                    font-size: 9px;
                }
            }
            .plus-sign {
                font-size: 11px;
                @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
                    font-size: 9px;
                }
            }
        }
    }
    .notifications-dropdown {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 90px;
        max-height: 460px;
        padding: 0.5rem 0;
        background-color: var(--white);
        border: 1px solid var(--purple);
        z-index: 200;
        box-sizing: border-box;
        border-radius: 4px;

        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            right: 117px;
        }

        @media only screen and (max-width: ${breakpoints.tablet - 1}px) {
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
            top: 3.6rem;
            border-radius: 0;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            top: calc(100% - 2px);
        }
        .mark-all-as-read-button {
            all: unset;
            cursor: pointer;
            font-size: 13px;
            text-align: right;
            margin-bottom: 1.5rem;
            margin-left: auto;
            padding: 0 10px;
            display: block;
            color: var(--gray);
            &:hover {
                opacity: 0.7;
            }
        }
        .notifications-list {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            overflow-y: scroll;
            max-height: 335px;
            li {
                list-style: none;
                padding: 0 10px;
                .notification-item {
                    cursor: pointer;
                    display: flex;
                    gap: 1rem;
                    padding: 0.5rem 0;
                    box-sizing: border-box;
                    border-bottom: 1px solid var(--border-color);
                    box-shadow: inset 0px 0px 0px 10px var(--white);
                    &:hover {
                        opacity: 0.7;
                    }
                    &.is-unread {
                        .title {
                            color: var(--purple);
                            font-weight: bold;
                        }
                    }
                    .arrow-icon {
                        transform: rotate(90deg);
                        color: var(--purple);
                    }
                    .title {
                        color: var(--gray);
                        margin-bottom: 0.5rem;
                        font-size: 13px;
                    }
                    .description {
                        * {
                            font-size: 12px;
                            line-height: 1.1rem;
                        }
                        color: var(--gray);
                        font-style: italic;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
        .view-all-button {
            all: unset;
            font-size: 13px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            color: var(--gray);
            &:hover {
                opacity: 0.7;
            }
        }
        .no-notifications {
            all: unset;
            font-size: 13px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            color: var(--gray);
            &:hover {
                opacity: 0.7;
            }
        }
    }
`;

export const Caret = styled.span`
    position: absolute;
    top: 0;
    right: 2px;
    transform: translateY(calc(-100% + 8px)) rotate(180deg);
`;
