import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';

interface Props {
    close: Function;
    active?: boolean;
    className?: string;
    children?: string | any;
}
interface SidebarProps {
    active?: boolean;
    className?: string;
    children?: string | any;
}

const Sidebar: FunctionComponent<Props> = ({ close, active, children, className }) => {
    const [act, setActive] = useState(active);

    useEffect(() => {
        setActive(active);
    }, [active]);

    return (
        <Container className={`${act ? 'sidebar active' : 'sidebar'} ${className ? className : ''}`.trim()}>
            <div className="modal-backdrop" onClick={() => close()} />

            <StyledSidebar className={`${act ? 'active' : ''} sidebar-content`.trim()}>{children}</StyledSidebar>
        </Container>
    );
};
const Container = styled.div`
    z-index: 9999;
    position: fixed;
    visibility: visible;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .active {
        transform: translate3d(0, 0, 0);
    }
    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
`;
const StyledSidebar = styled.div<SidebarProps>`
    position: fixed;
    z-index: 1;
    width: 460px;
    max-width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    /* -webkit-transform: translateX(0); */
    /* transform: translateX(0); */
    left: auto;
    background: var(--white);
    padding: 17px 30px 50px;
    overflow: auto;

    /* instrukcije promena */
    /* transform: translateX(0); */
    animation: none;
    @keyframes slide-right {
        0% {
            opacity: 0;
            transform: translateX(460px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .sidebar-inner-content {
        padding: 24px;
        box-sizing: border-box;
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
        height: 111vh;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding-bottom: 184px;

        border-top: 1px solid rgba(229, 229, 229, 0.32);
        position: fixed;
        right: 0;
    }
`;
export default Sidebar;
