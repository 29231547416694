import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useProfileStore from '../../../store/profile';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import AccountExpired from '../../../components/accountExpired';
import Filters from './filters';
import CancelModal from '../common/cancelModal';
import EAdvanceModal from '../common/eInvoiceModal';
import DeleteModal from '../common/deleteModal';
import SendDocumentModal from '../common/sendModal';
import ErrorModal from '../../../components/errorModal';
import NoEInvoiceApiModal from '../common/noEinvoiceApiModal';
import Success from '../../../components/successModal';
import useInvoicesStore from '../../../store/invoices';
import useAgencyStore from '../../../store/agency';
import useParamsStore from '../../../store/params';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import FilterTrack from '../common/filterTrack';
import useInvoiceParamsStore from '../../../store/invoiceParams';
import moment from 'moment';
import countTotal from '../common/countTotal';
import getAllAInvoices from './getAllAInvoices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '../../../components/checkbox';
import HandleInvoicesModal from '../common/handleInvoicesModal';
import useTheme from '../../../hooks/useTheme';

const AdvanceInvoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { profile } = useProfileStore();
    const { advanceInvoiceData, desiredCardView } = useInvoicesStore();
    const { agency } = useAgencyStore();
    const role = localStorage.getItem('role');

    const token = getAccessToken();
    const isAccountActive = role === 'admin' ? true : profile?.active === 1 ? true : false;

    const [searchValue, setSearchValue] = useState('');
    const [successEadvance, setSuccessEadvance] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEAvanceModal, setOpenEAvanceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);

    const [invoiceToCancel, setInvoiceToCancel] = useState<ObjectKeys>({});
    const [invoiceToEinvoice, setInvoiceToEinvoice] = useState<ObjectKeys>({});
    const [invoiceToSend, setInvoiceToSend] = useState<ObjectKeys>({});

    const [clientEmails, setClientEmails] = useState<Array<string>>([]);
    const [display, setDisplay] = useState(desiredCardView);
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [success, setSuccess] = useState(false);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [selectedInvoicesArray, setSelectedInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false);

    const isDarkTheme = useTheme();

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
        orderBy: '',
        sortedBy: '',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    useEffect(() => {
        useParamsStore.setState({ a_page: params.page });
        getInvoices();
    }, [formattedParams, params.limit, params.page, params.orderBy, params.sortedBy]);

    useEffect(
        () => () => {
            if (params.page > 1) {
                useInvoicesStore.setState({ advanceInvoiceData: [] });
                setParams({ ...params, page: 1 });
                useParamsStore.setState({ a_page: 1 });
            }
        },
        [],
    );

    useEffect(() => {
        useInvoicesStore.setState({ desiredCardView: display });
    }, [display]);

    const getInvoices = (): Promise<boolean> => {
        setShowSkeleton(true);
        return new Promise<boolean>((resolve) => {
            const finalParams = {
                ...params,
                page: params.page ? params.page : 1,
                search: 'is_prepaid:1;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            };
            useInvoiceParamsStore.setState({ advances_params: finalParams });
            setIsAllChecked(false);
            setSelectedInvoicesArray([]);
            communication
                .getInvoices(finalParams)
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        if (res.data?.data?.length > 0) {
                            setHasInvoices(true);
                        }
                        setShowSkeleton(false);
                        useInvoicesStore.setState({ advanceInvoiceData: res.data });
                        resolve(true);
                    }
                })
                .catch(() => {
                    setShowSkeleton(false);
                    useInvoicesStore.setState({ advanceInvoiceData: [] });
                    resolve(false);
                });
        });
    };

    //Advance Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/advance-invoices/edit/${invoiceId}`);
    };

    //Advance Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        navigate(`/advance-invoices/copy/${invoiceId}`);
    };
    //Refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);

        try {
            const res = await communication.refreshEInvoice({ id: eInvoiceId });

            if (res.status === 200) {
                setLoaderVisible(false);
                getInvoices().then((resp: boolean) => {
                    if (resp) {
                        setSuccess(true);
                    }
                });
            } else {
                console.error('Unexpected response status code:', res.status);
                setLoaderVisible(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    //AInvoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={invoice}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => {
                    setHasInvoices(false);
                    getInvoices();
                }}
            />,
        );
    };

    //AInvoice Cancel Modal
    const handleCancelModal = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
        setOpenCancelModal(true);
    };

    //AInvoice Download
    const handleDownloadInvoice = (invoiceId: string, invoice: ObjectKeys): void => {
        communication.downloadInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD');
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEAvanceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEAdvanceModal = async (invoice: ObjectKeys): Promise<void> => {
        setInvoiceToEinvoice(invoice);
        if (agency.e_invoice_api_key) handleOpenEInvoiceModal(invoice.id);
        else setNoEInvoiceApiModal(true);
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
    };

    const handleSendModal = (invoiceId: string, invoiceClientEmail: Array<string>): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmails(invoiceClientEmail);
    };

    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new advance invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/advance-invoices/add/${invoiceType}`);
    };

    const update = async (): Promise<any> => {
        setLoaderVisible(true);
        try {
            const updateInvoice = {
                ...invoiceToSend,
                auto_increment: true,
                cancel_number: false,
                cancelled: false,
                custom_number: false,
                custom_text: false,
                invoice_date: moment(invoiceToSend.invoice_date).format('YYYY-MM-DD').toString(),
                invoice_number: invoiceToSend.real_invoice_number,
                invoice_per_owner: true,
                is_custom: false,
                is_prepaid: true,
                paid_date: moment(invoiceToSend.paid_date).format('YYYY-MM-DD').toString(),
                prefix: 'A',
                rate: Number(invoiceToSend.rate),
                sef_invoice_number: invoiceToSend.sef_invoice_number ? invoiceToSend.sef_invoice_number : null,
                services: invoiceToSend.services.map((service: ObjectKeys, i: number) => ({
                    ...service,
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: Number(service.pivot.quantity.replace(',', '.')),
                    price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                    pivot: {
                        discount: 0,
                        index: i, // Add index to pivot
                        measurement_unit: service.pivot.measurement_unit,
                        price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                        quantity: service.pivot.quantity.replace(',', '.'),
                        total_price: service.pivot.total_price, // Ensure total_price is included
                    },
                })),
                memorandums: invoiceToSend.memorandums?.data?.map((memo: ObjectKeys) => memo.id) || [],
                trading_date: moment(invoiceToSend.trading_date).format('YYYY-MM-DD').toString(),
                value: Number(countTotal(invoiceToSend.services).replace(',', '.')),
                value_in_rsd: Number(countTotal(invoiceToSend.services).replace(',', '.')),
            };
            const res = await communication.editInvoice(invoiceToSend.id, updateInvoice);

            if (res) {
                navigate('/advance-invoices');
                setLoaderVisible(false);
                return res?.data?.data;
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    const handleAttachedMemos = (invoice: ObjectKeys): void => {
        setLoaderVisible(true);
        const updateInvoice = {
            ...invoice,
            invoice_number: invoice.real_invoice_number,
            services: invoice.services.map((service: ObjectKeys, i: number) => ({
                ...service,
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                discount: service.pivot.discount,
                pivot: {
                    discount: service.pivot.discount,
                    index: i, // Add index to pivot
                    measurement_unit: service.pivot.measurement_unit,
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    quantity: service.pivot.quantity.replace(',', '.'),
                    total_price: service.pivot.total_price, // Ensure total_price is included
                },
            })),
        };
        communication
            .editInvoice(invoice.id, updateInvoice)
            .then((res: ObjectKeys) => {
                if (res) {
                    setInvoiceToSend(res.data.data);
                    getAllAInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const handleSelectedCard = (id: string): void => {
        const isInvoiceInArray = selectedInvoicesArray.find((selectedInvoice) => selectedInvoice === id);
        if (isInvoiceInArray) {
            const filteredInvoiceList = selectedInvoicesArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedInvoicesArray(filteredInvoiceList);
        } else {
            setSelectedInvoicesArray((prevState) => [...prevState, id]);
        }
    };

    const handleSelectAllInvoices = (isChecked: boolean): void => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newInvoiceIds = advanceInvoiceData?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedInvoicesArray(newInvoiceIds);
        } else {
            setSelectedInvoicesArray([]);
        }
    };

    const handleDeleteInvoices = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
    };

    // const handleDownloadInvoices = (): void => {
    //     getSelectedInvoicesNames();
    // };

    const getSelectedInvoicesNames = (): string[] => {
        const invoiceNamesArray: string[] = [];
        selectedInvoicesArray.map((separateItem) => {
            const foundObject = advanceInvoiceData?.data?.find((obj: any) => obj.id === separateItem);

            invoiceNamesArray.push(`${foundObject.prefix ? foundObject.prefix : ''} ${foundObject.invoice_number}`);
        });
        return invoiceNamesArray;
    };

    const handleModalSubmission = (): void => {
        setLoaderVisible(true);
        setIsInvoicesModalOpen(false);
        communication
            .deleteSelectedInvoices('delete', selectedInvoicesArray)
            .then((res: ObjectKeys) => {
                if (res.status === 204) {
                    setLoaderVisible(false);
                    toast.success(t('pages.invoices.handleInvoicesModal.invoiceDeleteSuccess').text);
                    getInvoices();
                }
            })
            .catch((error: any) => {
                console.error('Error', error);
                setLoaderVisible(false);
                setErrorMessage(error?.response?.data?.message);
                if (error?.response?.status === 422) {
                    const realErrorMessage = error?.response?.data?.errors;

                    const firstKey: any = Object.keys(realErrorMessage || {})[0];
                    const message = realErrorMessage?.[firstKey]?.[0] || 'An error occurred';
                    setErrorMessage(message);
                    getInvoices();
                }
                setErrorModal(true);
            });
    };

    return (
        <>
            <ToastContainer />
            <PageWrapper>
                {errorModal && (
                    <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                        <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                    </Modal>
                )}
                {noEInvoiceApiModal && (
                    <Modal
                        modalVisible={true}
                        closeModal={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                    >
                        <NoEInvoiceApiModal
                            saveChanges={() => navigate('/settings/e-invoice')}
                            close={() => {
                                setNoEInvoiceApiModal(false);
                            }}
                            message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                        />
                    </Modal>
                )}
                {sendModal && (
                    <Sidebar close={() => setSendModal(false)}>
                        <SendDocumentModal
                            close={() => setSendModal(false)}
                            setFormData={setSendData}
                            formData={sendData}
                            invoice={invoiceToSend}
                            t={t}
                            id={clickedInvoice}
                            success={() => {
                                setClickedInvoice('');
                                getInvoices();
                            }}
                            loading={(e: boolean) => onLoad(e)}
                            clientEmails={clientEmails}
                            errorMessage={(mess: string) => {
                                setErrorMessage(mess);
                                setErrorModal(true);
                            }}
                            handleUpdateInvoice={invoiceToSend.status === 'open' ? update : undefined}
                            modalVisible={sendModal}
                            editInvoice={(updatedInvoice: ObjectKeys) => {
                                handleAttachedMemos(updatedInvoice);
                            }}
                        />
                    </Sidebar>
                )}
                {openCancelModal ? (
                    <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                        <CancelModal
                            t={t}
                            setOpenCancelModal={setOpenCancelModal}
                            clickedInvoice={invoiceToCancel}
                            onCancelInvoice={getInvoices}
                        />
                    </Modal>
                ) : (
                    <></>
                )}
                {openEAvanceModal ? (
                    <Modal modalVisible={openEAvanceModal} closeModal={() => setOpenEAvanceModal(false)}>
                        <EAdvanceModal
                            t={t}
                            setOpenEInvoiceModal={setOpenEAvanceModal}
                            clickedInvoice={invoiceToEinvoice}
                            onEinvoiceSent={() => {
                                setSuccessEadvance(true);
                                getInvoices();
                            }}
                        />
                    </Modal>
                ) : (
                    <></>
                )}
                {successEadvance && (
                    <Modal modalVisible={true} closeModal={() => setSuccessEadvance(false)}>
                        <Success
                            close={() => setSuccessEadvance(false)}
                            message={t('pages.eInvoices.sentEadvance').text}
                        />
                    </Modal>
                )}
                {success && (
                    <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                        <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                    </Modal>
                )}
                {isInvoicesModalOpen && (
                    <Modal
                        modalVisible={isInvoicesModalOpen}
                        closeModal={() => {
                            setIsInvoicesModalOpen(false);
                        }}
                    >
                        <HandleInvoicesModal
                            t={t}
                            closeGlobalModal={() => {
                                setIsInvoicesModalOpen(false);
                            }}
                            selectedInvoicesInformationArray={getSelectedInvoicesNames()}
                            modalType="delete"
                            invoiceType="advance"
                            isModalConfirmed={handleModalSubmission}
                        />
                    </Modal>
                )}
                {loaderVisible && <Loader />}
                <div className="advance-invoices page">
                    <Header isDarkTheme={isDarkTheme}>
                        <div>
                            <h1>{t('pages.advanceInvoices.title').text}</h1>
                            <AddButtonContainer>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => {
                                        if (isAccountActive) {
                                            setAddInvoiceDropdown(!addInvoiceDropdown);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                    {t('pages.advanceInvoices.newInvoice').text}
                                </Button>
                                {addInvoiceDropdown && (
                                    <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                        <Dropdown
                                            arrowRight="4px"
                                            itemList={[
                                                {
                                                    label: t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text,
                                                    handler: () => handleAddInvoice('domestic'),
                                                },
                                                {
                                                    label: t('pages.advanceInvoices.addInvoiceDropdown.foreign').text,
                                                    handler: () => handleAddInvoice('foreign'),
                                                },
                                            ]}
                                        />
                                    </DropdownContainer>
                                )}
                            </AddButtonContainer>
                        </div>
                    </Header>
                    {isAccountActive ? <></> : <AccountExpired />}
                    <Filters
                        setParams={setParams}
                        params={params}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setDisplay={setDisplay}
                        selectedUserView={display}
                    />
                    <FilterTrack
                        onChevronClick={(name: string, order: string) => {
                            setParams({ ...params, orderBy: name, sortedBy: order });
                        }}
                        t={t}
                    />
                    {advanceInvoiceData?.data?.length > 0 && isAccountActive && (
                        <SelectAllSection>
                            <div className="selectCheckbox">
                                <Checkbox
                                    onChange={(value: any) => {
                                        handleSelectAllInvoices(value);
                                    }}
                                    label={t('pages.agency.extra_services.select_all').text}
                                    defaultChecked={isAllChecked}
                                />
                            </div>
                            {selectedInvoicesArray.length > 0 && (
                                <div className="userControlSection">
                                    <div className="selectionDiv" onClick={handleDeleteInvoices}>
                                        <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                        <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                    </div>
                                    {/* <div className="selectionDiv" onClick={handleDownloadInvoices}>
                                    <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                                </div> */}
                                </div>
                            )}
                        </SelectAllSection>
                    )}
                    {showSkeleton ? (
                        Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                    ) : (
                        <>
                            <InvoicesList
                                className={
                                    advanceInvoiceData?.data?.length === 0 ? 'noItemsClass' : `display-${display}`
                                }
                            >
                                {advanceInvoiceData?.data?.length > 0 ? (
                                    advanceInvoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                        const invoice: ObjectKeys = {
                                            ...invoicePrev,
                                            services: invoicePrev.original_data.services,
                                            client: { data: invoicePrev.original_data.client },
                                        };
                                        // Get client updated emails
                                        const clientMails: Array<string> = (() => {
                                            if (invoicePrev.client?.data) {
                                                const emails =
                                                    invoicePrev.client.data.clientEmails?.data?.map(
                                                        (item: ObjectKeys) => item.email,
                                                    ) || [];
                                                return emails.length > 0
                                                    ? emails
                                                    : invoicePrev.client.data.email
                                                    ? [invoicePrev.client.data.email]
                                                    : [];
                                            }
                                            return [];
                                        })();
                                        return (
                                            <div key={invoice.id} className={`${display}`}>
                                                <InvoiceCard
                                                    type={'advanceInvoice'}
                                                    dropdownDirection={index > 2 ? 'up' : 'down'}
                                                    cardData={invoice}
                                                    cardDisplay={display}
                                                    handleChange={() => handleChange(invoice.id)}
                                                    handleCopy={() => handleCopy(invoice.id)}
                                                    handleDelete={() => handleDeleteModal(invoice)}
                                                    handleCancel={() => handleCancelModal(invoice)}
                                                    handleDownload={() => handleDownloadInvoice(invoice?.id, invoice)}
                                                    handleEInvoice={() => handleEAdvanceModal(invoice)}
                                                    handleSend={() => {
                                                        setInvoiceToSend(invoice);
                                                        handleSendModal(invoice.id, clientMails);
                                                    }}
                                                    handleClick={() => handleClickedInvoice(invoice)}
                                                    eInvoice={invoice.is_einvoice}
                                                    handleRefresh={() => {
                                                        handleRefreshEInvoice(invoice.id);
                                                    }}
                                                    // paymentInfo={() => void 0}
                                                    checkedInvoice={handleSelectedCard}
                                                    cardIdArray={selectedInvoicesArray}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <>
                                        {hasInvoices ? (
                                            <NoItems text={t('pages.invoices.filters.noResults').text} />
                                        ) : (
                                            <NoItems text={t('pages.invoices.noItems.descriptionPrepaid').text} />
                                        )}
                                    </>
                                )}
                            </InvoicesList>
                            {advanceInvoiceData?.data?.length > 0 && (
                                <Pagination
                                    pageCount={advanceInvoiceData.meta?.pagination?.total_pages}
                                    onPageChange={(e: ObjectKeys) => {
                                        setParams({ ...params, page: e.selected + 1 });
                                        useParamsStore.setState({ a_page: e.selected + 1 });
                                    }}
                                    nextLabel={`${
                                        advanceInvoiceData.meta?.pagination?.current_page ===
                                        advanceInvoiceData.meta?.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${
                                        advanceInvoiceData.meta?.pagination?.current_page === 1 ? '' : '<'
                                    }`}
                                    breakLabel="..."
                                    initialPage={advanceInvoiceData.meta?.pagination?.current_page - 1}
                                />
                            )}
                        </>
                    )}
                </div>
            </PageWrapper>
        </>
    );
};
export default AdvanceInvoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
`;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        flex-wrap: wrap;
        gap: 1rem;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;

const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
export const NoData = styled.div`
    flex: 1;
    text-align: center;
    margin: 60px 0;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
    }
    a {
        font-weight: bold;
    }
`;
