import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAllUsersStore from '../../../store/admin/allUsers';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Statistics from './statistics';
import Filters from '../../pausal/invoices/filters';
import DeleteModal from './deleteModal';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import getStatistics from './getStatistics';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightToFile,
    faCircleCheck,
    faCircleQuestion,
    faDownload,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../components/tooltipMain';
import useResponsive from '../../../hooks/responsive/useResponsive';
import colors from '../../../global/colors';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';
import ResponseModal, { SuccessObject, ErrorObject } from '../../pausal/common/responseModal';
import Checkbox from '../../../components/checkbox';
import HandleInvoicesModal from '../../../pages/pausal/common/handleInvoicesModal';
import ConfirmModal from '../../../components/confirmModal';

const Invoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const token = getAccessToken();
    const { allUsers } = useAllUsersStore();
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [invoices, setInvoices] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState<ObjectKeys>({});
    const [statistics, setStatistics] = useState<ObjectKeys>({});

    const tooltipUpload = t('pages.invoices.upload_tooltip').text;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);

    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [uploadErrorMessage, setUploadErrorMessage] = useState<string | ErrorObject[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | SuccessObject[]>([]);
    const [responseModal, setResponseModal] = useState(false);

    const tooltipDownloadExampleExcelFile = t('pages.invoices.download_invoice_example_toolip').text;

    const [selectedInvoicesArray, setSelectedInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false);
    const [invoicesModalType, setInvoicesModalType] = useState<string>('');

    const [removePaymentsModal, setRemovePaymentsModal] = useState(false);

    useBackOfficePasswordProtect();

    const { isDesktopLargeAndDown } = useResponsive();

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const getInvoices = (): void => {
        if (selectedUserId) {
            setLoaderVisible(true);
            communication
                .getInvoicesByUserId(selectedUserId, {
                    ...params,
                    search: 'is_prepaid:0;' + formatParams(params.search),
                    searchFields: 'is_prepaid:=;' + formatParams(params.searchFields),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setInvoices(res?.data);
                        handleSelectAllInvoices(false);
                    }
                })
                .then(() => {
                    if (setStatistics) {
                        getStatistics(setStatistics, selectedUserId);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        toast.error(error?.response?.data?.message);
                        setInvoices([]);
                    }
                })
                .finally(() => {
                    setLoaderVisible(false);
                });
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileInput = event.target; // Reference to the input element
        const file = fileInput.files?.[0];

        if (file) {
            const validMimeTypes = [
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
            ];

            if (!validMimeTypes.includes(file.type)) {
                setWrongTypeModal(true);
                fileInput.value = ''; // Clear the input value
                return;
            }
            setWrongTypeModal(false);
            setLoaderVisible(true);
            communication
                .importInvoices(selectedUserId, file)
                .then((res: ObjectKeys) => {
                    setLoaderVisible(false);
                    if (res && res.data && res.status === 200) {
                        if (res?.data?.success_rows?.length > 0) {
                            const successMessages = res.data.success_rows;
                            setSuccessMessage(successMessages);
                            const errorMessages = res.data.errors;
                            setUploadErrorMessage(errorMessages);
                            getInvoices();
                            toast.success('Invoices imported successfully');
                        } else if (res && res.data && res.data.errors && res.data.errors.length > 0) {
                            const errorMessages = res.data.errors;
                            setUploadErrorMessage(errorMessages);
                            const successMessages = res.data.success_rows;
                            setSuccessMessage(successMessages);
                        } else if (res?.data?.success_rows?.length === 0 && res.data.errors.length === 0) {
                            setUploadErrorMessage('');
                            setSuccessMessage('');
                        }
                        setResponseModal(true);
                    } else {
                        setErrorMessage(t('pages.clients.modal.form.failedUpload').text);
                        setErrorModal(true);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setLoaderVisible(false);
                    console.error(error);
                    setErrorMessage(t('pages.clients.modal.form.failedUpload').text);
                    setErrorModal(true);
                })
                .finally(() => {
                    fileInput.value = ''; // Reset input after success or error
                });
        }
    };

    useEffect(() => {
        if (selectedUserId) {
            getInvoices();
        }
    }, [selectedUserId, params]);

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };
    //Invoice Change
    // const handleChange = (invoiceId: string): void => {
    //     navigate(`/admin/invoices/edit/${invoiceId}/${selectedUserId}`);
    // };
    const handleChange = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice);
        if (invoice.status === 'paid' || invoice.status === 'partially') {
            setRemovePaymentsModal(true);
        } else {
            navigate(`/admin/invoices/edit/${invoice.id}/${selectedUserId}`);
        }
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        const invoiceNumber = invoice.prefix ? invoice.prefix + ' ' + invoice.invoice_number : invoice.invoice_number;
        const invoiceId = invoice.id;
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={clickedInvoice}
                invoiceNumber={invoiceNumber}
                closeGlobalModal={closeGlobalModal}
                setInvoices={setInvoices}
                selectedUserId={selectedUserId}
                invoiceId={invoiceId}
                onDeleteInvoice={() => {
                    getInvoices();
                }}
            />,
        );
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        if (invoice.type !== 'foreign') {
            generateQrCode(
                agency.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res.i) {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            res.i,
                        );
                    } else {
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            undefined,
                        );
                    }
                })
                .catch((error: ObjectKeys) => {
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    );
                    console.error(error);
                });
        } else {
            communication.downloadInvoice(
                invoiceId,
                token,
                invoice.invoice_number,
                invoice.currency !== 'RSD',
                false,
                undefined,
            );
        }
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice);
    };

    const triggerFileInput = (): void => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const triggerExampleInvoiceDownload = (lang: string): void => {
        let fileName = 'Invoice_Import_TemplateSRB.xlsx';
        if (lang === 'Srpski') {
            fileName = 'Invoice_Import_TemplateSRB.xlsx';
        } else if (lang === 'English') {
            fileName = 'Invoice_Import_TemplateENG.xlsx';
        } else if (lang === 'Русский') {
            fileName = 'Invoice_Import_TemplateRUS.xlsx';
        }

        const filePath = `${process.env.PUBLIC_URL}/${fileName}`;
        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteInvoices = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
        setInvoicesModalType('delete');
    };

    const handleDownloadInvoices = (): void => {
        getSelectedInvoicesNames();
        setInvoicesModalType('download');
    };

    const handleInvoicesPayment = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
        setInvoicesModalType('payment');
    };

    const getSelectedInvoicesNames = (): string[] => {
        const invoiceNamesArray: string[] = [];
        selectedInvoicesArray.map((separateItem) => {
            const foundObject = invoices?.data?.find((obj: any) => obj.id === separateItem);

            invoiceNamesArray.push(`${foundObject.prefix ? foundObject.prefix : ''} ${foundObject.invoice_number}`);
        });

        return invoiceNamesArray;
    };

    const handleSelectAllInvoices = (isChecked: boolean) => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newInvoiceIds = invoices?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedInvoicesArray(newInvoiceIds);
        } else {
            setSelectedInvoicesArray([]);
        }
    };
    const handleModalSubmission = (): void => {
        if (invoicesModalType === 'delete') {
            setLoaderVisible(true);
            setIsInvoicesModalOpen(false);
            communication
                .deleteSelectedInvoices('delete', selectedInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);

                    if (error?.response?.status === 422) {
                        const realErrorMessage = error?.response?.data?.errors;

                        const firstKey: any = Object.keys(realErrorMessage || {})[0];
                        const message = realErrorMessage?.[firstKey]?.[0] || 'An error occurred';
                        toast.error(message);
                        getInvoices();
                    }
                });
        } else if (invoicesModalType === 'payment') {
            setLoaderVisible(true);
            setIsInvoicesModalOpen(false);
            communication
                .chargeSelectedInvoices('charge', selectedInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                });
        }
    };
    const handleSelectedCard = (id: string): void => {
        const isInvoiceInArray = selectedInvoicesArray.find((selectedInvoice) => selectedInvoice === id);
        if (isInvoiceInArray) {
            const filteredInvoiceList = selectedInvoicesArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedInvoicesArray(filteredInvoiceList);
        } else {
            setSelectedInvoicesArray((prevState) => [...prevState, id]);
        }
    };

    const removeAllPayments = (): void => {
        setRemovePaymentsModal(false);
        setLoaderVisible(true);
        communication
            .removeAllPayments(clickedInvoice.id)
            .then(async (res: ObjectKeys) => {
                if (res.status === 204) {
                    navigate(`/admin/invoices/edit/${clickedInvoice.id}/${selectedUserId}`);
                    setLoaderVisible(false);
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    return (
        <>
            <ToastContainer />
            {loaderVisible && <Loader />}
            {removePaymentsModal && (
                <Modal
                    modalVisible={removePaymentsModal}
                    closeModal={() => setRemovePaymentsModal(!removePaymentsModal)}
                >
                    <ConfirmModal
                        action={async () => {
                            setLoaderVisible(true);
                            setRemovePaymentsModal(false);
                            removeAllPayments();
                        }}
                        close={() => {
                            setRemovePaymentsModal(false);
                        }}
                        active={removePaymentsModal}
                        message={t('warnings.changingInvoiceWarning').text}
                    />
                </Modal>
            )}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={'Invalid file type. Please upload an Excel file.'}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}

            {responseModal && (
                <Modal modalVisible={responseModal} closeModal={() => setResponseModal(false)}>
                    <ResponseModal
                        t={t}
                        setOpenModal={setResponseModal}
                        errorMessages={uploadErrorMessage ? uploadErrorMessage : ''}
                        successMessages={successMessage ? successMessage : ''}
                    />
                </Modal>
            )}
            {isInvoicesModalOpen && (
                <Modal
                    modalVisible={isInvoicesModalOpen}
                    closeModal={() => {
                        setIsInvoicesModalOpen(false);
                    }}
                >
                    <HandleInvoicesModal
                        t={t}
                        closeGlobalModal={() => {
                            setIsInvoicesModalOpen(false);
                        }}
                        selectedInvoicesInformationArray={getSelectedInvoicesNames()}
                        modalType={invoicesModalType}
                        invoiceType="invoice"
                        isModalConfirmed={handleModalSubmission}
                    />
                </Modal>
            )}
            <div className="invoices page">
                <Header>
                    <h1>{t('pages.invoices.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                    <div className="userInteraction">
                        <div className="uploadInvoice">
                            <Icon onClick={triggerFileInput}>
                                <FontAwesomeIcon icon={faArrowRightToFile} fontSize={'15px'} color={colors.purple} />
                            </Icon>
                            <p onClick={triggerFileInput}>{t('pages.invoices.upload_tooltip_header').text}</p>
                            <Tooltip
                                text={tooltipUpload}
                                isHtml={true}
                                position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                customPositioning={{ top: '40%', right: '80%', transform: 'translateY(-50%)' }}
                                zIndex={10}
                                label={
                                    <FontAwesomeIcon
                                        icon={faCircleQuestion}
                                        fontSize={'15px'}
                                        style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                        color={colors.purple}
                                    />
                                }
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept=".pdf,.doc,.docx,.xlsx,.csv" // Specify accepted file types
                            />
                        </div>
                        <div className="downloadInvoice">
                            <div className="serbianInvoiceDownload">
                                <Icon onClick={() => triggerExampleInvoiceDownload('Srpski')}>
                                    <FontAwesomeIcon icon={faDownload} fontSize={'15px'} color={colors.purple} />
                                </Icon>
                                <p onClick={() => triggerExampleInvoiceDownload('Srpski')}>
                                    PREUZMI DOKUMENT NA SRPSKOM
                                </p>
                                <Tooltip
                                    text={tooltipDownloadExampleExcelFile}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '50%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color={colors.purple}
                                        />
                                    }
                                />
                            </div>
                            <div className="russianInvoiceDownload">
                                <Icon onClick={() => triggerExampleInvoiceDownload('Русский')}>
                                    <FontAwesomeIcon icon={faDownload} fontSize={'15px'} color={colors.purple} />
                                </Icon>
                                <p onClick={() => triggerExampleInvoiceDownload('Русский')}>
                                    PREUZMI DOKUMENT NA RUSKOM
                                </p>
                                <Tooltip
                                    text={tooltipDownloadExampleExcelFile}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '50%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color={colors.purple}
                                        />
                                    }
                                />
                            </div>
                            <div className="englishInvoiceDownload">
                                <Icon onClick={() => triggerExampleInvoiceDownload('English')}>
                                    <FontAwesomeIcon icon={faDownload} fontSize={'15px'} color={colors.purple} />
                                </Icon>
                                <p onClick={() => triggerExampleInvoiceDownload('English')}>
                                    PREUZMI DOKUMENT NA ENGLESKOM
                                </p>
                                <Tooltip
                                    text={tooltipDownloadExampleExcelFile}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '50%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color={colors.purple}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </SelectWrapper>
                <Statistics selectedUserId={selectedUserId} setStatistics={setStatistics} statistics={statistics} />
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <SelectAllSection>
                    <div className="selectCheckbox">
                        <Checkbox
                            onChange={(value: any) => {
                                handleSelectAllInvoices(value);
                            }}
                            label={t('pages.agency.extra_services.select_all').text}
                            defaultChecked={isAllChecked}
                        />
                    </div>
                    {selectedInvoicesArray.length > 0 && (
                        <div className="userControlSection">
                            <div className="selectionDiv" onClick={handleDeleteInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                            </div>
                            {/* <div className="selectionDiv" onClick={handleDownloadInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                            </div> */}
                            <div className="selectionDiv" onClick={handleInvoicesPayment}>
                                <span>{t('pages.invoiceCard.dropdownItems.charge').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.green} icon={faCircleCheck} />
                            </div>
                        </div>
                    )}
                </SelectAllSection>
                <InvoicesList className={`display-${display}`}>
                    {invoices?.data?.length > 0 ? (
                        invoices?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.Invoice}
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice)}
                                        handleDelete={() => handleDeleteModal(invoice)}
                                        handleDownload={() =>
                                            handleDownloadInvoice(
                                                invoice?.id,
                                                invoice?.original_data?.agency,
                                                invoice?.client?.data,
                                                invoice.value_in_rsd,
                                                invoice.invoice_number,
                                                invoice.bank_account,
                                                invoice.currency,
                                                true,
                                                invoice,
                                            )
                                        }
                                        handleClick={() => handleClickedInvoice(invoice)}
                                        eInvoice={invoice?.is_einvoice}
                                        checkedInvoice={handleSelectedCard}
                                        cardIdArray={selectedInvoicesArray}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.invoices.noItems.description').text} />
                    )}
                </InvoicesList>
                {invoices?.data?.length > 0 && (
                    <Pagination
                        pageCount={invoices.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            invoices.meta?.pagination?.current_page === invoices.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${invoices.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={invoices.meta?.pagination?.current_page - 1}
                        currentPage={invoices.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default Invoices;

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .userInteraction {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-left: auto;
        font-size: 12px;
    }

    .uploadInvoice,
    .downloadInvoice > * {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin-right: 10px;
            font-size: 15px;
            line-height: 15px;
            color: var(--purple);
            cursor: pointer;
        }
    }

    .downloadInvoice {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        justify-content: flex-end;
        margin-top: 20px;
    }
    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        flex-direction: column;
        align-items: start;

        .userInteraction {
            margin-top: 15px;
        }

        .userInteraction,
        .downloadInvoice {
            margin-left: 0;
            align-items: flex-start;
        }

        .user-selector {
            margin-left: 0;
            margin-top: 10px;
        }
    }
`;
const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;
const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
        }
    }
`;
