import { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../global/colors';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';

type Items = {
    icon?: any;
    iconColor?: any;
    label: string;
    link?: string;
    newTab?: boolean;
    handler?: Function;
    download?: boolean;
};
interface Props {
    itemList: Items[];
    background?: string;
    arrowLeft?: string;
    arrowRight?: string;
    caretDown?: boolean;
}

interface CaretProps {
    background?: string;
    arrowLeft?: string;
    arrowRight?: string;
    caretDown?: boolean;
}

const Dropdown: FunctionComponent<Props> = ({ itemList, background, arrowLeft, arrowRight, caretDown = false }) => {
    return (
        <Wrapper>
            <Caret
                background={background}
                arrowLeft={arrowLeft}
                arrowRight={arrowRight}
                className="fa-1x"
                caretDown={caretDown}
            >
                <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.purple} icon={faCaretDown} />
            </Caret>
            <Container className="dropdown-container">
                {itemList
                    ?.filter((i) => !isEmpty(i))
                    ?.map((i) => (
                        <div key={i.label} className="itemListClass">
                            {i.download ? (
                                <a href={i.link} download>
                                    <Item>
                                        {i.icon && (
                                            <FontAwesomeIcon
                                                color={i.iconColor ? i.iconColor : colors.gray}
                                                icon={i.icon}
                                            />
                                        )}
                                        <Label className="label">{i.label}</Label>
                                    </Item>
                                </a>
                            ) : i.link ? (
                                <Link target={i.newTab ? '_blank' : ''} to={i.link}>
                                    <Item
                                        onClick={() => {
                                            i.handler && i.handler();
                                        }}
                                    >
                                        {i.icon && (
                                            <FontAwesomeIcon
                                                color={i.iconColor ? i.iconColor : colors.gray}
                                                icon={i.icon}
                                            />
                                        )}
                                        <Label className="label">{i.label}</Label>
                                    </Item>
                                </Link>
                            ) : (
                                <Item
                                    onClick={(e) => {
                                        i.handler && i.handler(e);
                                    }}
                                >
                                    {i.icon && (
                                        <FontAwesomeIcon
                                            color={i.iconColor ? i.iconColor : colors.gray}
                                            icon={i.icon}
                                        />
                                    )}
                                    <Label className="label">{i.label}</Label>
                                </Item>
                            )}
                        </div>
                    ))}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    height: 50px;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
`;

const Container = styled.div`
    padding: 5px 10px;
    background: var(--white);
    border-radius: 4px;
    border: 1px solid var(--purple);
    // @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
    //     top: -75px;
    //     display: flex;
    //     width: max-content;
    //     left: -87px;
    //     position: absolute;
    //     justify-content: space-between;
    //     gap: 10px;
    // }
    .itemListClass:last-child ${Item} {
        border-bottom: none;
    }
`;

const Label = styled.p`
    color: var(--adjust);
    margin-left: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 13px;
`;

const Caret = styled.span<CaretProps>`
    z-index: 1;
    position: absolute;
    rotate: ${(props) => (!props.caretDown ? '180deg' : 'unset')};
    top: ${(props) => (!props.caretDown ? '-11px' : 'unset')};
    bottom: ${(props) => (props.caretDown ? '-11px' : 'unset')};
    width: min-content;
    left: ${(props) => props.arrowLeft && `${props.arrowLeft}`};
    right: ${(props) => (props.arrowRight ? `${props.arrowRight}` : '0')};
    background: ${(props) => props.background && `${props.background}`};
`;

export default Dropdown;
