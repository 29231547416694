import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../../global/colors';
import { ObjectKeys } from '../../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../../components/button';
import Input, { InputComponentType } from '../../../../../components/input';
import Select from '../../../../../components/select';
import communication from '../../../../../communication';
import Loader from '../../../../../components/loader';
import useCharLengthValidation from '../../../../../hooks/useCharLengthValidation';

interface Props {
    t: Function;
    close: Function;
    success: Function;
    loading: Function;
    modalVisible: boolean;
    errorMessage: Function;
    type: 'service' | 'consultation';
}

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

const SendModal: FunctionComponent<Props> = ({ close, modalVisible, success, type }) => {
    const [formData, setFormData] = useState<ObjectKeys>({
        name_rs: '',
        name_en: '',
        name_ru: '',
        description_rs: '',
        description_en: '',
        description_ru: '',
        type: type,
        services: [],
    });
    const [questionModal, setQuestionModal] = useState(false);
    const [services, setServices] = useState<Service[]>();
    const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const name_rsValidationMessage = useCharLengthValidation(formData.name_rs, 3, true);
    const name_enValidationMessage = useCharLengthValidation(formData.name_en, 3, true);
    const name_ruValidationMessage = useCharLengthValidation(formData.name_ru, 3, true);

    const isFormDataValid = (): boolean => {
        const validationMessages = [name_rsValidationMessage, name_enValidationMessage, name_ruValidationMessage];
        const names = [formData.name_rs, formData.name_en, formData.name_ru];

        return (
            validationMessages.every((msg) => msg === '') &&
            names.every((name) => name.trim() !== '') &&
            formData.services.length > 0
        );
    };

    useEffect(() => {
        if (!modalVisible) return;
    }, [modalVisible]);

    const handleRemoveService = (serviceId: number): void => {
        if (!formData || !services) return;
        const index = formData.services.findIndex((service: ObjectKeys) => service.id === serviceId);

        if (index !== -1) {
            const updatedEditedFormData = {
                ...formData,
                services: formData.services.filter((service: ObjectKeys) => service.id !== serviceId),
            };
            const removedService = formData.services[index];
            setServices([...services, removedService]);
            setFormData(updatedEditedFormData);
        }
    };

    const handleAddService = (service: ObjectKeys): void => {
        if (!formData || !services) return;
        const updatedServices = services.filter((s: ObjectKeys) => s.id !== service.id);
        const updatedEditedFormData = {
            ...formData,
            services: [...formData.services, service],
        };
        setServices(updatedServices);
        setFormData(updatedEditedFormData);
    };

    const createPackage = (): void => {
        setError(false);
        // setLoader(true);
        const serviceIdsOnly = formData.services.map((service: Service) => service.id);
        const updatedFormData = {
            ...formData,
            services: serviceIdsOnly,
        };
        communication
            .createServicePackage(updatedFormData)
            .then((res: ObjectKeys) => {
                setLoader(false);
                success();
            })
            .catch((err: any) => {
                console.error('Error:', err);
                setLoader(false);
            });
    };

    const getAllServices = (): void => {
        setLoader(true);
        const params = {
            page: 1,
            limit: 1000,
            search: '',
            searchFields: '',
            searchJoin: 'and',
            orderBy: 'name_rs',
            sortedBy: 'asc',
        };
        if (type === 'consultation') {
            params.search = 'is_consultation:1;';
            params.searchFields = 'is_consultation:=;';
        }
        communication
            .getAllAdminServices(params)
            .then((response: ObjectKeys) => {
                setServices(response.data.data);
                setLoader(false);
            })
            .catch((err: any) => {
                console.error('Error:', err);
                setLoader(false);
            });
    };

    useEffect(() => {
        getAllServices();
    }, []);

    return (
        <>
            {loader && <Loader />}
            {questionModal ? (
                <>
                    <Question>
                        <p>Da li ste sigurni da želite da dodate paket?</p>
                        <div className="button-container">
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    createPackage();
                                    close();
                                }}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ color: 'var(--white)', marginRight: '10px' }} />
                                Da
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="small"
                                size={200}
                                onClick={() => {
                                    setQuestionModal(false);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ color: 'var(--white)', marginRight: '10px' }}
                                />
                                Odustani
                            </Button>
                        </div>
                    </Question>
                </>
            ) : (
                <>
                    {formData && (
                        <Container>
                            <Header>
                                <h2>Dodaj paket</h2>
                                <Icon onClick={() => close()}>
                                    <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                                </Icon>
                            </Header>
                            <Input
                                type={InputComponentType.Text}
                                label={'Naziv srpski'}
                                value={formData.name_rs}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        name_rs: value,
                                    });
                                }}
                                validation={error && name_rsValidationMessage ? name_rsValidationMessage : ''}
                                maxLength={190}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Naziv engleski'}
                                value={formData.name_en}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        name_en: value,
                                    });
                                }}
                                validation={error && name_enValidationMessage ? name_enValidationMessage : ''}
                                maxLength={190}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Naziv ruski'}
                                value={formData.name_ru}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        name_ru: value,
                                    });
                                }}
                                validation={error && name_ruValidationMessage ? name_ruValidationMessage : ''}
                                maxLength={190}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Opis srpski'}
                                value={formData.description_rs}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        description_rs: value,
                                    });
                                }}
                                maxLength={500}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Opis engleski'}
                                value={formData.description_en}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        description_en: value,
                                    });
                                }}
                                maxLength={500}
                            />
                            <br />
                            <Input
                                type={InputComponentType.Text}
                                label={'Opis ruski'}
                                value={formData.description_ru}
                                onChange={(value: string) => {
                                    setFormData({
                                        ...formData,
                                        description_ru: value,
                                    });
                                }}
                                maxLength={500}
                            />
                            <br />
                            <div>
                                <p className="services">Usluge koje su uključene u paket:</p>
                                {formData.services.map((service: ObjectKeys) => (
                                    <div key={service.id}>
                                        <p className="items" onClick={() => handleRemoveService(service.id)}>
                                            <FontAwesomeIcon icon={faTrash} style={{ color: 'var(--danger)' }} />
                                            {service.name_rs}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <br />
                            <div>
                                <p className="services">Dodaj uslugu u paket:</p>
                                {services && services.length > 0 ? (
                                    <>
                                        {services.map((service: ObjectKeys) => (
                                            <div key={service.id}>
                                                <p className="items" onClick={() => handleAddService(service)}>
                                                    <FontAwesomeIcon icon={faPlus} style={{ color: 'var(--purple)' }} />
                                                    {service.name_rs}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <p>Nema usluga</p>
                                )}
                            </div>
                            {error && formData?.services?.length === 0 && (
                                <p style={{ color: 'var(--danger)', marginTop: '20px', textAlign: 'center' }}>
                                    Nije uneta nijedna usluga u paket
                                </p>
                            )}

                            <div className="button-container">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    className="small"
                                    size={200}
                                    onClick={() => {
                                        if (!isFormDataValid()) {
                                            setError(true);
                                            return;
                                        } else {
                                            setError(false);
                                            setQuestionModal(true);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faSave}
                                        style={{ color: 'var(--white)', marginRight: '10px' }}
                                    />
                                    Sacuvaj
                                </Button>
                            </div>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
        height: 50px;
    }
    label {
        color: var(--gray);
    }
    .six-months {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .discount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            font-size: 18px;
        }
    }
    .discount-input {
        width: 15%;
    }
    .services {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 5px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        cursor: pointer;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;
const Question = styled.div`
    display: flex;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    p {
        font-size: 20px;
        font-weight: 700;
        color: var(--danger);
        text-align: center;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
