import { Dispatch, FunctionComponent, SetStateAction, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useCurrencyStore from '../../../../store/currency';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import SelectComponent from '../../../../components/select';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSearch from '../../../../components/searchCreateNew';
import Loader from '../../../../components/loader';
import { useTipsQuery } from '../../../../react-query/useTipsQuery';
import InstructionsTooltip from '../../../..//components/instructionsTooltip';
import useTheme from '../../../../hooks/useTheme';
import useResponsive from '../../../../hooks/responsive/useResponsive';

type Props = {
    t: Function;
    setEditClientModal: Dispatch<SetStateAction<boolean>>;
    success: Function;
    measurementUnits: { value: string; label: string }[];
    type: 'service' | 'product';
    triggerServicesRefetch: Function;
};

const AddArticleModal: FunctionComponent<Props> = ({
    t,
    setEditClientModal,
    success,
    measurementUnits,
    type,
    triggerServicesRefetch,
}) => {
    const { currentLang } = useLanguageStore();
    const { currency } = useCurrencyStore();
    const [formData, setFormData] = useState<any>({
        name: '',
        default_measurement_unit: '',
        default_price: '',
        default_currency: 'rsd',
        id: '',
        type: type,
    });
    const articleTypeOptions = [
        { label: t('pages.editInvoice.addServiceModal.product').text, value: 'product' },
        { label: t('pages.editInvoice.addServiceModal.service').text, value: 'service' },
    ];
    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [clickedField, setClickedField] = useState('');
    const [showValidationMessage] = useState(false);

    const { isBiggerThanMobile, isMobile: isMobilePhone } = useResponsive();

    const [loader, setLoader] = useState(false);

    const isDarkTheme = useTheme();

    const nameValidationMessage = useCharLengthValidation(formData.name, 3, true);
    // const isPriceValid = useCharLengthValidation(formData.default_price, 3, true);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    const handleCreateService = (): void => {
        setLoader(true);
        if (!nameValidationMessage) {
            try {
                communication
                    .createArticle({
                        ...formData,
                        is_foreign: 0,
                        new: true,
                        default_price: parseFloat(formData.default_price.replace(',', '.')),
                    })
                    .then((res: ObjectKeys) => {
                        if (res.status === 200) {
                            triggerServicesRefetch();
                            setLoader(false);
                            toast.success(t('pages.listOfArticles.add_success').text);
                            success();
                        } else {
                            setLoader(false);
                            toast.error(t('pages.confirmEmail.title').text);
                        }
                    });
            } catch (error: any) {
                console.error(error);
                setLoader(false);
                toast.error(t('pages.confirmEmail.title').text);
            }
        } else {
            setLoader(false);
            handleValidation('name');
        }
    };

    const createInvoice15 = useRef(null);
    const createInvoice16 = useRef(null);
    const createInvoice17 = useRef(null);
    const createInvoice18 = useRef(null);
    const createInvoice19 = useRef(null);

    const { tips, setNextTipActive, skipGroup } = useTipsQuery({ page: 1, per_page: 5 }, 1);

    const refs = [
        { nameRef: 'createInvoice15', ref: createInvoice15 },
        { nameRef: 'createInvoice16', ref: createInvoice16 },
        { nameRef: 'createInvoice17', ref: createInvoice17 },
        { nameRef: 'createInvoice18', ref: createInvoice18 },
        { nameRef: 'createInvoice19', ref: createInvoice19 },
    ];

    const [tooltipTargetRef, setTooltipTargetRef] = useState<React.RefObject<HTMLElement> | null>(null);

    useEffect(() => {
        if (tips && !isMobilePhone) {
            const activeTip = tips?.tips?.data?.find((tip: any) => tip.is_active);

            if (activeTip) {
                const orderStr = activeTip.order.toString();

                const matchingRef = refs.find(({ nameRef }) => {
                    const orderInNameRef = nameRef.replace('createInvoice', '');
                    return orderInNameRef === orderStr;
                });

                if (matchingRef) {
                    setTooltipTargetRef(matchingRef.ref);
                } else {
                    setTooltipTargetRef(null);
                }

                if (activeTip.order === 20) {
                    setEditClientModal(false);
                }
            }
        }
    }, [tips]);

    return (
        <EditClientModalWrapper>
            {tooltipTargetRef?.current && !isMobilePhone && (
                <InstructionsTooltip targetRef={tooltipTargetRef} position="bottom" />
            )}
            <ToastContainer />
            {loader && <Loader />}
            <ModalText>
                <h4>
                    {t('pages.listOfArticles.addArticle').text}
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: 'var(--gray)' }}
                        onClick={() => setEditClientModal(false)}
                    />
                </h4>
                {/* <p>{t('pages.clients.AddArticleModal.description').text}</p> */}
            </ModalText>
            <Form isDarkTheme={isDarkTheme}>
                <span ref={createInvoice15}>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.listOfArticles.modal.form.name').text}
                        validation={showValidationMessage || clickedField === 'name' ? nameValidationMessage : ''}
                        value={formData.name ? formData.name : ''}
                        blurHandler={() => handleValidation('name')}
                        onChange={(value: string) => {
                            setFormData({ ...formData, name: value });
                        }}
                        maxLength={500}
                    />
                </span>
                <UnitSelectWrapper>
                    <label htmlFor="">{t('pages.listOfArticles.modal.form.unitOfMeasure').text}</label>
                    <div ref={createInvoice16}>
                        <SelectSearch
                            optionList={measurementUnits}
                            defaultValue={measurementUnits.find(
                                (unit) => unit.value === formData.default_measurement_unit,
                            )}
                            placeholder={t('pages.invoices.add_measurement_unit').text}
                            handleSelectedValue={(unit: { label: string; value: string }) => {
                                setFormData({ ...formData, default_measurement_unit: unit.value });
                            }}
                            handleCreateOption={(unit) => {
                                setFormData({ ...formData, default_measurement_unit: unit.value });
                            }}
                        />
                    </div>
                </UnitSelectWrapper>
                <div ref={createInvoice17}>
                    <Input
                        type={InputComponentType.Amount}
                        label={t('pages.listOfArticles.modal.form.price').text}
                        value={formData.default_price}
                        onChange={(value: string) => {
                            setFormData({ ...formData, default_price: value });
                        }}
                    />
                </div>

                <div ref={createInvoice18}>
                    <SelectComponent
                        className="select-container currency-select"
                        optionList={currencies}
                        value={formData.default_currency ? formData.default_currency : 'EUR'}
                        label={t('pages.clients.modal.form.foreign.currency').text}
                        defaultSelectedOption={{
                            label: formData.default_currency?.toUpperCase() || '',
                            value: formData.default_currency || '',
                        }}
                        handleSelectedValue={(value: string) => {
                            setFormData({ ...formData, default_currency: value });
                        }}
                    />
                </div>
                <Input
                    type={InputComponentType.TextArea}
                    label={t('pages.editInvoice.articleDescription').text}
                    value={formData.description ? formData.description : ''}
                    // blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, description: value });
                    }}
                    className="articleDescriptionField"
                    placeholder={t('pages.editInvoice.insertArticleDescription').text}
                    maxLength={1490}
                />
            </Form>
            <ButtonWrapper>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'130px'}
                    onClick={() => {
                        handleCreateService();
                    }}
                >
                    {t('buttons.save').text}
                </Button>
            </ButtonWrapper>
        </EditClientModalWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const UnitSelectWrapper = styled.div`
    flex: 1;
    width: 100%;
    min-width: 180px;

    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        display: flex;
        padding: 5px 0;
    }
    > div {
        width: 100%;
    }
    .select-search {
        > div {
            box-shadow: none;
        }
        .input-dropdown {
            background-color: transparent;
            box-shadow: none;
            border-radius: 5px;
            .selected-value {
                height: 28px;
                display: flex;
                align-items: center;
            }
        }
    }
`;

const EditClientModalWrapper = styled.div`
    .select-wrapper {
        width: 100%;
        margin-bottom: 20px;
        label {
            font-size: 15px;
            color: var(--gray);
            margin-bottom: 2px;
            display: flex;
        }
        .currency-select {
            display: flex;
            flex-direction: column;
            > div,
            label {
                width: 100%;
            }
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--black);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
        div {
            font-size: 15px;
            color: var(--gray);
            .select__menu-list {
                .select__option {
                    &:hover {
                        color: var(--white);
                        background: var(--purple);
                    }
                    &.select__option--is-selected {
                        color: var(--white);
                        background: var(--purple);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;

const ModalText = styled.div`
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
    }
`;
const Form = styled.div<DarkThemeProps>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 30px;

    .articleDescriptionField {
        textarea {
            outline: none;
            border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#ccc')};
            font-size: 14px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
            background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#fff')};
            padding: 5px 0 0 5px;
            height: 150px;
        }

        textarea::placeholder {
            padding: 5px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`;
export default AddArticleModal;
