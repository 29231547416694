import { FunctionComponent, useEffect, useState } from 'react';
import {
    MobileRow,
    MobileTotal,
    Row,
    ServiceDescriptionContainer,
    StyledInput,
    TextAreaContainer,
    ToggleButton,
    ToggleText,
} from '../style';
import SelectSearch from '../../../../../components/searchCreateNew';
import Input, { InputComponentType } from '../../../../../components/input';
import { ObjectKeys } from '../../../../../types/objectKeys';
import colors from '../../../../../global/colors';
import { formatToSerbianNumber, formatTwoDecimals } from '../../../../../functions/format';
import useServiceNumberValidation from '../../../../../hooks/useServiceNumberValidation';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '../../../../../hooks/useTheme';

type SelectOption = {
    value?: string;
    label?: string;
};

interface Props {
    t: Function;
    index: number;
    service: ObjectKeys;
    services: ObjectKeys[];
    units: ObjectKeys[];
    setFormData: Function;
    handleTotal: Function;
    invoice: ObjectKeys;
    setUnits?: Function;
    handleServiceClick: Function;
}

const MobileService: FunctionComponent<Props> = ({
    t,
    index,
    service,
    services,
    units,
    setFormData,
    handleTotal,
    invoice,
    setUnits,
    handleServiceClick,
}) => {
    const transformedService: ObjectKeys = {
        ...service,
        pivot: {
            ...service.pivot,
            price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
            quantity: service?.pivot?.quantity?.replace('.', ','),
            discount: service?.pivot?.discount ? service?.pivot?.discount?.toString().replace('.', ',') : 0,
        },
    };
    const isQuantityValid = useServiceNumberValidation(transformedService.pivot.quantity);
    const isPriceValid = useServiceNumberValidation(transformedService.pivot.price_per_unit);

    const [totalServicePrice, setTotalServicePrice] = useState<number>(0);

    const [isTextareaVisible, setIsTextareaVisible] = useState(false);

    const isDarkTheme = useTheme();

    const toggleTextareaVisibility = () => {
        setIsTextareaVisible(!isTextareaVisible);
    };

    useEffect(() => {
        if (service.description || service.pivot.description) {
            setIsTextareaVisible(true);
        }
    }, []);

    useEffect(() => {
        setFormData((prev: ObjectKeys) => {
            const copy = { ...prev };
            const quantity = Number(transformedService.pivot.quantity.replace(',', '.'));
            const price = Number(transformedService.pivot.price_per_unit.replace(',', '.'));
            const discount = Number(transformedService.pivot.discount.toString().replace(',', '.'));
            copy.services[index].pivot.total_price = quantity * price - (quantity * price * discount) / 100;
            setTotalServicePrice(copy.services[index].pivot.total_price);
            return copy;
        });
    }, [transformedService.pivot.price_per_unit, transformedService.pivot.discount]);

    useEffect(() => {
        handleTotal(
            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                return accumulator + (isNaN(object.pivot.total_price) ? 0 : Number(object.pivot.total_price));
            }, 0),
        );
    }, [totalServicePrice]);

    return (
        <>
            <Row className="service-list">
                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.item').text}</span>
                        <p onClick={() => handleServiceClick()} className="type">
                            {transformedService.name
                                ? transformedService.name
                                : services.find((s: ObjectKeys) => s.id === transformedService.id)?.name}
                        </p>
                    </div>
                </MobileRow>
                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unit').text}</span>
                        <SelectSearch
                            handleSelectedValue={(v: ObjectKeys) => {
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services[index].pivot.measurement_unit = v.value;
                                    return copy;
                                });
                            }}
                            handleCreateOption={(c: SelectOption) => {
                                let newUnitAdded = false;
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services[index].pivot.measurement_unit = c.value;
                                    return copy;
                                });
                                // Check if the unit already exists, if not, add it
                                if (!units.some((unit) => unit.value === c.value)) {
                                    units.push({ label: c.label, value: c.value });
                                    newUnitAdded = true;
                                }
                                // If a new unit was added, update the state to re-render the component
                                if (newUnitAdded && setUnits) {
                                    setUnits([...units]);
                                }
                            }}
                            optionList={units.map((unit) => ({
                                label: unit.label,
                                value: unit.value,
                            }))}
                            key={units.length}
                            defaultValue={units.find((u) => u.value === transformedService.pivot.measurement_unit)}
                            color={colors.black}
                            noValuePlaceholderColor="red"
                            placeholder={t('pages.invoices.add_measurement_unit').text}
                            searchPlaceholder={t('input.search_and_add').text}
                        />
                    </div>
                </MobileRow>

                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.amount').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Amount}
                                    negative={true}
                                    value={
                                        transformedService.pivot.quantity !== ''
                                            ? transformedService.pivot.quantity
                                            : ''
                                    }
                                    readOnly={false}
                                    onChange={(v: any) => {
                                        {
                                            setFormData((prev: ObjectKeys) => {
                                                const copy = { ...prev };
                                                copy.services[index].pivot.quantity = v;
                                                const quantity = Number(v.replace(',', '.'));
                                                const price = transformedService.pivot.price_per_unit.replace(',', '.');
                                                const discount = transformedService.pivot.discount;
                                                copy.services[index].pivot.total_price =
                                                    quantity * price - (quantity * price * discount) / 100;
                                                setTotalServicePrice(copy.services[index].pivot.total_price);
                                                return copy;
                                            });
                                            handleTotal(
                                                invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                    return (
                                                        accumulator +
                                                        (isNaN(object.pivot.total_price)
                                                            ? 0
                                                            : Number(object.pivot.total_price))
                                                    );
                                                }, 0),
                                            );
                                        }
                                    }}
                                    placeholder={t('pages.editInvoice.enterQuantity').text}
                                    validation={isQuantityValid ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>x</span>
                        </MobileRow>
                    </div>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unitPrice').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Amount}
                                    value={
                                        transformedService.pivot.price_per_unit !== ''
                                            ? transformedService.pivot.price_per_unit
                                            : ''
                                    }
                                    onChange={(v: any) => {
                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            copy.services[index].pivot.price_per_unit = v;
                                            const quantity = Number(
                                                transformedService.pivot.quantity.replace(',', '.'),
                                            );
                                            const price = v.replace(',', '.');
                                            const discount = transformedService.pivot.discount;
                                            copy.services[index].pivot.total_price =
                                                quantity * price - (quantity * price * discount) / 100;
                                            setTotalServicePrice(copy.services[index].pivot.total_price);
                                            return copy;
                                        });
                                        handleTotal(
                                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                return (
                                                    accumulator +
                                                    (isNaN(object.pivot.total_price)
                                                        ? 0
                                                        : Number(object.pivot.total_price))
                                                );
                                            }, 0),
                                        );
                                    }}
                                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                                    validation={isPriceValid ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>x</span>
                        </MobileRow>
                    </div>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.discount').text}</span>
                        <MobileRow>
                            {/* DISCOUNT */}
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Text}
                                    value={transformedService.pivot.discount}
                                    onChange={(v: any) => {
                                        let discountValue = v.replace(',', '.').replace(/[^0-9.]/g, '');
                                        if (discountValue.split('.').length > 2) return; // Prevent multiple dots

                                        const parts = discountValue.split('.');
                                        if (parts.length > 1 && parts[1].length > 2) {
                                            discountValue = parts[0] + '.' + parts[1].slice(0, 2); // Limit to 2 decimal places
                                        }
                                        if (
                                            parseFloat(discountValue) > 100 ||
                                            (parseFloat(discountValue) >= 100 && discountValue.split('.').length > 1)
                                        )
                                            discountValue = '100'; // Limit discount to 100
                                        if (
                                            discountValue.length > 1 &&
                                            discountValue[0] === '0' &&
                                            discountValue[1] !== '.'
                                        ) {
                                            discountValue = discountValue.slice(1); // Remove leading zero unless it's 0
                                        }

                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            copy.services[index].pivot.discount = discountValue;
                                            const quantity = Number(
                                                transformedService.pivot.quantity.replace(',', '.'),
                                            );
                                            const price = transformedService.pivot.price_per_unit.replace(',', '.');
                                            const discount = parseFloat(discountValue);
                                            if (!isNaN(discount) && discount <= 100) {
                                                copy.services[index].pivot.total_price =
                                                    quantity * price - (quantity * price * discount) / 100;
                                                setTotalServicePrice(copy.services[index].pivot.total_price);
                                            }
                                            return copy;
                                        });
                                        handleTotal(
                                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                return (
                                                    accumulator +
                                                    (isNaN(object.pivot.total_price)
                                                        ? 0
                                                        : Number(object.pivot.total_price))
                                                );
                                            }, 0),
                                        );
                                    }}
                                    hideBlurOnChange
                                    handleBlurAction={() => {
                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            while (
                                                Array.from(copy.services[index].pivot.discount)[0] === '0' &&
                                                Array.from(copy.services[index].pivot.discount)[1] !== '.'
                                            ) {
                                                copy.services[index].pivot.discount =
                                                    copy.services[index].pivot.discount.slice(1);
                                            }
                                            return copy;
                                        });
                                    }}
                                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                                />
                                <span>=</span>
                            </StyledInput>
                        </MobileRow>
                    </div>
                </MobileRow>

                <MobileTotal>
                    <span>{t('pages.editInvoice.previewModal.total').text}</span>
                    <StyledInput className="total">
                        <Input
                            type={InputComponentType.Text}
                            value={totalServicePrice ? formatTwoDecimals(totalServicePrice.toString()) : ''}
                        />
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services.splice(index, 1);
                                    return copy;
                                })
                            }
                        >
                            x
                        </span>
                    </StyledInput>
                </MobileTotal>
            </Row>
            <ServiceDescriptionContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleText isDarkTheme={isDarkTheme} onClick={toggleTextareaVisibility}>
                        {t('pages.editInvoice.articleDescription').text}
                    </ToggleText>
                    <ToggleButton onClick={toggleTextareaVisibility}>
                        {isTextareaVisible ? (
                            <FontAwesomeIcon
                                icon={faChevronUp}
                                color={isDarkTheme ? 'var(--purple)' : 'var(--gray)'}
                                className="fa-icon-close"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                color={isDarkTheme ? 'var(--purple)' : 'var(--gray)'}
                                className="fa-icon-add"
                            />
                        )}
                    </ToggleButton>
                </div>
                {isTextareaVisible && (
                    <TextAreaContainer isDarkTheme={isDarkTheme}>
                        <Input
                            type={InputComponentType.TextArea}
                            negative={true}
                            value={
                                service.pivot.description !== undefined
                                    ? service.pivot.description
                                    : service.description
                            }
                            readOnly={false}
                            onChange={(v: any) => {
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };

                                    copy.services[index].pivot.description = v;
                                    return copy;
                                });
                            }}
                            hideBlurOnChange
                            handleBlurAction={() => {
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    if (copy.services[index].pivot.description) {
                                        copy.services[index].pivot.description =
                                            copy.services[index].pivot.description.trim();
                                    }
                                    return copy;
                                });
                            }}
                            placeholder={t('pages.editInvoice.enterDescription').text}
                            maxLength={1490}
                        />
                    </TextAreaContainer>
                )}
            </ServiceDescriptionContainer>
        </>
    );
};

export default MobileService;
