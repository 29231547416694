import Checkbox from '../checkbox';
import { transformDateFormat } from '../../functions/formatDateTime';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotificationType } from '@/communication/notifications/types';
import useLanguageStore from '../../store/language';
import DangerouslySetInnerHTML from '../common/DangerouslySetInnerHtml';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useTheme from '../../hooks/useTheme';

interface Props extends NotificationType {
    checked: boolean;
    onCheck: (id: string) => void;
    onClick: any;
}

export const NotificationItem = ({
    alert_users,
    checked,
    onCheck,
    onClick,
    id,
    title_of_alert_rs,
    title_of_alert_en,
    title_of_alert_ru,
    description_rs,
    description_en,
    description_ru,
    created_at,
    sent_at,
}: Props) => {
    const [searchParams] = useSearchParams();
    const searchId = searchParams.get('id');
    const navigate = useNavigate();
    const isDarkTheme = useTheme();

    const lang = localStorage.language
        ? localStorage.language === 'English'
            ? 'en'
            : localStorage.language === 'Русский'
            ? 'ru'
            : 'rs'
        : 'rs';

    return (
        <Notification
            isDarkTheme={isDarkTheme}
            className={`${!alert_users.data[0].is_read ? 'is-not-read' : ''}`}
            onClick={() => {
                navigate(`/notifications${searchId !== id ? `?id=${id}` : ''}`);
                if (!alert_users.data[0].is_read) {
                    onClick([id]);
                }
            }}
        >
            <div className={`first-row ${searchId === id ? 'active' : ''}`}>
                <div className="checkbox-title-wrapper">
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            defaultChecked={checked}
                            onChange={(e: MouseEvent) => {
                                onCheck(id);
                            }}
                        />
                    </div>
                    <DangerouslySetInnerHTML htmlContent={eval(`title_of_alert_${lang}`)} />
                </div>
                <p className="date">{transformDateFormat(sent_at)}</p>
            </div>
            {searchId === id && (
                <DangerouslySetInnerHTML className="second-row" htmlContent={eval(`description_${lang}`)} />
            )}
        </Notification>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}
const Notification = styled.article<DarkThemeProps>`
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
    cursor: pointer;
    color: var(--black);
    word-break: break-word;
    &:hover {
        .first-row {
            background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#cebcff;')};
            .checkbox-title-wrapper,
            .date {
                color: var(--black);
            }
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
    .checkbox-title-wrapper {
        display: flex;
        gap: 0.5rem;
        font-size: 14px;
        color: var(--gray);
        a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50ch;
            &:hover {
                text-decoration: underline;
            }
        }
        .checkbox {
            margin-bottom: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            max-width: 120px;
        }
    }
    &.is-not-read {
        font-weight: bold;
        .checkbox-title-wrapper {
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--black)')};
        }
        .date {
            color: var(--black);
        }
    }
    .first-row {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        padding: 1.2rem 0.5rem;
        &.active {
            background-color: ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#cebcff;')};
            .checkbox-title-wrapper {
                color: var(--black);
            }
            .date {
                color: var(--black);
            }
        }
    }
    .date {
        font-size: 14px;
        color: var(--gray);
    }
    .second-row {
        padding: 1rem 2.7rem;
        font-size: 14px;
        font-style: italic;
        p {
            font-size: 13px;
        }
    }
`;
