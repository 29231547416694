import { FunctionComponent, useEffect, useState } from 'react';
import Select, { StylesConfig, components } from 'react-select';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useTheme from '../../hooks/useTheme';
import { useLocation } from 'react-router-dom';

export type SelectOption = {
    value: string | number;
    label: string;
};

interface Props {
    label?: string;
    labelVariant?: 'light' | 'dark';
    className?: string;
    placeholder?: string;
    optionList: SelectOption[];
    value?: string | null | Array<{ value: any; label: any }>;
    handleSelectedValue?: Function;
    handleCreateOption?: (value: string) => void;
    width?: string;
    color?: string;
    defaultSelectedOption?: { value: string | number; label: string };
    isMultiple?: boolean;
    isCreatable?: boolean;
    isSearchable?: boolean;
    backgroundColor?: string;
    menuTextColor?: string;
    bottomBorder?: string;
    positionConditional?: 'auto' | 'top';
    isDynamic?: boolean;
    customDropdownHeight?: string;
}

interface SelectProps {
    width?: string;
    labelVariant: 'light' | 'dark';
    isDarkTheme: boolean;
}

const SelectComponent: FunctionComponent<Props> = ({
    label,
    labelVariant = 'light',
    className,
    placeholder,
    optionList,
    value,
    handleSelectedValue,
    width,
    color,
    defaultSelectedOption,
    isMultiple = false,
    isCreatable = false,
    handleCreateOption,
    isSearchable,
    backgroundColor,
    menuTextColor,
    bottomBorder,
    positionConditional = 'auto',
    isDynamic,
    customDropdownHeight,
}) => {
    const isDarkTheme = useTheme();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const selectedOptions = defaultSelectedOption
        ? defaultSelectedOption
        : value
        ? optionList.find((v) => v.value === value)
        : defaultSelectedOption || null;

    const [areValuesDynamic, setAreValuesDynamic] = useState<boolean>(false);

    const { pathname } = useLocation();

    const selectValue = areValuesDynamic && isDynamic ? selectedOptions : value;

    const handleSelect = (data: SelectOption): void => {
        if (isMultiple) {
            handleSelectedValue && handleSelectedValue(data);
        } else {
            handleSelectedValue && handleSelectedValue(data.value);
        }
    };

    const colourStyles: StylesConfig<any> = {
        control: (base) => ({
            ...base,
            backgroundColor: 'transparent',
            borderRadius: '0px',
            cursor: 'pointer',
            boxShadow: 'none',
            height: 'fit-content',
            fontSize: '14px',
            border: '1px solid var(--border-color)',
            color: 'var(--adjust)',
            minHeight: 'none',
            ':hover': {
                border: '1px solid var(--border-color)',
            },
        }),
        option: (styles, { data, isDisabled }) => {
            const isLastOption = optionList[optionList.length - 1]?.value === data.value;
            return {
                ...styles,
                backgroundColor: '',
                color: menuTextColor ? menuTextColor : 'var(--black)',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderWidth: '2px',
                borderRadius: '0',
                borderBottom: isLastOption ? 'none' : bottomBorder ? bottomBorder : '1px solid var(--border-color)',

                ':focus': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':active': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':hover': {
                    color: 'var(--gray)',
                },
            };
        },
        input: (styles) => ({
            ...styles,
            borderWidth: '2px',
            fontSize: '15px',
            color: 'var(--adjust)',
            lineHeight: '1.428571429',
            verticalAlign: 'middle',
            transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'var(--adjust)',
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'var(--adjust)',
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '250ms',
            width: '14px',
            height: '14px',
            padding: 0,
            marginRight: '5px',
            color: 'currentcolor',
        }),
        indicatorsContainer: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '32px',
        }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
        menu: (base) => ({
            ...base,
            maxHeight: '200px',
            overflow: 'auto',
            backgroundColor: backgroundColor ? backgroundColor : 'var(--white)',
            borderRadius: '0px',
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: customDropdownHeight ? customDropdownHeight : 'inherit',
            overflow: 'inherit',
        }),
    };

    const Input = (props: any) => {
        return (
            <form
                style={{
                    display: 'inline-grid',
                    gridArea: '1 / 1 / 2 / 3',
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <components.Input
                    {...props}
                    aria-autocomplete="none"
                    autoComplete="off"
                    className="hide-autocomplete-safari"
                />
            </form>
        );
    };

    const getTruncatedLabel = (clientLabel: string) => {
        if (className?.includes('statsClientClass') && clientLabel.length > 20) {
            return `${clientLabel.slice(0, 25)}...`;
        }
        return clientLabel;
    };

    const truncatedLabel = selectedOptions ? getTruncatedLabel(selectedOptions.label) : placeholder;

    useEffect(() => {
        if (pathname.startsWith('/statistics/reports')) {
            setAreValuesDynamic(true);
        } else {
            setAreValuesDynamic(false);
        }
    }, [pathname]);

    return (
        <StyledSelectContainer
            width={width}
            labelVariant={isDarkTheme ? 'dark' : 'light'}
            className={'select-wrapper'}
            isDarkTheme={isDarkTheme}
        >
            <div className={`${className ?? ''}`}>
                {label ? <label className="labelNote">{label}</label> : <></>}
                {isCreatable ? (
                    <CreatableSelect
                        className={className}
                        classNamePrefix="select"
                        options={optionList}
                        onCreateOption={handleCreateOption}
                        onChange={handleSelect}
                        styles={colourStyles}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={isMultiple}
                        placeholder={truncatedLabel}
                        noOptionsMessage={() => t('pages.invoices.filters.noOptions').text}
                    />
                ) : (
                    <Select
                        classNamePrefix="select"
                        options={optionList}
                        onChange={handleSelect}
                        styles={colourStyles}
                        isClearable={false}
                        value={selectValue}
                        isSearchable={isSearchable === false ? false : true}
                        isMulti={isMultiple}
                        placeholder={truncatedLabel}
                        menuPlacement={positionConditional}
                        components={{ Input }}
                        noOptionsMessage={() => t('pages.invoices.filters.noOptions').text}
                    />
                )}
            </div>
        </StyledSelectContainer>
    );
};

const lightModeLabelStyles = `
color: var(--light-gray);
font-size: 13px;
padding: 5px;
line-height: 18px;
letter-spacing: -0.3px;
`;

const darkModeLabelStyles = `
font-size: 15px;
color: var(--gray);
margin-bottom: 2px;
&:hover {
    color: var(--purple);
}
`;

const StyledSelectContainer = styled.div<SelectProps>`
    text-align-last: left;
    display: flex;
    font-family: inherit;
    border-style: hidden;
    font-size: 15px;
    cursor: pointer;
    flex-direction: column;

    div .labelNote {
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
    }
    .select__placeholder {
        font-size: 15px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
    }
    .select-container {
        label {
            color: white;
        }
    }
    .select-employment-type {
        margin-bottom: 8px;
        label {
            margin-bottom: 10px;
            padding: 0px;
            font-size: 15px;
        }
        .select__control {
            background-color: var(--white) !important;
            box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
            border-radius: 0;
        }
    }
    .select-client {
        text-align: left;
    }

    .select-lang {
        z-index: 0;
        width: 105px;
        font-size: 12px !important;
        label {
            color: var(--gray);
            margin-bottom: 10px;
            padding: 0px;
            font-size: 12px;
        }
        .select__control {
            border-radius: 0;
            border: none;
            font-size: 12px;
            color: white;
        }
        .select__placeholder {
            font-size: 13px;
            color: white;
        }
        .select__value-container {
            padding: 0px;
        }
    }
    .select-month {
        .select__control {
            height: auto;
        }
    }
    .select-items {
        .select__control {
            height: auto;
        }
    }
    .einvoices {
        label {
            color: var(--gray);
            margin-bottom: 10px;
            padding: 0px;
            font-size: 15px;
        }
        .select__control {
            border-radius: 0;
            border: 1px solid var(--purple);
        }
        margin-bottom: 8px;
    }

    width: ${(props) => (props.width ? `${props.width}` : '200px')};
    label {
        /* ${(props) => (props.labelVariant === 'light' ? lightModeLabelStyles : darkModeLabelStyles)} dark theme */
    }
    &option {
        background-color: red;

        &--is-focused {
            background-color: green;
        }
    }
`;
export default SelectComponent;
