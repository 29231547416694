import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../components/tooltipMain';
import { formatTwoDecimals } from '../../../../functions/format';
import getCurrentLanguage from '../../../../functions/getCurrentLanguage';
import { breakpoints } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import colors from '../../../../global/colors';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';

interface Service {
    name_rs: string;
}

interface CardProps {
    data?: {
        created_at: string;
        description_en: string | null;
        description_rs: string | null;
        description_ru: string | null;
        id: string;
        name_en: string;
        name_rs: string;
        name_ru: string;
        services: {
            data: Service[];
        };
        type: string;
        updated_at: string;
        [key: string]: string | null | any;
    };
    orderPackage: Function;
    nextStep: Function;
    emptyCartError: boolean;
}

const ConsultationsCard: React.FC<CardProps> = ({ data, orderPackage, nextStep, emptyCartError }) => {
    // Initialize with null

    const allServices = data?.services.data.map((item: ObjectKeys) => ({
        ...item, // Spread existing properties
        quantity: 1, // Add quantity with a default value of 1
        type: 'service',
    }));
    const lang = getCurrentLanguage();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const { isLaptopAndDown } = useResponsive();

    return (
        <Card>
            <div className="cardTitle">
                <p>{data?.[`name_${lang}`]}</p>
            </div>

            <div className="courier-services">
                {allServices?.map((service: ObjectKeys, index: number) => {
                    // Define the hardcoded labels
                    const hardcodedLabels = [
                        t('pages.agency.extra_services.consultationsSerbian').text,
                        t('pages.agency.extra_services.consultationsEnglish').text,
                        t('pages.agency.extra_services.consultationsRussian').text,
                    ];

                    // Get the label based on the iteration index
                    const label = hardcodedLabels[index] || '';

                    return (
                        <div className="price-container" key={service.id}>
                            <div className="service-option">
                                <input
                                    type="radio"
                                    id={`service-${service.id}`}
                                    name="service"
                                    value={service.id}
                                    onChange={() => {
                                        orderPackage(service, data?.id);
                                        setSelectedOption(service.id);
                                    }}
                                />
                                <label
                                    htmlFor={`service-${service.id}`}
                                    className="textLabel"
                                    onClick={() => {
                                        orderPackage(service, data?.id);
                                        setSelectedOption(service.id);
                                    }}
                                >
                                    <p>{label}</p>
                                </label>
                            </div>
                            <span className="price">{formatTwoDecimals(service.price.toString())}</span>
                        </div>
                    );
                })}
            </div>
            <div className="error">
                {emptyCartError && <p>{t('pages.editInvoice.previewModal.selectConsultationPackage').text}</p>}
            </div>
            <div className="callToAction">
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => nextStep()} size={'80%'}>
                    {t('pages.agency.extra_services.consultationsOrder').text}
                </Button>
            </div>

            <div className="tooltip">
                <div className="tooltip">
                    <Tooltip
                        text={data?.[`description_${lang}`]}
                        isHtml={true}
                        position="custom"
                        show={data?.[`description_${lang}`]}
                        customPositioning={
                            isLaptopAndDown
                                ? { top: '50%', right: '100%', transform: 'translateY(-50%)' }
                                : { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
                        }
                        zIndex={10}
                        label={
                            <FontAwesomeIcon
                                icon={faCircleQuestion}
                                fontSize={'15px'}
                                style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                color={colors.purple}
                            />
                        }
                        customTooltipTextWidth={isLaptopAndDown ? '200px' : '300px'}
                    />
                </div>
            </div>
        </Card>
    );
};

const Card = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--purple);
    position: relative;
    box-sizing: border-box;
    flex-basis: 100%;

    @media (min-width: ${breakpoints.mobileL}px) {
        flex-basis: 90%;
    }

    @media (min-width: ${breakpoints.tablet}px) {
        flex-basis: 48%;
        align-items: normal;
    }

    @media (min-width: ${breakpoints.laptop}px) {
        flex-basis: 48%;
    }

    @media (min-width: ${breakpoints.laptopM}px) {
        flex-basis: 48%;
    }

    @media (min-width: ${breakpoints.laptopL}px) {
        flex-basis: 32%;
    }

    .cardTitle {
        font-weight: 600;
        font-size: 20px;
        min-height: 80px;

        display: flex;
        justify-content: center;
        p {
            font-weight: 600;
            font-size: 20px;
            max-width: 250px;
            margin-bottom: 20px;
            @media screen and (min-width: ${breakpoints.mobileM}px) {
                max-width: 70%;
            }
        }
    }

    .services {
        margin: 20px 0;
    }

    .price-container {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 5px 0;

        .price {
            flex-grow: 1;
            text-align: right;
            display: flex;
            min-width: 83px;

            @media screen and (min-width: ${breakpoints.mobileL}px) {
                min-width: none;
                width: 100px;
            }
            @media screen and (min-width: ${breakpoints.tablet}px) {
                min-width: none;
                width: auto;
            }
            @media screen and (min-width: ${breakpoints.laptop}px) {
                min-width: none;
                width: 100px;
            }
        }

        .price::before {
            content: '';
            background: repeating-linear-gradient(
                to right,
                currentColor,
                currentColor 1px,
                transparent 2px,
                transparent 4px
            );
            height: 2px;
            flex-grow: 1;
            display: inline-block;
            margin-top: 1em;
        }
    }

    .price-label {
        white-space: nowrap;
    }

    .dots {
        flex-grow: 1;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }

    .courier-services {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .service-option {
            display: flex;
            justify-content: center;
            align-items: baseline;
            max-width: none;
            cursor: pointer;

            p {
                font-size: 14px;
                padding-top: 0;
                @media screen and (min-width: ${breakpoints.tablet}px) {
                    padding-top: 5px;
                }
                @media screen and (min-width: ${breakpoints.laptop}px) {
                    padding-top: 0;
                }
            }

            .textLabel {
                max-width: 80%;
                display: flex;
                justify-content: center;
                /* align-items: center; */
                cursor: pointer;
            }
            @media screen and (min-width: ${breakpoints.mobileM}px) {
                align-items: center;

                .textLabel {
                    max-width: 100%;
                    white-space: nowrap;
                    display: flex;
                    justify-content: center;
                }
            }

            @media screen and (min-width: ${breakpoints.mobileL}px) {
                max-width: 70%;
            }

            @media screen and (min-width: ${breakpoints.tablet}px) {
                align-items: normal;
                .textLabel {
                    white-space: normal;
                }
            }
            @media screen and (min-width: ${breakpoints.laptop}px) {
                align-items: center;
                .textLabel {
                    white-space: nowrap;
                }
            }
        }

        .needCourier,
        .noService {
            display: flex;
            align-items: center;
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 25px;
            width: 20px;
            margin-right: 10px;
            cursor: pointer;

            @media screen and (min-width: ${breakpoints.mobileL}px) {
                height: 25px;
                width: 20px;
            }
        }
    }

    .callToAction {
        display: flex;
        justify-content: center;
        margin-top: auto;
    }

    .tooltip {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .error {
        margin-bottom: 5px;
        height: 12px;
        p {
            color: red;
            font-size: 12px;
            text-align: center;
        }
    }
`;

export default ConsultationsCard;
