import React from 'react';
import styled from 'styled-components';
import cancelImg from '../../../assets/cancel.png';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { breakpoints } from '../../../constants/breakpoints';

// Component
const PaymentSuccess: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const urlParams = new URLSearchParams(window.location.search);

    const userThemeChoice = localStorage.getItem('theme');
    const isDarkTheme = userThemeChoice === 'dark';

    const transactionDetails = {
        cart_id: urlParams.get('ShoppingCartID') || '',
        order_id: urlParams.get('WsPayOrderId') || '',
        date_time: urlParams.get('DateTime') || '',
        approval_code: urlParams.get('ApprovalCode') || null,
        amount: urlParams.get('Amount') || '',
    };

    return (
        <Container isDarkTheme={isDarkTheme}>
            <CancelText>{t('pages.payments.cancel.title').text}</CancelText>
            <CancelImage src={cancelImg} alt="Cancel" />
            <InfoText>{t('pages.payments.cancel.description').text}</InfoText>
            {transactionDetails ? (
                <div className="params">
                    <h3>{t('pages.payments.error.details').text}</h3>
                    <div className="error">
                        <p>{t('pages.payments.error.subDetails').text}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.order').text}:</p>
                        <p>{transactionDetails.cart_id}</p>
                    </div>
                </div>
            ) : null}
            <Link to="/dashboard">
                <span className="link">{t('pages.payments.cancel.button').text}</span>
            </Link>
        </Container>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

// Styled components
const Container = styled.div<DarkThemeProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : 'var(--light-gray)')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
    min-height: 100vh;
    .link {
        font-size: 1rem;
        cursor: pointer;
    }
    .params {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .error {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            color: var(--danger);
            p {
                font-size: 1rem;
            }
        }
        .param {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            p {
                font-size: 1rem;
            }
        }
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
        height: 111vh;
    }
    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
        padding: 0 50px;
    }
    @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
        padding: 0 30px;
    }
    @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
        padding: 0 10px;
    }
`;

const CancelText = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
`;

const CancelImage = styled.img`
    width: 200px;
    height: 200px;
    margin-bottom: 20px;

    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
        height: 150px;
        width: 150px;
    }
    @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
        height: 120px;
        width: 120px;
    }
`;

const InfoText = styled.p`
    font-size: 1rem;
    margin-bottom: 20px;
`;

export default PaymentSuccess;
