import React, { useEffect } from 'react';
import styled from 'styled-components';
import useTheme from '../../hooks/useTheme';

const DarkModeToggle: React.FC = () => {
    const isDarkTheme = useTheme();

    const toggleTheme = () => {
        const newTheme = isDarkTheme ? 'light' : 'dark';
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <ToggleWrapper>
            <ToggleCheckbox type="checkbox" id="darkmode-toggle" checked={isDarkTheme} onChange={toggleTheme} />
            <ToggleLabel htmlFor="darkmode-toggle" checked={isDarkTheme}>
                <MoonIcon className="moon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.39703 11.6315C3.39703 16.602 7.42647 20.6315 12.397 20.6315C15.6858 20.6315 18.5656 18.8664 20.1358 16.23C16.7285 17.3289 12.6922 16.7548 9.98282 14.0455C7.25201 11.3146 6.72603 7.28415 7.86703 3.89293C5.20697 5.47927 3.39703 8.38932 3.39703 11.6315ZM21.187 13.5851C22.0125 13.1021 23.255 13.6488 23 14.5706C21.7144 19.2187 17.4543 22.6315 12.397 22.6315C6.3219 22.6315 1.39703 17.7066 1.39703 11.6315C1.39703 6.58874 4.93533 2.25845 9.61528 0.999986C10.5393 0.751502 11.0645 1.99378 10.5641 2.80935C8.70026 5.84656 8.83194 10.0661 11.397 12.6312C13.9319 15.1662 18.1365 15.3702 21.187 13.5851Z"
                        fill={isDarkTheme ? '#fff' : '#7e7e7e'}
                    />
                </MoonIcon>
                <SunIcon className="sun" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                        stroke={isDarkTheme ? '#7e7e7e' : '#fff'}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </SunIcon>
            </ToggleLabel>
        </ToggleWrapper>
    );
};

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const ToggleCheckbox = styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
`;

const ToggleLabel = styled.label<{ checked: boolean }>`
    width: 4em;
    height: 2em;
    position: relative;
    display: block;
    background-color: ${({ checked }) => (checked ? '#242424' : '#ffffff')};
    border-radius: 2em;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.4), inset 0px -2px 5px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;

    &::after {
        content: '';
        width: 1.6em;
        height: 1.6em;
        position: absolute;
        top: 0.2em;
        left: ${({ checked }) => (checked ? '2.2em' : '0.2em')};
        transform: ${({ checked }) => (checked ? 'translateX(0)' : 'none')};
        background: var(--purple);
        border-radius: 50%;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
    }

    &:active::after {
        width: 2em;
    }
`;

const MoonIcon = styled.svg`
    position: absolute;
    width: 1.2em;
    height: 1.2em;
    top: 0.4em;
    left: 2.4em;
    z-index: 100;
    transition: 0.3s;
`;

const SunIcon = styled.svg`
    position: absolute;
    width: 1.2em;
    height: 1.2em;
    top: 0.4em;
    left: 0.4em;
    z-index: 100;
    transition: 0.3s;
`;

export default DarkModeToggle;
