import React from 'react';
import styled, { keyframes } from 'styled-components';

const DragAndDropSkeleton: React.FC = () => {
    return (
        <SkeletonContent>
            <div className="tooltip-wrapper">
                <SkeletonIcon className="skeleton-icon" />
            </div>
            <div className="section info">
                <SkeletonFileName className="skeleton-file-name" />
                <SkeletonDate className="skeleton-date" />
            </div>
            <div className="trash-wrapper">
                <SkeletonTrashIcon className="skeleton-trash-icon" />
            </div>
        </SkeletonContent>
    );
};

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const SkeletonContent = styled.div`
    background: var(--moonlight);
    border-radius: 10px;
    padding: 20px;
    border-top: 1px solid #e1e3eb;
    padding-top: 25px;
    margin-top: 25px;
    display: table;
    width: 100%;

    .tooltip-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .info {
        margin: 0;
        line-height: 22px;
        margin-left: 16px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 10px 30px 20px;
    }

    .trash-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
`;

const SkeletonIcon = styled.div`
    width: 80px;
    height: 80px;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 5px;
`;

const SkeletonFileName = styled.div`
    width: 70%;
    height: 20px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonDate = styled.div`
    width: 50%;
    height: 15px;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonTrashIcon = styled.div`
    width: 24px;
    height: 24px;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 5px;
`;

export default DragAndDropSkeleton;
