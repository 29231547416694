import { FunctionComponent, useEffect, useState } from 'react';
import DragAndDrop from './dragAndDrop';
import styled from 'styled-components';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import moment from 'moment';
import useAgencyStore from '../../../../store/agency';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import useTheme from '../../../../hooks/useTheme';

type SelectOption = {
    value: string;
    label: string;
};
const EcotaxSolution: FunctionComponent = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [yearsForSearch, setYearsForSearch] = useState<Array<string>>([]);
    const { isMobile } = useResponsive();
    const agency = useAgencyStore((props) => props.agency);

    const [searchYear, setSearchYear] = useState<SelectOption>({
        value: new Date().getFullYear().toString(),
        label: new Date().getFullYear().toString(),
    });

    const isDarkTheme = useTheme();

    useEffect(() => {
        const registartionYear = moment(agency.date_of_registration).year();
        const currentYear = moment().year();
        for (let i = currentYear; i >= registartionYear; i--) {
            setYearsForSearch((prevState) => [...prevState, i.toString()]);
        }
    }, []);

    useEffect(() => {
        if (yearsForSearch[tabIndex]) {
            setSearchYear({ value: yearsForSearch[tabIndex]?.toString(), label: yearsForSearch[tabIndex]?.toString() });
        }
    }, [tabIndex]);

    const handleTabIndex = (year: string): void => {
        const index = yearsForSearch.findIndex((item) => item === year);
        setTabIndex(index);
    };

    return (
        <Content className={isMobile ? 'mobile' : 'desktop'} isDarkTheme={isDarkTheme}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {yearsForSearch.map((tab, index) => (
                        <Tab className={'custom-tab'} key={index}>
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                {yearsForSearch.map((tab, index) => (
                    <TabPanel key={index}>
                        <DragAndDrop
                            className={'col-12'}
                            fileTypes={['jpg', 'png', 'doc', 'docx', 'pdf', 'jpeg']}
                            type={'tax'}
                            multiple={true}
                            searchYear={tab}
                            taxCurrentYear={searchYear}
                            updateSearchYear={(year: string) => handleTabIndex(year)}
                            preventUpload={false}
                        />
                    </TabPanel>
                ))}
            </Tabs>
        </Content>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Content = styled.div<DarkThemeProps>`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 14.27%;
            @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: fit-content;
            }
        }
    }
    .react-tabs__tab--selected {
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#f7f7f7')};
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#000000')};
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
`;
export default EcotaxSolution;
