import React, { useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../components/tooltipMain';
import { formatTwoDecimals } from '../../../../functions/format';
import getCurrentLanguage from '../../../../functions/getCurrentLanguage';
import { breakpoints } from '../../../../constants/breakpoints';
import colors from '../../../../global/colors';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useTheme from '../../../../hooks/useTheme';

interface Service {
    name_rs: string;
}

interface CardProps {
    data?: {
        created_at: string;
        description_en: string | null;
        description_rs: string | null;
        description_ru: string | null;
        id: string;
        name_en: string;
        name_rs: string;
        name_ru: string;
        services: {
            data: Service[];
        };
        type: string;
        updated_at: string;
        [key: string]: string | null | any;
    };
    orderPackage: Function;
    nextStep: Function;
    userPackage: ObjectKeys | boolean;
    emptyCartError: boolean;
}

const ConsultationsCard: React.FC<CardProps> = ({ data, orderPackage, nextStep, userPackage, emptyCartError }) => {
    const allServices = data?.services.data.map((item: ObjectKeys) => ({
        ...item, // Spread existing properties
        quantity: 1, // Add quantity with a default value of 1
        type: 'service',
    }));
    const lang = getCurrentLanguage();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const { isLaptopAndDown } = useResponsive();

    const isDarkTheme = useTheme();
    const formattedPrice = (service: ObjectKeys): string => {
        const isTrialAndInactive =
            !userPackage || typeof userPackage === 'boolean'
                ? true // Default to trial user if userPackage is missing or invalid
                : false;

        const user_service_discount_percentage =
            typeof userPackage === 'object' && userPackage.services_discount ? userPackage.services_discount : 0;

        const freeServices =
            typeof userPackage === 'object' && userPackage.services?.data
                ? userPackage.services.data.map((s: any) => s.id)
                : [];

        const isFreeService = freeServices.includes(service.id);

        const finalPrice = isTrialAndInactive
            ? isFreeService
                ? 0
                : service.price
            : isFreeService
            ? 0
            : service.price * (1 - user_service_discount_percentage / 100);

        return formatTwoDecimals(finalPrice.toString());
    };

    return (
        <Card isDarkTheme={isDarkTheme}>
            <div className="cardTitle">{data?.[`name_${lang}`]}</div>

            <div className="courier-services">
                {allServices?.map((service: ObjectKeys, index: number) => {
                    // Define the hardcoded labels
                    const hardcodedLabels = [
                        t('pages.agency.extra_services.consultationsSerbian').text,
                        t('pages.agency.extra_services.consultationsEnglish').text,
                        t('pages.agency.extra_services.consultationsRussian').text,
                    ];

                    // Get the label based on the iteration index
                    const label = hardcodedLabels[index] || '';

                    return (
                        <div className="price-container" key={service.id}>
                            <div className="service-option">
                                <input
                                    type="radio"
                                    id={`service-${service.id}`}
                                    name="service"
                                    value={service.id}
                                    onChange={() => {
                                        orderPackage(service, data?.id);
                                        setSelectedOption(service.id);
                                    }}
                                />
                                <label
                                    htmlFor={`service-${service.id}`}
                                    className="textLabel"
                                    onClick={() => {
                                        orderPackage(service, data?.id);
                                        setSelectedOption(service.id);
                                    }}
                                >
                                    <p>{label}</p>
                                </label>
                            </div>
                            <span className="price">{formattedPrice(service)}</span>
                        </div>
                    );
                })}
            </div>
            <div className="error">
                {emptyCartError && !selectedOption && (
                    <p>{t('pages.editInvoice.previewModal.selectConsultationPackage').text}</p>
                )}
            </div>
            <div className="callToAction">
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => nextStep()} size={'80%'}>
                    {t('pages.agency.extra_services.consultationsOrder').text}
                </Button>
            </div>

            <div className="tooltip">
                <Tooltip
                    text={data?.[`description_${lang}`]}
                    isHtml={true}
                    position="custom"
                    show={data?.[`description_${lang}`]}
                    customPositioning={
                        isLaptopAndDown
                            ? { top: '50%', right: '100%', transform: 'translateY(-50%)' }
                            : { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
                    }
                    zIndex={10}
                    label={
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            fontSize={'15px'}
                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                            color={colors.purple}
                        />
                    }
                    customTooltipTextWidth={isLaptopAndDown ? '200px' : '300px'}
                />
            </div>
        </Card>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Card = styled.div<DarkThemeProps>`
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--black);
    position: relative;
    box-sizing: border-box;
    flex-basis: 100%;
    border: 1px solid var(--purple);
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'white')};

    @media (min-width: ${breakpoints.mobileL}px) {
        flex-basis: 90%;
    }

    @media (min-width: ${breakpoints.tablet}px) {
        flex-basis: 48%;
    }

    @media (min-width: ${breakpoints.laptop}px) {
        flex-basis: 48%;
    }

    @media (min-width: ${breakpoints.laptopM}px) {
        flex-basis: 48%;
    }

    @media (min-width: ${breakpoints.laptopL}px) {
        flex-basis: 32%;
    }

    .cardTitle {
        font-weight: 600;
        font-size: 20px;
        min-height: 80px;

        display: flex;
        justify-content: center;

        margin: 20px 0;
        height: 120px;

        @media screen and (min-width: ${breakpoints.mobileS}px) {
            height: 95px;
        }
        @media screen and (min-width: ${breakpoints.tablet}px) {
            height: 75px;
        }
        p {
            font-weight: 600;
            font-size: 20px;
            max-width: 250px;
            margin-bottom: 20px;
        }
    }

    .services {
        margin: 20px 0;
    }

    .price-container {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 5px 0;

        .price {
            flex-grow: 1;
            text-align: right;
            display: flex;
            min-width: 83px;

            @media screen and (min-width: ${breakpoints.mobileL}px) {
                min-width: none;
                width: 100px;
            }
        }

        .price::before {
            content: '';
            background: repeating-linear-gradient(
                to right,
                currentColor,
                currentColor 1px,
                transparent 2px,
                transparent 4px
            );
            height: 2px;
            flex-grow: 1;
            display: inline-block;
            margin-top: 1em;
        }
    }

    .price-label {
        white-space: nowrap;
    }

    .dots {
        flex-grow: 1;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }

    .courier-services {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .service-option {
            display: flex;
            justify-content: center;
            align-items: baseline;
            max-width: none;
            cursor: pointer;

            .textLabel {
                max-width: 80%;
                display: flex;
                justify-content: center;
                /* align-items: center; */
            }

            @media screen and (min-width: ${breakpoints.mobileM}px) {
                align-items: center;

                .textLabel {
                    max-width: 100%;
                    white-space: nowrap;
                    display: flex;
                    justify-content: center;
                }
            }

            @media screen and (min-width: ${breakpoints.mobileL}px) {
                max-width: 70%;
            }

            @media screen and (min-width: ${breakpoints.tablet}px) {
                align-items: normal;
                p {
                    padding-top: 5px;
                    font-size: 14px;
                }
                .textLabel {
                    white-space: normal;
                }
            }
            @media screen and (min-width: ${breakpoints.laptop}px) {
                align-items: center;
                p {
                    font-size: 14px;
                    padding-top: 0;
                }
                /* .textLabel {
                    white-space: nowrap;
                } */
            }
        }

        .needCourier,
        .noService {
            display: flex;
            align-items: center;
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 20px;
            width: 15px;
            margin-right: 10px;

            @media screen and (min-width: ${breakpoints.mobileM}px) {
                height: 25px;
                width: 20px;
            }
        }
    }

    .callToAction {
        display: flex;
        justify-content: center;
        margin-top: auto;
    }

    .tooltip {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .error {
        margin-bottom: 5px;
        height: 12px;
        p {
            color: red;
            font-size: 12px;
            text-align: center;
        }
    }
`;

export default ConsultationsCard;
