import React, { FunctionComponent } from 'react';
import { ObjectKeys } from '@/types/objectKeys';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUser, faCalendarAlt, faClock, faTrash } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../../communication';
import { getAccessToken } from '../../../../functions/auth';
import useAllUsersStore from '../../../../store/admin/allUsers';

type SolutionType = {
    solution: ObjectKeys;
    showEmail: boolean;
    type: 'tax' | 'ecotax';
    deleteSolution: (id: string) => void;
};

const AdminSolutionCard: FunctionComponent<SolutionType> = ({ solution, showEmail, type, deleteSolution }) => {
    const token = getAccessToken();
    const { allUsers } = useAllUsersStore();

    const getUserByID = (id: string): string => {
        const user = allUsers.find((useR: any) => useR.id === id);
        if (user) {
            return `${user.email} - ${user.pib}`;
        } else {
            return '';
        }
    };

    const downloadSolution = (): void => {
        if (type === 'tax') {
            communication.downloadSolution(solution.id, token);
        } else {
            communication.downloadEcotaxSolution(solution.id, token);
        }
    };
    return (
        <Card>
            <span className={'title'} onClick={() => downloadSolution()}>
                <FontAwesomeIcon icon={faFile} className={'icon'} />
                {solution.file_name}
            </span>
            {showEmail && (
                <span className={'email'}>
                    <FontAwesomeIcon icon={faUser} className={'icon'} />
                    {getUserByID(solution?.user_id)}
                </span>
            )}

            <span className={'date'}>
                <FontAwesomeIcon icon={faCalendarAlt} className={'icon'} />
                {moment(solution.created_at).format('DD. MM. YYYY')}
            </span>

            <span className={'year'}>
                <FontAwesomeIcon icon={faClock} className={'year-icon'} /> {/* Example icon, replace if needed */}
                {solution.year}
            </span>
            <span className={'delete'}>
                <FontAwesomeIcon icon={faTrash} className={'icon'} onClick={() => deleteSolution(solution.id)} />
            </span>
        </Card>
    );
};

const Card = styled.div`
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    background-color: var(--white);
    padding: 0 15px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

    span {
        display: inline-block;
    }

    .title {
        flex-grow: 3;
        color: var(--purple);
        cursor: pointer;
        transition: color 0.1s ease-in-out;
    }

    .email {
        margin-right: 10px;
        color: var(--gray);
        flex-grow: 0;
        transition: color 0.1s ease-in-out;
    }
    .date {
        color: var(--gray);
        flex-grow: 0;
        transition: color 0.1s ease-in-out;
    }

    .icon {
        margin-right: 10px;
        transition: color 0.1s ease-in-out;
    }

    .year-icon {
        margin-right: 3px;
        color: var(--purple);
        transition: color 0.1s ease-in-out;
    }

    .year {
        display: flex;
        align-items: center;
        margin-left: 30px;
    }
    .delete {
        margin-left: 15px;
        color: var(--danger);
        transition: color 0.1s ease-in-out;
        cursor: pointer;
    }

    &:hover {
        background-color: var(--purple);

        .title,
        .email,
        .date,
        .year,
        .year-icon {
            color: var(--white);
        }
    }
`;

export default AdminSolutionCard;
