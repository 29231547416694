import React from 'react';
import styled from 'styled-components';
import useTheme from '../../../../hooks/useTheme';
interface TooltipProps {
    text: string;
    position: 'top' | 'right' | 'bottom' | 'left' | 'hardLeft' | 'alternateLeft' | 'custom';
    label: React.ReactNode;
    isHtml?: boolean;
    show?: boolean;
    zIndex?: number;
    customPositioning?: { top?: string; right?: string; bottom?: string; left?: string; transform?: string };
    refProps?: React.RefObject<HTMLDivElement>;
}
const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;
const TooltipText = styled.span<{
    position: 'top' | 'right' | 'bottom' | 'left' | 'hardLeft' | 'alternateLeft' | 'custom';
    zIndex?: number;
    customPositioning?: { top?: string; right?: string; bottom?: string; left?: string; transform?: string };
    isDarkTheme?: boolean;
}>`
    visibility: hidden;
    height: auto;
    width: 200px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
    color: var(--gray);
    text-align: center;
    border: 3px solid var(--purple);
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: ${({ zIndex }) => zIndex || 1}; /* Apply zIndex with a default value of 1 */
    /* Positioning logic based on props */
    ${({ position }) =>
        position === 'top' &&
        `
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    `}
    ${({ position }) =>
        position === 'right' &&
        `
        top: 50%;
        left: 100%;
        margin-left: 10px;
        transform: translateY(-50%);
    `}
    ${({ position }) =>
        position === 'bottom' &&
        `
        top: 100%;
        left: 50%;
        margin-top: 10px;
        transform: translateX(-50%);
    `}
    ${({ position }) =>
        position === 'left' &&
        `
        top: 50%;
        right: 100%;
        margin-right: 10px;
        transform: translateY(-50%);
    `}
    ${({ position }) =>
        position === 'hardLeft' &&
        `
        top: 50%;
        left: -180px;
        margin-right: 10px;
        transform: translateY(-50%);
    `}
    ${({ position }) =>
        position === 'alternateLeft' &&
        `
        top: 11%;
        left: -190px;
        margin-right: 10px;
        transform: translateY(-50%);
    `}
    ${({ position, customPositioning }) =>
        position === 'custom' &&
        customPositioning &&
        `
        top: ${customPositioning.top || 'auto'};
        left: ${customPositioning.left || 'auto'};
        right: ${customPositioning.right || 'auto'};
        bottom: ${customPositioning.bottom || 'auto'};
        transform: ${customPositioning.transform}
    `}
`;
const TooltipLabel = styled.span`
    ${TooltipText} {
        visibility: visible;
    }
`;
const Tooltip: React.FC<TooltipProps> = ({
    text,
    position,
    label,
    isHtml,
    show = true,
    zIndex,
    customPositioning,
    refProps,
}) => {
    const isDarkTheme = useTheme();
    return (
        <TooltipContainer>
            <TooltipLabel>
                {label}
                {show ? (
                    <TooltipText
                        ref={refProps}
                        position={position}
                        customPositioning={customPositioning}
                        zIndex={zIndex}
                        isDarkTheme={isDarkTheme}
                    >
                        {isHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text}
                    </TooltipText>
                ) : null}
            </TooltipLabel>
        </TooltipContainer>
    );
};
export default Tooltip;
