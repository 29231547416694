import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faE,
    faMinusCircle,
    faCircleCheck,
    faPenToSquare,
    faPlus,
    faRefresh,
    faA,
    faBell,
    faEraser,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import { ObjectKeys } from '../../types/objectKeys';

const useDropdown = (
    invoice: ObjectKeys,
    type: string,
    dropdown: string,
    handleChange?: Function,
    handleCancel?: Function,
    handleCopy?: Function,
    handleDelete?: Function,
    handleSend?: Function,
    handleReminder?: Function,
    handlePayment?: Function,
    handleDownload?: Function,
    handleEInvoice?: Function,
    handleInvoice?: Function,
    handleAdvance?: Function,
    handleRefresh?: Function,
    is_einvoice?: boolean,
    is_advance?: boolean,
    paymentInfo?: Function,
): any => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    // const eAdvanceWithPrepaids = invoice.is_einvoice && invoice?.prepaid_invoices?.length > 0 ? false : true;

    // const isCanceledPaidInvoice = invoice.status === 'paid' && invoice.cancelled ? false : true;

    const isAdvance = invoice.is_prepaid ? true : false;
    const isCanceled = invoice.cancelled ? true : false;

    switch (dropdown) {
        case 'sent':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                !is_einvoice && {
                    icon: faPenToSquare,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.change').text,
                    handler: handleChange,
                },
                {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                is_einvoice && invoice?.eInvoice?.data?.status === 'Sending'
                    ? null
                    : {
                          icon: faMinusCircle,
                          iconColor: colors.gray,
                          label: t('pages.invoiceCard.dropdownItems.cancel').text,
                          handler: handleCancel,
                      },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
                !isAdvance
                    ? {
                          icon: faCircleCheck,
                          iconColor: colors.green,
                          label: t('pages.invoiceCard.dropdownItems.payment').text,
                          handler: handlePayment,
                      }
                    : {},
                invoice.type !== 'foreign' && {
                    icon: faE,
                    iconColor: invoice.is_einvoice === 1 ? colors.gray : colors.green,
                    label: !isAdvance
                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                        : t('pages.invoiceCard.dropdownItems.eAdvance').text,
                    handler: handleEInvoice,
                },
                !isAdvance
                    ? {
                          icon: faBell,
                          iconColor: colors.purple,
                          label: t('pages.invoices.sendModal.send_reminder').text,
                          handler: handleReminder,
                      }
                    : {},
            ];
        case 'paid':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                !is_einvoice &&
                    !isCanceled && {
                        icon: faPenToSquare,
                        iconColor: colors.blue,
                        label: t('pages.invoiceCard.dropdownItems.change').text,
                        handler: handleChange,
                    },
                !is_einvoice && {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
                invoice.type !== 'foreign' && {
                    icon: faE,
                    iconColor: invoice.is_einvoice === 1 ? colors.gray : colors.green,
                    label: !isAdvance
                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                        : t('pages.invoiceCard.dropdownItems.eAdvance').text,
                    handler: handleEInvoice,
                },
                !isCanceled &&
                    paymentInfo && {
                        icon: faInfoCircle,
                        iconColor: colors.purple,
                        label: t('pages.invoiceCard.dropdownItems.info').text,
                        handler: paymentInfo,
                    },
            ];
        case 'draft':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                {
                    icon: faPenToSquare,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.change').text,
                    handler: handleChange,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
            ];
        case 'partially':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                !is_einvoice && {
                    icon: faPenToSquare,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.change').text,
                    handler: handleChange,
                },
                {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
                !isAdvance
                    ? {
                          icon: faCircleCheck,
                          iconColor: colors.green,
                          label: t('pages.invoiceCard.dropdownItems.payment').text,
                          handler: handlePayment,
                      }
                    : {},
                invoice.type !== 'foreign' && {
                    icon: faE,
                    iconColor: invoice.is_einvoice === 1 ? colors.gray : colors.green,
                    label: !isAdvance
                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                        : t('pages.invoiceCard.dropdownItems.eAdvance').text,
                    handler: handleEInvoice,
                },
                !isCanceled &&
                    paymentInfo && {
                        icon: faInfoCircle,
                        iconColor: colors.purple,
                        label: t('pages.invoiceCard.dropdownItems.info').text,
                        handler: paymentInfo,
                    },
            ];
        case 'cancelled':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
                is_einvoice && {
                    icon: faE,
                    iconColor: colors.gray,
                    label: t('pages.invoiceCard.dropdownItems.eAdvance').text,
                },
            ];
        case 'proforma':
            return [
                {
                    icon: faPenToSquare,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.change').text,
                    handler: handleChange,
                },
                {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                {
                    icon: faPlus,
                    iconColor: colors.green,
                    label: t('pages.invoiceCard.dropdownItems.invoice').text,
                    handler: handleInvoice,
                },
                {
                    icon: faA,
                    iconColor: colors.brown,
                    label: t('pages.advanceInvoices.single').text,
                    handler: handleAdvance,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
            ];
        case 'closed':
            return [
                is_einvoice && {
                    icon: faRefresh,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.refresh').text,
                    handler: handleRefresh,
                },
                {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
                {
                    icon: faClone,
                    iconColor: colors.purple,
                    label: t('pages.invoiceCard.dropdownItems.copy').text,
                    handler: handleCopy,
                },
                {
                    icon: faEnvelope,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.send').text,
                    handler: handleSend,
                },
            ];
        case 'admin':
            return [
                invoice.status !== 'cancelled' && {
                    icon: faPenToSquare,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.change').text,
                    handler: handleChange,
                },
                !is_einvoice && {
                    icon: faTrashCan,
                    iconColor: colors.danger,
                    label: t('pages.invoiceCard.dropdownItems.delete').text,
                    handler: handleDelete,
                },
                invoice.status !== 'open' && {
                    icon: faDownload,
                    iconColor: colors.blue,
                    label: t('pages.invoiceCard.dropdownItems.download').text,
                    handler: handleDownload,
                },
            ];
    }
};
export default useDropdown;
