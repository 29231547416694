import { FunctionComponent, useEffect, useState } from 'react';
import WebNavbar from './web';
import MobileNavbar from './mobile';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../modal';
import Suggestion from './suggestion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAgencyStore from '../../store/agency';
import { activeUserNavigation, adminNavigation, userNavigation } from '../../constants/routes';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../constants/breakpoints';

const Navbar: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const role = localStorage.getItem('role');

    const { agency } = useAgencyStore();
    const isTrial = role !== 'admin' ? agency.user.data.trial : false;
    const userPackage =
        role !== 'admin' ? (isTrial ? t('navbar.main.trial').text : agency.user.data.payment_package.data.name) : '';

    const expires = role !== 'admin' ? agency.user.data.account_expiration_date : '';
    const [suggestionModal, setSuggestionModal] = useState(false);

    const isAccountActive = role !== 'admin' ? agency.user.data.active : true;

    const navItemsData = role === 'admin' ? adminNavigation : isAccountActive ? activeUserNavigation : userNavigation;

    const isLaptopWidth = useMediaQuery({
        query: `(max-width: ${breakpoints.laptop - 1}px)`,
    });

    const userTheme = localStorage.getItem('theme');
    document.documentElement.setAttribute('data-theme', userTheme ? userTheme : 'light');

    return (
        <>
            <ToastContainer />
            {suggestionModal && (
                <Modal modalVisible={true} closeModal={() => setSuggestionModal(false)}>
                    <Suggestion close={() => setSuggestionModal(false)} />
                </Modal>
            )}

            {!isLaptopWidth ? (
                <WebNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    expires={expires}
                    role={role}
                    navItems={navItemsData}
                    isAccountActive={isAccountActive}
                    packageName={userPackage}
                />
            ) : (
                <MobileNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    role={role}
                    navItems={navItemsData}
                    expires={expires}
                    isAccountActive={isAccountActive}
                    packageName={userPackage}
                />
            )}
        </>
    );
};

export default Navbar;
