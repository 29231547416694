import styled from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useTheme from '../../../../hooks/useTheme';

type FilterTrackProps = {
    onChevronClick: (name: string, order: string) => void;
    t: Function;
    customFilterStyling?: boolean;
};

const FilterTrack: React.FC<FilterTrackProps> = ({ onChevronClick, t, customFilterStyling }) => {
    const isDarkTheme = useTheme();
    return (
        <StyledFilterTrack customFilterStyling={customFilterStyling} isDarkTheme={isDarkTheme}>
            <div className="name-col">
                <p>{t('pages.invoiceCard.infoWrapper.name').text}</p>
                <p className="chevrons">
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        color="white"
                        onClick={() => onChevronClick('clients|company_name', 'asc')}
                    />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        color="white"
                        onClick={() => onChevronClick('clients|company_name', 'desc')}
                    />
                </p>
            </div>
            <div className="prefix-col">
                <p>{t('pages.invoiceCard.infoWrapper.prefix').text}</p>
                <p className="chevrons">
                    <FontAwesomeIcon icon={faChevronUp} color="white" onClick={() => onChevronClick('prefix', 'asc')} />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        color="white"
                        onClick={() => onChevronClick('prefix', 'desc')}
                    />
                </p>
            </div>
            <div className="number-col">
                <p>{t('pages.invoiceCard.infoWrapper.invoice_number').text}</p>
                <p className="chevrons">
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        color="white"
                        onClick={() => onChevronClick('invoice_number', 'asc')}
                    />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        color="white"
                        onClick={() => onChevronClick('invoice_number', 'desc')}
                    />
                </p>
            </div>
            <div className="date-col">
                <p>{t('pages.invoiceCard.infoWrapper.date').text}</p>
                <p className="chevrons">
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        color="white"
                        onClick={() => onChevronClick('invoice_date;created_at', 'asc;asc')}
                    />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        color="white"
                        onClick={() => onChevronClick('invoice_date;created_at', 'desc;desc')}
                    />
                </p>
            </div>
            <div className="amount-col">
                <p>{t('pages.invoiceCard.infoWrapper.amount').text}</p>
                <p className="chevrons">
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        color="white"
                        onClick={() => onChevronClick('value_in_rsd', 'asc')}
                    />
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        color="white"
                        onClick={() => onChevronClick('value_in_rsd', 'desc')}
                    />
                </p>
            </div>
            <div className="action-wrapper"></div>
        </StyledFilterTrack>
    );
};

interface StyledFilterTrackProps {
    customFilterStyling?: boolean;
    isDarkTheme: boolean;
}

const StyledFilterTrack = styled.div<StyledFilterTrackProps>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr 2fr;
    padding: 5px 20px;
    padding-left: 50px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    margin-bottom: 10px;

    ${({ customFilterStyling }) =>
        customFilterStyling &&
        `
    padding-left: 20px;
  `}

    .name-col {
        grid-column: span 1;
        margin-right: 160px;
    }

    .prefix-col,
    .number-col {
        padding-left: 20px;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: flex-start;

        p {
            margin: 0;
            font-size: 12px;
        }

        .chevrons {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                color: white;
                margin-left: 8px;
                font-size: 12px;
                cursor: pointer;
                transition: transform 0.2s ease-in-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .action-wrapper {
        display: flex;
        justify-content: flex-end;

        div {
            &.action {
                justify-content: center;
                margin-top: 20px;
            }
        }
    }

    /* Responsive styles */
    /* test */

    @media screen and (min-width: 769px) {
        display: flex;
        .name-col {
            width: 20%;
            margin-right: 0;
        }

        .prefix-col {
            width: 15%;
            padding-left: 0;
        }

        .number-col {
            width: 15%;
            padding-left: 0;
        }

        .date-col {
            width: 15%;
        }

        .amount-col {
            width: 10%;
        }

        /* .mocking,
        .mockingTwo {
            width: 5%;
        } */

        .action-wrapper {
            width: 25%;
        }
    }

    @media screen and (min-width: 1601px) {
        .prefix-col {
            flex: 1;
        }

        .number-col {
            flex: 1;
        }

        .date-col {
            flex: 1;
        }

        .amount-col {
            flex: 1;
        }
        .action-wrapper {
            width: 450px;
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 1fr;

        /* .name-col {
            margin-right: 0;
        }

        .prefix-col {
            padding-left: 20px;
        }

        .number-col {
            padding-left: 28px;
        }

        .date-col {
            padding-left: 38px;
        }

        .amount-col {
            padding-left: 51px;
        } */
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        .name-col {
            /* width: 215px; */
            margin-right: 0;
        }

        .prefix-col {
            /* padding-left: 29px; */
        }

        .number-col {
            /* padding-left: 20px; */
        }

        .date-col {
            /* padding-left: 20px; */
        }

        .amount-col {
            /* padding-left: 9px; */
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: 2559px) {
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.desktopL}) {
        .name-col {
            /* width: 410px; */
            margin-right: 0;
        }

        .prefix-col,
        .number-col {
            /* width: 225px; */
        }

        .date-col {
            /* width: 220px; */
            /* padding-left: 13px; */
        }

        .prefix-col {
            /* padding-left: 39px; */
        }

        .number-col {
            /* padding-left: 26px; */
        }
    }

    /* @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 1fr;
        .name-col {
            width: 600px;
        }

        .prefix-col {
            padding-left: 38px;
        }

        .number-col {
            padding-left: 0;
            padding-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .date-col {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 7px;
        }

        .amount-col {
            padding-left: 110px;
        }
    } */

    /* Adjust for smaller screens */

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        display: flex;
        justify-content: space-around;
        padding: 5px 0px;

        div {
            display: inline-block;
        }

        .name-col {
            margin-right: 0;
        }

        .prefix-col,
        .number-col {
            padding-left: 0;
        }

        .action-wrapper {
            display: none;
        }

        .chevrons {
            svg {
                margin-left: 0 !important;
            }
        }
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        display: flex;
        justify-content: space-around;
        padding: 5px 0px;

        div {
            display: inline-block;
        }

        .name-col {
            width: auto;
        }
    }
`;
export default FilterTrack;
