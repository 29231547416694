import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { Accordion } from '../../components/accordion';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import getInstructions from './data';
import Search from '../../components/input/search';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../global/colors';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useTheme from '../../hooks/useTheme';

const Instructions = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const defaultLang = lang === 'Srpski' ? 'rs' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'rs';

    const [searchTerm, setSearchTerm] = useState('');
    const instructionsByCategory = getInstructions(t);
    const [openCategories, setOpenCategories] = useState<string[]>([]);

    const isDarkTheme = useTheme();

    const toggleCategory = (categoryName: string): void => {
        setOpenCategories(
            (prevCategories) =>
                prevCategories.includes(categoryName)
                    ? prevCategories.filter((cat) => cat !== categoryName) // Close category
                    : [...prevCategories, categoryName], // Open category
        );
    };

    const filteredInstructions = useMemo(() => {
        return instructionsByCategory.map((category) => ({
            ...category,
            instructions: category.instructions.filter((instruction) =>
                instruction?.title?.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
        }));
    }, [instructionsByCategory, searchTerm]);

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const matchingCategories = filteredInstructions
                .filter((category) => category.instructions.length > 0)
                .map((category) => category.categoryName);
            setOpenCategories(matchingCategories);
        } else {
            setOpenCategories([]);
        }
    }, [searchTerm]);

    return (
        <Container isDarkTheme={isDarkTheme}>
            <Header isDarkTheme={isDarkTheme}>
                <h1>{t('navbar.pausal.instructions').text}</h1>
            </Header>
            <SubHeader isDarkTheme={isDarkTheme}>
                <label className="second-title">{`${t('pages.instructions.subtitle').text}`}</label>
            </SubHeader>
            <Filters>
                <div className="filter-bottom">
                    <div className="filter-search">
                        <Search
                            placeholder={t('pages.memos.filters.search').text}
                            onChange={(value: string) => {
                                setSearchTerm(value);
                            }}
                            noteColor={'#fff'}
                            className="select-search-filter"
                        />
                    </div>
                </div>
            </Filters>
            <InstructionsWrapper>
                {filteredInstructions.map((category) => (
                    <div key={category.categoryName}>
                        <CategoryTitle onClick={() => toggleCategory(category.categoryName)} isDarkTheme={isDarkTheme}>
                            <FontAwesomeIcon
                                className={'caret'}
                                color={colors.purple}
                                icon={openCategories.includes(category.categoryName) ? faChevronUp : faChevronDown} // Use openCategories
                            />
                            <p>{category.categoryName}</p>
                        </CategoryTitle>
                        {openCategories.includes(category.categoryName) && (
                            <Accordion instructions={category.instructions} lang={defaultLang} />
                        )}
                    </div>
                ))}
            </InstructionsWrapper>
        </Container>
    );
};

export default Instructions;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const CategoryTitle = styled.div<DarkThemeProps>`
    display: flex;
    padding: 1rem;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white);')};
    border-bottom: 1px solid var(--border-color);
    color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#4c595f')};
    p {
        font-size: 16px;
    }
    cursor: pointer;
    margin: 10px 0;
    .caret {
        margin-right: 10px;
        padding-right: 15px;
    }
`;

const Container = styled.div<DarkThemeProps>`
    vertical-align: top;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#4c595f')};

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const Header = styled.div<DarkThemeProps>`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    width: 100%;

    h1 {
        margin: 0;
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4C595F')};
    }
`;

const InstructionsWrapper = styled.div`
    margin-bottom: 20px;
`;

const SubHeader = styled.div<DarkThemeProps>`
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    text-transform: uppercase;
`;

const Filters = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 0;

    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .filter-search {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: inline-block;
        }
    }
`;
