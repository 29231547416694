import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Package from './adminServiceCard/';
import Sidebar from '../../../../components/sideModal';
import EditModal from '../common/editModal';
import CreateModal from '../common/createModal';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../../components/loader';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import Button, { ButtonVariant } from '../../../../components/button';
// import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import communication from '../../../../communication';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { useBackOfficePasswordProtect } from '../../../../hooks/backOfficePasswordProtect';

const Packages: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);
    const [editModal, setEditModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<ObjectKeys>({});
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const params = {
        page: 1,
        limit: 100,
        search: 'type:consultation;',
        searchFields: 'type:=;',
        searchJoin: 'and',
    };
    useBackOfficePasswordProtect();

    const handleSelectedPackage = (item: ObjectKeys): void => {
        setSelectedPackage(item);
    };

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                setPackages(response.data.data);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const onDelete = (): void => {
        communication
            .deleteServicePackage(selectedPackage.id)
            .then(() => {
                getAllPackages();
                toast.success('Paket uspešno obrisan');
            })
            .catch((error: any) => {
                console.error('Error:', error);
                toast.error('Greska prilikom brisanja paketa');
            });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    // useEffect(() => {
    //     console.log('packages', packages);
    // }, [packages]);

    return (
        <>
            {loader && <Loader />}
            <ToastContainer />
            {deleteModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setDeleteModal(false);
                            onDelete();
                            // Update services
                        }}
                        close={() => {
                            setDeleteModal(false);
                        }}
                        active={deleteModal}
                        message={'Da li ste sigurni da zelite da obrisete paket?'}
                    />
                </Modal>
            )}
            {editModal && (
                <Sidebar close={() => setEditModal(false)}>
                    <EditModal
                        close={() => setEditModal(false)}
                        formData={selectedPackage}
                        t={t}
                        id={selectedPackage.id}
                        success={() => {
                            getAllPackages();
                        }}
                        loading={(e: boolean) => setLoader(e)}
                        errorMessage={(mess: string) => {
                            console.error(mess);
                        }}
                        modalVisible={editModal}
                        type={'consultation'}
                    />
                </Sidebar>
            )}
            {createModal && (
                <Sidebar close={() => setCreateModal(false)}>
                    <CreateModal
                        close={() => setCreateModal(false)}
                        t={t}
                        success={() => {
                            getAllPackages();
                        }}
                        loading={(e: boolean) => setLoader(e)}
                        errorMessage={(mess: string) => {
                            console.error(mess);
                        }}
                        modalVisible={createModal}
                        type={'consultation'}
                    />
                </Sidebar>
            )}
            <Header>
                <h1>Konsultacije</h1>
            </Header>
            <Create>
                <ButtonContainer>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        size={'20%'}
                        onClick={() => setCreateModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} className="fa-icon-create" />
                        Dodaj paket konsultacija
                    </Button>
                </ButtonContainer>
            </Create>
            <Main>
                {packages?.length > 0 ? (
                    <>
                        {packages.map((item: ObjectKeys) => (
                            <Package
                                key={item.id}
                                data={item}
                                onEdit={() => setEditModal(true)}
                                onDelete={() => setDeleteModal(true)}
                                onClick={() => {
                                    handleSelectedPackage(item);
                                }}
                            />
                        ))}
                    </>
                ) : (
                    <p>Nema paketa</p>
                )}
            </Main>
        </>
    );
};

const Header = styled.div`
    padding-bottom: 20px;
`;

const Create = styled.div`
    margin-bottom: 20px;
    max-width: 80%;
    .fa-icon-create {
        margin-right: 10px;
    }
`;

const Main = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    place-items: center;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export default Packages;
