import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import useTheme from '../../hooks/useTheme';

const TransferOrderSkeleton: React.FC = () => {
    const isDarkTheme = useTheme();
    return (
        <Content isDarkTheme={isDarkTheme}>
            <div className="box">
                <SkeletonTitle className="skeleton-title"></SkeletonTitle>
                <SkeletonPriceContainer>
                    <SkeletonText className="skeleton-text"></SkeletonText>
                    <SkeletonAmount className="skeleton-amount"></SkeletonAmount>
                </SkeletonPriceContainer>
                <SkeletonIconContainer>
                    <SkeletonIcon className="skeleton-icon"></SkeletonIcon>
                </SkeletonIconContainer>
            </div>
        </Content>
    );
};

interface darkThemeProps {
    isDarkTheme: boolean;
}

const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const Content = styled.div<darkThemeProps>`
    @media (max-width: ${MQ_BREAKPOINTS.laptop}) {
        width: 50%;
        flex: 1 1 50%;
        max-width: 50%;
        &:nth-child(even) {
            padding-right: 0px;
        }
        &:nth-child(odd) {
            padding-left: 0px;
        }
    }
    width: 25%;
    flex: 1 1 25%;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    .box {
        height: 150px;
        background: ${({ isDarkTheme }) => (isDarkTheme ? 'rgb(71, 77, 78)' : '#fff')};
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #9b7fe9;
        position: relative;
    }
`;

const SkeletonTitle = styled.div`
    width: 100%;
    height: 1.2em;
    margin: 18px 24px 0;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonPriceContainer = styled.div`
    width: 100%;
    padding: 0 24px 24px;
`;

const SkeletonText = styled.div`
    height: 18px;
    width: 50%;
    margin-bottom: 5px;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonAmount = styled.div`
    height: 22px;
    width: 30%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border-radius: 4px;
`;

const SkeletonIconContainer = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
`;

const SkeletonIcon = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
`;

export default TransferOrderSkeleton;
