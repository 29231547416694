import styled from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

export const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;
export const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
        display: flex;
        align-items: center;
    }
    .right {
        display: flex;
        justify-content: end;
        position: relative;
        button {
            line-height: 1.3;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            &.btn-send {
                margin: 0 10px;
            }
        }
    }
`;
export const MenuButton = styled.div`
    display: block;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 1;
    text-align: right;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 5px 15px 15px;
    border: 1px solid var(--purple);
    border-radius: 5px;
    background: var(--white);
    button {
        width: 135px;
        margin-left: 0px !important;
        margin-top: 10px;
    }
`;
export const Body = styled.div`
    padding-bottom: 20px;
`;

export const Invoice = styled.div`
    background: var(--white);
    box-shadow: 0 0 5px 5px var(--shadow-color);
    padding: 40px;
    margin-top: 40px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 20px;
    }
`;

export const ButtonsBottom = styled.div`
    display: flex;
    margin-top: 20px;
    padding: 30px 0;
    float: right;
    button {
        height: 34px;
        :not(:first-of-type) {
            margin-left: 10px;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        width: 110%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: end;
        background: var(--purple);

        padding-right: 20px;
        padding-bottom: 100px;
        margin-right: -15px;
        margin-left: 15px;
        margin-bottom: -100px;
        button {
            &.print-btn,
            &.preview-btn,
            &.trash-btn,
            &.save-btn {
                background: var(--white);
            }
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        padding-bottom: 100px;
    }
`;
