import { FunctionComponent, SetStateAction, Dispatch, useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../../global/colors';
import communication from '../../../communication';
import useVatNoValidation from '../../../hooks/useVatNoValidation';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import SelectComponent from '../../../components/select';
import SelectSearch from '../../../components/selectSearch';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useAgencyIdNumberValidation from '../../../hooks/useAgencyIdNumberValidation';
import useDateInFutureValidation from '../../../hooks/useDateInFutureValidation';
import useNumLengthValidation from '../../../hooks/useNumLengthValidation';
import { RegisterSecondFormData } from '../../../types/register/registerSecondFormData';
import { ObjectKeys } from '../../../types/objectKeys';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loader';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};
interface Props {
    t: Function;
    showValidationMessage: boolean;
    formData: RegisterSecondFormData;
    setFormData: Dispatch<SetStateAction<RegisterSecondFormData>>;
    handleValidation: Function;
    clickedField: string;
    activityCodesOptions: SelectOption[];
    municipalitiesOptions: SelectOption[];
}

const SecondStep: FunctionComponent<Props> = ({
    t,
    showValidationMessage,
    formData,
    setFormData,
    handleValidation,
    clickedField,
    activityCodesOptions,
    municipalitiesOptions,
}) => {
    const isVatNoValid = useVatNoValidation(formData.vatNumber);
    const isAgencyNameValid = useCharLengthValidation(formData.agencyName, 3, true);
    const isAgencyFullNameValid = useCharLengthValidation(formData.agencyFullName, 3, true);
    const isAgencyIdNumberValid = useAgencyIdNumberValidation(formData.agencyIdNumber);
    const isBankAccountNumber1Valid = useNumLengthValidation(formData.bankAccountNumber1, 3, false);
    const isBankAccountNumber2Valid = useNumLengthValidation(formData.bankAccountNumber2, 13, false);
    const isBankAccountNumber3Valid = useNumLengthValidation(formData.bankAccountNumber3, 2, false);
    const isCityAndZipValid = useCharLengthValidation(formData.cityAndZip, 3, true);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(formData.addressOfHeadQuarters, 3, true);
    const isAddressNumberValid = useCharLengthValidation(formData.addressNumber, 1, true);
    const isAgencyDateOfRegistration = useDateInFutureValidation(formData.agencyDateOfRegistration);
    const [bankAccounts, setBankAccounts] = useState<SelectOption[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState<SelectOption[]>([]);

    const [loader, setLoader] = useState<boolean>(false);

    const employmentTypes = [
        { value: 'flat_rate_agency', label: t('pages.agency.profile.flat_rate_agency').text },
        { value: 'employment_relationship', label: t('pages.agency.profile.employment_relationship').text },
        { value: 'retired', label: t('pages.agency.profile.retired').text },
    ];

    const parseAddress = (address: string) => {
        if (!address || typeof address !== 'string') return { street: '', number: '' };
        const match = address.match(/(\d+\w*\s*[a-zA-Z]*)$/);
        if (match) {
            return {
                street: address.replace(match[0], '').trim(),
                number: match[0].trim(),
            };
        }
        return { street: address.trim(), number: '' };
    };

    const handleGetAgencyData = async (): Promise<void> => {
        if (!formData.vatNumber) return;

        setLoader(true);

        try {
            const res: ObjectKeys = await communication.getAgencyDataBasedOnVatNo(formData.vatNumber);
            const agencyData = res?.data?.data;

            if (!agencyData) {
                setLoader(false);
                return;
            }

            // Handle bank accounts
            if (agencyData?.bank_account.length > 1) {
                const selectOptions: SelectOption[] = agencyData.bank_account.map((account: any) => ({
                    value: account.broj_racuna,
                    label: account.broj_racuna,
                }));
                setBankAccounts(selectOptions);
            } else {
                setBankAccounts([]);
            }

            // Handle phone numbers
            if (Array.isArray(agencyData.phone) && agencyData.phone.length > 1) {
                setPhoneNumbers(agencyData.phone.map((phone: any) => ({ value: phone, label: phone })));
            } else {
                setPhoneNumbers([]); // Reset if it's not an array or has only one value
            }

            setFormData({
                vatNumber: agencyData?.pib,
                agencyName: agencyData?.company_name_short || agencyData?.company_name,
                agencyFullName: agencyData?.company_name.slice(0, 170),
                agencyIdNumber: agencyData?.identification_number,
                bankAccountNumber1:
                    agencyData?.bank_account.length === 1
                        ? agencyData.bank_account[0]?.broj_racuna.split('-')[0] || ''
                        : '',
                bankAccountNumber2:
                    agencyData?.bank_account.length === 1
                        ? agencyData.bank_account[0]?.broj_racuna.split('-')[1] || ''
                        : '',
                bankAccountNumber3:
                    agencyData?.bank_account.length === 1
                        ? agencyData.bank_account[0]?.broj_racuna.split('-')[2] || ''
                        : '',
                activityCodeId: agencyData?.activity_code_id,
                activityCode: { label: '', value: agencyData?.activity_code_id },
                agencyDateOfRegistration: agencyData.date_registered
                    ? formatDateForDatePicker(agencyData.date_registered)
                    : null,
                cityAndZip: `${agencyData?.city} ${agencyData?.zip_code}`,
                municipalities: { label: '', value: '' },
                municipalityId: agencyData.municipality_id || '',
                addressOfHeadQuarters: parseAddress(agencyData?.address).street,
                addressNumber: parseAddress(agencyData?.address).number,
                phoneNumber:
                    Array.isArray(agencyData.phone) && agencyData.phone.length === 1 ? agencyData.phone[0] : '',
                employedInAnotherCompany: false,
                employment_type: '',
            });
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'An error occurred while fetching agency data.');
        } finally {
            setLoader(false);
        }
    };

    const formatDateForDatePicker = (dateString: string): Date | null => {
        const [datePart] = dateString.split('T'); // Extract only the date portion
        const [year, month, day] = datePart.split('-').map(Number);
        const parsedDate = new Date(year, month - 1, day);

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize today's date to prevent time issues

        return parsedDate > today ? null : parsedDate;
    };

    return (
        <SecondStepWrapper>
            {loader && <Loader />}
            <ToastContainer />
            <Input
                type={InputComponentType.Number}
                label={t('pages.register.rightColumn.form.secondStep.vatNumber').text}
                value={formData.vatNumber}
                validation={showValidationMessage || clickedField === 'vatNumber' ? isVatNoValid : ''}
                blurHandler={() => handleValidation('vatNumber')}
                onChange={(value: string) => {
                    setFormData({ ...formData, vatNumber: value });
                }}
                maxLength={9}
            />
            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'auto'}
                disabled={formData.vatNumber === ''}
                className="agency-info-btn"
                onClick={handleGetAgencyData}
            >
                {t('pages.register.rightColumn.form.secondStep.agencyInfo').text}
            </Button>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.agencyName').text}
                value={formData.agencyName}
                maxLength={150}
                validation={showValidationMessage || clickedField === 'agencyName' ? isAgencyNameValid : ''}
                blurHandler={() => handleValidation('agencyName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyName: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.agencyFullName').text}
                value={formData.agencyFullName}
                maxLength={150}
                validation={showValidationMessage || clickedField === 'agencyFullName' ? isAgencyFullNameValid : ''}
                blurHandler={() => handleValidation('agencyFullName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyFullName: value });
                }}
            />
            <Input
                type={InputComponentType.Number}
                label={t('pages.register.rightColumn.form.secondStep.agencyIdNumber').text}
                value={formData.agencyIdNumber}
                validation={showValidationMessage || clickedField === 'agencyIdNumber' ? isAgencyIdNumberValid : ''}
                blurHandler={() => handleValidation('agencyIdNumber')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyIdNumber: value });
                }}
                maxLength={8}
            />
            {bankAccounts && (
                <SelectSearch
                    optionList={bankAccounts}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        let value = data.value;
                        if (value[17] !== '-') {
                            const lastTwoDigits = data.value.slice(-2);
                            value = data.value.slice(0, -2) + '-' + lastTwoDigits;
                        }
                        const parts = value.split('-');
                        setFormData({
                            ...formData,
                            bankAccountNumber1: parts[0],
                            bankAccountNumber2: parts[1],
                            bankAccountNumber3: parts[2],
                        });
                    }}
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.chooseAccount').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseAccount').text}
                    defaultValue={
                        formData.bankAccountNumber1.length > 0 &&
                        formData.bankAccountNumber2.length > 0 &&
                        formData.bankAccountNumber3.length > 0
                            ? {
                                  value: `${formData.bankAccountNumber1}-${formData.bankAccountNumber2}-${formData.bankAccountNumber3}`,
                                  label: `${formData.bankAccountNumber1}-${formData.bankAccountNumber2}-${formData.bankAccountNumber3}`,
                              }
                            : {
                                  label: t('pages.register.rightColumn.form.secondStep.chooseAccount').text,
                                  value: t('pages.register.rightColumn.form.secondStep.chooseAccount').text,
                              }
                    }
                />
            )}
            <BankAccountNumber>
                <label>{t('pages.register.rightColumn.form.secondStep.bankAccountNumber').text}</label>
                <div>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber1}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber1'
                                ? isBankAccountNumber1Valid
                                : ''
                        }
                        blurHandler={() => handleValidation('bankAccountNumber1')}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber1: value });
                        }}
                        maxLength={3}
                        className="bank-acc-num-1"
                    />
                    <span>-</span>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber2}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber2'
                                ? isBankAccountNumber2Valid
                                : ''
                        }
                        hideBlurOnChange
                        blurHandler={() => {
                            if (formData.bankAccountNumber2.length < 13 && formData.bankAccountNumber2.length > 0) {
                                setFormData({
                                    ...formData,
                                    bankAccountNumber2: formData.bankAccountNumber2.padStart(13, '0'),
                                });
                            }
                            handleValidation('bankAccountNumber2');
                        }}
                        handleBlurAction={() => {
                            setFormData({
                                ...formData,
                                bankAccountNumber2: formData.bankAccountNumber2.padStart(13, '0'),
                            });
                        }}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber2: value });
                        }}
                        maxLength={13}
                        className="bank-acc-num-2"
                    />
                    <span>-</span>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber3}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber3'
                                ? isBankAccountNumber3Valid
                                : ''
                        }
                        maxLength={2}
                        blurHandler={() => handleValidation('bankAccountNumber3')}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber3: value });
                        }}
                        className="bank-acc-num-3"
                    />
                </div>
            </BankAccountNumber>
            <SelectWrapper>
                <SelectSearch
                    optionList={activityCodesOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({ ...formData, activityCodeId: data.value, activityCode: data });
                    }}
                    validation={
                        showValidationMessage && !formData.activityCodeId
                            ? t('pages.validationMessages.chooseActivityCode').text
                            : ''
                    }
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.searchActivityCode').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text}
                    defaultValue={
                        formData.activityCodeId
                            ? {
                                  value: formData.activityCodeId,
                                  label: activityCodesOptions.find((ac) => ac?.value === formData.activityCodeId)
                                      ?.label,
                              }
                            : {
                                  label: t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text,
                                  value: '',
                              }
                    }
                />
            </SelectWrapper>
            <DateWrapper>
                <Input
                    type={InputComponentType.Date}
                    label={t('pages.register.rightColumn.form.secondStep.agencyDateOfRegistration').text}
                    date={formData.agencyDateOfRegistration}
                    validation={
                        showValidationMessage || clickedField === 'agencyDateOfRegistration'
                            ? isAgencyDateOfRegistration
                            : ''
                    }
                    blurHandler={() => handleValidation('agencyDateOfRegistration')}
                    onChange={(value: null) => {
                        setFormData({ ...formData, agencyDateOfRegistration: value });
                    }}
                    maxDate={new Date()}
                />
            </DateWrapper>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.cityAndZip').text}
                value={formData.cityAndZip}
                validation={showValidationMessage || clickedField === 'cityAndZip' ? isCityAndZipValid : ''}
                blurHandler={() => handleValidation('cityAndZip')}
                onChange={(value: string) => {
                    setFormData({ ...formData, cityAndZip: value });
                }}
            />
            <SelectWrapper>
                <SelectSearch
                    optionList={municipalitiesOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({ ...formData, municipalityId: data.value, municipalities: data });
                    }}
                    validation={
                        showValidationMessage && !formData.municipalityId
                            ? t('pages.validationMessages.chooseMunicipality').text
                            : ''
                    }
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.searchMunicipality').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text}
                    defaultValue={
                        formData.municipalityId
                            ? {
                                  value: formData.municipalityId,
                                  label: municipalitiesOptions.find((m) => m?.value === formData.municipalityId)?.label,
                              }
                            : {
                                  label: t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text,
                                  value: '',
                              }
                    }
                />
            </SelectWrapper>
            <Address>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.secondStep.addressOfHeadQuarters').text}
                    value={formData.addressOfHeadQuarters}
                    validation={
                        showValidationMessage || clickedField === 'addressOfHeadQuarters'
                            ? isAddressOfHeadQuartersValid
                            : ''
                    }
                    blurHandler={() => handleValidation('addressOfHeadQuarters')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, addressOfHeadQuarters: value });
                    }}
                    className="address-name"
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.secondStep.addressNumber').text}
                    value={formData.addressNumber}
                    validation={showValidationMessage || clickedField === 'addressNumber' ? isAddressNumberValid : ''}
                    blurHandler={() => handleValidation('addressNumber')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, addressNumber: value });
                    }}
                    className="address-num"
                />
            </Address>
            {phoneNumbers && phoneNumbers.length > 1 && (
                <SelectSearch
                    optionList={phoneNumbers}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        const value = data.label;

                        setFormData({
                            ...formData,
                            phoneNumber: value,
                        });
                    }}
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.choosePhoneNumber').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.choosePhoneNumber').text}
                />
            )}
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.phoneNumber').text}
                value={formData.phoneNumber}
                onChange={(value: string) => {
                    setFormData({ ...formData, phoneNumber: value });
                }}
                maxLength={19}
            />
            <EmployedType>
                <p>{t('pages.agency.profile.employment_type').text}</p>
                <SelectComponent
                    optionList={employmentTypes}
                    handleSelectedValue={(value: string) => {
                        setFormData({ ...formData, employment_type: value });
                    }}
                    placeholder={t('pages.agency.profile.employment_type_placeholder').text}
                    width={'100%'}
                    defaultSelectedOption={employmentTypes.find((et) => et.value === formData.employment_type)}
                />
                {showValidationMessage && formData.employment_type === '' ? (
                    <p className="error">{t('pages.agency.profile.employment_type').text}.</p>
                ) : null}
            </EmployedType>
        </SecondStepWrapper>
    );
};

export default SecondStep;

const SecondStepWrapper = styled.div`
    .agency-info-btn {
        margin-bottom: 20px;
        padding: 5px;
        height: auto;
        line-height: 15px;
    }
`;
const BankAccountNumber = styled.div`
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
    }
    > div {
        display: flex;
        align-items: center;
        span {
            margin: 0 10px;
            color: var(--border-color);
            width: 10px;
        }
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const Address = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const EmployedType = styled.div`
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    p {
        color: var(--gray);
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 15px;
    }
    .error {
        color: var(--danger);
        font-size: 12px;
        margin-top: 5px;
    }
`;
