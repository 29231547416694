import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderCard from '../../../components/transferOrderCard';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { generateQrCode } from '../../../communication/qr';
import { ObjectKeys } from '../../../types/objectKeys';
import AddOrEditModal from './addOrEditModal';
import Pagination from '../../../components/pagination';
import ConfirmModal from '../../../components/confirmModal';
import InfoModal from '../../../components/infoModal';
import { getAccessToken } from '../../../functions/auth';
import { formatTwoDecimals } from '../../../functions/format';
import Search from '../../../components/input/search';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from './successModal';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';

interface SelectProps {
    label: string;
    value: string;
}
const TransferOrders: FunctionComponent = () => {
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [orderTransfers, setOrderTransfers] = useState<ObjectKeys[]>();
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [addOrEditModal, setAddOrEditModal] = useState<boolean>(false);

    const [pagination, setPagination] = useState<any>({});
    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);

    const [transferAction, setTransferAction] = useState<string>('new');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);
    const [showSearch, setShowSearch] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const [warningModal, setWarningModal] = useState(false);

    const isDarkTheme = useTheme();

    const token = getAccessToken();

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        searchJoin: 'and',
        orderBy: 'created_at',
        sortedBy: 'desc',
        search: 'type:custom',
        searchFields: 'type:like',
    });

    const showSelectOptions = [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ];

    const getAlltransfers = async (): Promise<void> => {
        setIsLoader(true);
        const response = await communication.getAllTransfers(params);
        setPagination(response.data.meta.pagination);
        setOrderTransfers(response.data.data);
        setIsLoader(false);
    };

    useEffect(() => {
        getAlltransfers();
    }, [params]);

    const handleFindTransfers = (value: string): void => {
        if (value !== '') {
            setParams({
                ...params,
                search: `type:custom;payment_slip_heading:${value}`,
                searchFields: 'type:like;payment_slip_heading:like',
            });
        } else {
            setParams({ ...params, search: 'type:custom', searchFields: 'type:like' });
        }
    };

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${
                    transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
                        ? transfer.model_number_recipient
                        : '00'
                }${transfer.call_number_recipient}`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const downloadTax = async (transfer: ObjectKeys): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(transfer);
            communication.downloadTax(
                transfer?.id,
                token,
                qrCodeR ? `data:image/jpeg;base64,${qrCodeR}` : '',
                'transfer',
            );
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleClickOnCard = async (transfer: ObjectKeys, action: string): Promise<void> => {
        setSelectedTransfer(transfer);
        switch (action) {
            case 'preview':
                setPreviewModal(true);
                await getQrCode(transfer);
                return;
            case 'edit':
                setTransferAction('edit');
                setAddOrEditModal(true);
                return;
            case 'delete':
                setIsDeleteModalActive(true);
                return;
            case 'print':
                await downloadTax(transfer);
                return;
            default:
                break;
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoader(true);
        setIsDeleteModalActive(false);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAlltransfers();
        }
        setIsLoader(false);
    };

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadTax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                        type="transfer"
                    />
                </Modal>
            )}
            {addOrEditModal && (
                <Modal
                    modalVisible={addOrEditModal}
                    closeModal={() => setAddOrEditModal(false)}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <AddOrEditModal
                        onClose={() => setAddOrEditModal(false)}
                        selectedTransfer={selectedTransfer}
                        action={transferAction}
                        refresh={getAlltransfers}
                    />
                </Modal>
            )}
            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.transfers.transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>
            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'Da li ste sigurni da želite da obrišete nalog za prenos?'}
                />
            </Modal>
            <Header isDarkTheme={isDarkTheme}>
                <div className="title-and-button">
                    <h1>{t('navbar.pausal.customTax').text}</h1>

                    <div className="header-options-buttons">
                        <Button
                            className={'button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setTransferAction('new');
                                setAddOrEditModal(true);
                            }}
                            icon={true}
                            height={35}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {t('pages.transfers.add_transfer').text}
                        </Button>
                    </div>
                </div>
            </Header>
            <Filters isDarkTheme={isDarkTheme}>
                <label className="second-title">{t('pages.transfers.other_transfers').text}</label>
            </Filters>
            <BottomFilters isDarkTheme={isDarkTheme}>
                <div className="filter-bottom">
                    <div className="filter">
                        <label className="show-2">{t('pages.invoices.filters.show.label').text}</label>
                        <SelectComponent
                            optionList={showSelectOptions}
                            defaultSelectedOption={showSelectOptions[0]}
                            color={colors.gray}
                            className={'select-items'}
                            handleSelectedValue={(value: any) => {
                                setParams({ ...params, limit: value, page: 1 });
                            }}
                            width={'70px'}
                        />
                        <label className="show-2">{t('pages.transfers.orders').text}</label>
                    </div>
                    <div className="filter-search">
                        <Search
                            placeholder={t('pages.invoices.filters.search').text}
                            value={searchValue}
                            onChange={(value: string) => {
                                setShowSearch(true);
                                setSearchValue(value);
                            }}
                            onLeaveOrEnter={(value: string) => {
                                handleFindTransfers(value);
                                setShowSearch(false);
                            }}
                            showSearchNote={showSearch}
                            noteColor={colors.white}
                            className="select-search-filter"
                        />
                    </div>
                </div>
            </BottomFilters>
            <Content>
                {orderTransfers?.map((transfer: any) => (
                    <TransferOrderCard
                        key={transfer.id}
                        title={transfer.payment_slip_heading}
                        price={formatTwoDecimals(transfer.total_amount)}
                        currency={transfer.currency}
                        onClick={() => handleClickOnCard(transfer, 'preview')}
                        onEdit={() => handleClickOnCard(transfer, 'edit')}
                        onDelete={() => handleClickOnCard(transfer, 'delete')}
                        onPrint={() => handleClickOnCard(transfer, 'print')}
                        t={t}
                    />
                ))}
            </Content>
            {orderTransfers && orderTransfers?.length > 0 && pagination?.total_pages > 0 && (
                <Pagination
                    pageCount={pagination?.total_pages}
                    initialPage={0}
                    onPageChange={(e: object) => {
                        handlePageClick(e);
                    }}
                    nextLabel={(pagination?.current_page === pagination?.total_pages ? '' : '>').toString()}
                    previousLabel={(pagination?.current_page === 1 ? '' : '<').toString()}
                />
            )}
        </PageWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 74vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow-y: auto;
        }
    }
    label {
        font-size: 14px;
        line-height: 34px;
    }
`;

const Title = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
`;

const Content = styled.div``;

const BottomFilters = styled.div<DarkThemeProps>`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show-2 {
            font-size: 14px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92A5AC')};
            text-transform: uppercase;
        }
    }
    .purple-title {
        font-size: 14px;
        background: var(--purple);
        color: var(--white);
        margin-bottom: 10px;
        padding: 20px;
        text-transform: uppercase;
    }
`;

const Header = styled.div<DarkThemeProps>`
    /* padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color); */
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4C595F')};
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            button {
                margin-top: 12px;
            }
        }
    }
    .title-and-button {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--border-color);
        gap: 35px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
        }
    }
`;

const Filters = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 20px 20px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    vertical-align: middle;
    height: 65px;
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        min-width: 120px;
        text-transform: uppercase;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            font-size: 15px;
        }
    }
`;
export default TransferOrders;
