import { useQuery, useMutation } from '@tanstack/react-query';
import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { queryClient } from '../App';

const fetchTips = async (params: any, groupIndex?: number) => {
    try {
        const response = await communication.getAllGroups(params);

        if (!isStatusSuccess(response.status)) {
            throw new Error('Failed to fetch tips');
        }

        if (!response.data || !Array.isArray(response.data.data)) {
            throw new Error('Invalid response data');
        }

        const selectedGroup = response.data.data.find((group: any) => group.name === 'createInvoiceTips');

        if (!selectedGroup) {
            throw new Error('Group "createInvoiceTips" not found');
        }

        if (selectedGroup.is_finished) {
            return null;
        } else {
            return {
                ...selectedGroup,
                tips: {
                    ...selectedGroup.tips,
                    data: selectedGroup.tips.data.map((tip: any, index: number) => ({
                        ...tip,
                        is_active: index === 0,
                    })),
                },
            };
        }
    } catch (error) {
        console.error('Error fetching tips:', error);
        throw error;
    }
};

const setNextTipActive = (groupIndex?: number) => {
    queryClient.setQueryData(['tips', { page: 1, per_page: 5 }], (oldData: any) => {
        if (!oldData) {
            console.warn('No cached data found for tips!');
            return oldData;
        }

        const activeTip = oldData.tips.data.find((tip: any) => tip.is_active);
        if (!activeTip) {
            return oldData;
        }

        const nextTipIndex = oldData.tips.data.findIndex((tip: any) => tip.id === activeTip.id) + 1;
        if (nextTipIndex >= oldData.tips.data.length) {
            communication.finishGroup(oldData.id, true).then(() => {
                queryClient.invalidateQueries({ queryKey: ['tips', { page: 1, per_page: 5 }] });
            });
            return { ...oldData, is_finished: true };
        }

        return {
            ...oldData,
            tips: {
                ...oldData.tips,
                data: oldData.tips.data.map((tip: any) => ({
                    ...tip,
                    is_active: tip.id === oldData.tips.data[nextTipIndex].id,
                })),
            },
        };
    });
};

const skipGroup = () => {
    queryClient.setQueryData(['tips', { page: 1, per_page: 5 }], (oldData: any) => {
        if (!oldData) return oldData;

        communication.finishGroup(oldData.id, true).then(() => {
            queryClient.invalidateQueries({ queryKey: ['tips', { page: 1, per_page: 5 }] });
        });
        return { ...oldData, is_finished: true };
    });
};

export const useTipsQuery = (params: any, groupIndex?: number) => {
    const invalidate = () => queryClient.invalidateQueries({ queryKey: ['tips', params] });
    const remove = () => queryClient.removeQueries({ queryKey: ['tips', params] });

    const {
        data: tips = null,
        refetch,
        isLoading,
        isFetching,
        error,
    } = useQuery({
        queryKey: ['tips', params],
        queryFn: () => fetchTips(params),
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    });

    return {
        tips,
        refetch,
        isLoading,
        isFetching,
        error,
        invalidate,
        remove,
        setNextTipActive,
        skipGroup,
    };
};
