import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useProfileStore from '../../../store/profile';
import Loader from '../../../components/loader';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Input, { InputComponentType } from '../../../components/input';
import Logo from '../../../assets/pausal-logo-gray.png';
import useEmailValidation from '../../../hooks/useEmailValidation';
import Modal from '../../../components/modal';
import Success from '../../../components/successModal';
import { ObjectKeys } from '../../../types/objectKeys';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const SendData: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [formData, setFormData] = useState<ObjectKeys>();
    const [recipient, setRecipient] = useState('');
    const [recipientTouched, setRecipientTouched] = useState(false);
    const [success, setSuccess] = useState(false);
    const [domesticBankAccounts, setDomesticBankAccounts] = useState([]);
    const [foreignBankAccounts, setForeignBankAccounts] = useState([
        {
            iban: '',
            swift: '',
            currency: '',
        },
    ]);

    const isEmailValid = useEmailValidation(recipient);

    useEffect(() => {
        getAgency();
    }, []);

    const send = (): void => {
        if (!isEmailValid) {
            formData && communication.sendAgencyData(formData?.id, { emails: [recipient] });
            setSuccess(true);
        }
    };

    async function getAgency(): Promise<void> {
        const res = await communication.getAgency();
        setFormData(res.data.data);
        if (res.data.data.bankAccounts.data.length > 0) {
            const filteredBankAccounts = res.data.data.bankAccounts.data.filter(
                (account: ObjectKeys) => account.type === 'domestic',
            );
            setDomesticBankAccounts(filteredBankAccounts);
            const filteredForeignBankAccounts = res.data.data.bankAccounts.data.filter(
                (account: ObjectKeys) => account.type === 'foreign',
            );
            setForeignBankAccounts(filteredForeignBankAccounts);
        }
    }

    return (
        <>
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)}>
                        <SuccessContainer>
                            <h4>{t('pages.agency.sendData.successfullySent').text}</h4>
                            <p>{recipient}</p>
                        </SuccessContainer>
                    </Success>
                </Modal>
            )}
            {formData ? (
                <Content>
                    <Data>
                        <h3>{formData.name}</h3>
                        <p className="margin-top">{formData.full_name}</p>
                        <p className="margin-top">
                            {formData.street_address} {formData.street_number}
                        </p>
                        <p>{formData.city}</p>

                        <Row className="margin-top">
                            <p>{t('pages.agency.sendData.VATno').text}&nbsp;</p>
                            <p>{formData.pib}</p>
                        </Row>
                        <Row>
                            <p>{t('pages.agency.sendData.id').text}&nbsp;</p>
                            <p>{formData.identification_number}</p>
                        </Row>
                        <Row>
                            <p>{t('pages.agency.sendData.email').text}&nbsp;</p>
                            <p>{formData.agency_email}</p>
                        </Row>
                        <Row>
                            <p>{t('pages.agency.sendData.phone').text}&nbsp;</p>
                            <p>{formData.phone}</p>
                        </Row>
                        <Row>
                            <p>{t('pages.agency.bankAccounts.domestic.title').text}&nbsp;</p>
                        </Row>
                        {domesticBankAccounts.length > 0 ? (
                            <>
                                {domesticBankAccounts.map((account: ObjectKeys, index: number) => (
                                    <Row className="bank" key={index}>
                                        <p>
                                            {index + 1 + '. '}
                                            {t('pages.agency.sendData.bank').text}&nbsp;
                                        </p>
                                        <p key={index}>{account.account_number}</p>
                                    </Row>
                                ))}
                            </>
                        ) : null}
                        <Row>
                            <p>{t('pages.agency.bankAccounts.foreign.title').text}&nbsp;</p>
                        </Row>
                        {foreignBankAccounts.length > 0 ? (
                            <>
                                {foreignBankAccounts.map((account: ObjectKeys, index: number) => (
                                    <Row className="bank" key={index}>
                                        <p>
                                            {index + 1 + '. '}
                                            {account.currency}&nbsp;|
                                        </p>
                                        <p>{t('pages.agency.profile.iban').text}&nbsp;</p>
                                        <p key={index}>{account.iban}&nbsp;|</p>
                                        <p>{t('pages.agency.profile.swift').text}&nbsp;</p>
                                        <p key={index}>{account.swift}</p>
                                    </Row>
                                ))}
                            </>
                        ) : null}
                        <Border />
                        <Footer>
                            {t('pages.agency.sendData.sentVia').text}&nbsp;
                            <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                pausal.rs
                            </a>
                            <img src={Logo} alt="logo" />
                        </Footer>
                    </Data>

                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.sendData.recipient').text}
                        value={recipient}
                        onChange={(value: string) => {
                            setRecipient(value);
                        }}
                        validation={recipientTouched ? isEmailValid : ''}
                        blurHandler={() => setRecipientTouched(true)}
                    />

                    <div className="button-container">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="big"
                            size={200}
                            onClick={send}
                        >
                            {t('pages.agency.sendData.send').text}
                        </Button>
                    </div>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default SendData;

const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    padding: 5% 20px 0 20px;

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .input-container {
        width: 100%;
    }
    input {
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
`;

const Data = styled.div`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px 5px var(--shadow-color);
    margin-bottom: 30px;
    padding: 30px;
    color: var(--gray);
    display: inline-block;
    vertical-align: top;
    h3 {
        color: var(--black);
        font-size: 25px;
        font-weight: 400;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        &.margin-top {
            margin-top: 20px;
        }
    }
    img {
        width: 85px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        float: right;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    &.margin-top {
        margin-top: 20px;
    }
    &.bank {
        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            flex-direction: column;
        }
    }
`;

const Border = styled.div`
    border-top: 2px solid var(--border-color);
    padding-top: 25px;
    margin-top: 25px;
`;
const Footer = styled.div`
    font-size: 12px;
`;
const SuccessContainer = styled.div`
    display: block;
    padding: 35px 50px 30px;
    text-align: center;
    min-height: 130px;
    h4 {
        color: var(--black);
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 30px;
        line-height: 1.5;
    }
    p {
        margin-top: 5px;
        line-height: 30px;
        white-space: pre-line;
        font-size: 16px;
        text-align: center;
        color: var(--gray);
    }
`;
