import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import colors from '../../../global/colors';
import { formatClientParams } from '../../../functions/formatClientParams';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAgencyStore from '../../../store/agency';
import Modal from '../../../components/modal';
import NoItems from '../../../components/noItems';
import Button, { ButtonVariant } from '../../../components/button';
import SelectComponent from '../../../components/select';
import Search from '../../../components/input/search';
import Pagination from '../../../components/pagination';
import Loader from '../../../components/loader';
import ClientCard from '../../../components/clientCard';
import Sidebar from '../../../components/sideModal';
import Success from '../../../components/successModal';
import Dropdown from '../../../components/dropdown';
import ArchiveModal from './archiveModal';
import EditClientModal from './editClientModal';
import AddClientModal from './addClientModal';
import Warning from '../../../components/successModal';
import WarningClientCard from './warningCard';
import useResponsive from '../../../hooks/responsive/useResponsive';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';

type ClientType = 'domestic' | 'foreign' | 'archived';

const Clients: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { isMobile, isTabletAndDown } = useResponsive();

    const [addClientDropdown, setAddClientDropdown] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [clickedClient, setClickedClient] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [clients, setClients] = useState<ObjectKeys>({});

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const isDarkTheme = useTheme();

    const { handleClickOutside } = useOutsideClick(ref, setAddClientDropdown);
    const tabs = [
        t('pages.clients.tabs.domestic').text,
        t('pages.clients.tabs.foreign').text,
        t('pages.clients.tabs.inactive').text,
    ];
    const mobileTabs: any = [
        { value: 0, label: t('pages.clients.tabs.domestic').text },
        { value: 1, label: t('pages.clients.tabs.foreign').text },
        { value: 2, label: t('pages.clients.tabs.inactive').text },
    ];
    const [searchValue, setSearchValue] = useState('');
    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);
    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchJoin: 'and',
    });

    useEffect(() => {
        setLoaderVisible(true);
        if (tabIndex === 2) {
            communication
                .getArchivedClients({
                    ...params,
                    page: page,
                    search: formatClientParams(params.search),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setLoaderVisible(false);
                        setClients(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setClients([]);
                    }
                });
        } else if (tabIndex === 0)
            communication
                .getClients(agency?.user_id, {
                    ...params,
                    page: page,
                    search: formatClientParams(params.search)
                        ? 'is_foreign:0;' + formatClientParams(params.search)
                        : 'is_foreign:0',
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setLoaderVisible(false);
                        setClients(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setClients([]);
                    }
                });
        else
            communication
                .getClients(agency?.user_id, {
                    ...params,
                    page: page,
                    search: formatClientParams(params.search)
                        ? 'is_foreign:1;' + formatClientParams(params.search)
                        : 'is_foreign:1',
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setLoaderVisible(false);
                        setClients(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setClients([]);
                    }
                });
    }, [params, page, tabIndex]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    //Client Change
    const handleChange = (clientId: string): void => {
        setClickedClient(clientId);
        setEditClientModal(true);
    };

    //Client Archive Modal - not archived clients
    const handleArchiveModal = (clientId: string): void => {
        setGlobalModal(
            <ArchiveModal
                t={t}
                clickedClient={clientId}
                closeGlobalModal={closeGlobalModal}
                setClients={setClients}
                tabIndex={tabIndex}
                userId={agency?.user_id}
            />,
        );
    };

    //Add new client
    const handleAddClient = (clientType: string): void => {
        setAddClientDropdown(false);
        setClickedClient(clientType);
        setAddClientModal(true);
    };
    const handleActivate = (clientId: string): void => {
        setLoaderVisible(true);
        communication
            .activateClient(clientId)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setSuccessModal(true);
                }
            })
            .then(() => {
                if (tabIndex === 2) {
                    communication
                        .getArchivedClients({
                            ...params,
                            page: page,
                            search: '',
                        })
                        .then((res: ObjectKeys) => {
                            if (res.status === 200) {
                                setLoaderVisible(false);
                                setClients(res?.data);
                            }
                        });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };

    const handleClickedClient = (clientId: string): void => {
        setClickedClient(clientId);
    };

    const getClientType = (clickedTab: number): ClientType => {
        switch (clickedTab) {
            case 0:
                return 'domestic';
            case 1:
                return 'foreign';
            case 2:
                return 'archived';
            default:
                return 'domestic';
        }
    };

    return (
        <>
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success
                        close={() => setSuccessModal(false)}
                        message={t('pages.clients.successModal.message').text}
                    />
                </Modal>
            )}
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={tabIndex}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="clients page">
                <Header isDarkTheme={isDarkTheme}>
                    <div>
                        <h1>{t('navbar.pausal.listOfClients').text}</h1>
                    </div>
                    <AddButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            icon={!isMobile ? true : false}
                            size={'auto'}
                            onClick={() => {
                                setAddClientDropdown(!addClientDropdown);
                            }}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                            {!isMobile && t('pages.clients.newClient').text}
                        </Button>
                        {addClientDropdown && (
                            <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                <Dropdown
                                    arrowRight="4px"
                                    itemList={[
                                        {
                                            label: t('pages.clients.header.addClientDropdown.domestic').text,
                                            handler: () => handleAddClient('domestic'),
                                        },
                                        {
                                            label: t('pages.clients.header.addClientDropdown.foreign').text,
                                            handler: () => handleAddClient('foreign'),
                                        },
                                    ]}
                                />
                            </DropdownContainer>
                        )}
                    </AddButtonContainer>
                </Header>

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabsItems isDarkTheme={isDarkTheme}>
                        <TabList>
                            {isTabletAndDown ? (
                                <div style={{ marginTop: '10px' }}>
                                    <SelectComponent
                                        className="select-container tabs-select"
                                        optionList={mobileTabs}
                                        handleSelectedValue={(value: any) => setTabIndex(value)}
                                        defaultSelectedOption={tabs[0]}
                                        placeholder={t('pages.transfers.choose').text}
                                    />
                                </div>
                            ) : (
                                <>
                                    {tabs?.map((tab, index) => (
                                        <Tab key={index}>{tab}</Tab>
                                    ))}
                                </>
                            )}
                        </TabList>
                    </TabsItems>
                </Tabs>
                <Filters>
                    <ShowSelect isDarkTheme={isDarkTheme}>
                        <SelectComponent
                            optionList={showItems}
                            label={t('pages.clients.filters.show.label').text}
                            handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                            defaultSelectedOption={showItems[0]}
                        />
                        <p>{t('pages.clients.filters.show.clients').text}</p>
                    </ShowSelect>
                    <Search
                        placeholder={t('pages.clients.filters.search').text}
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            setParams({
                                ...params,
                                search: { ...params.search, company_name: value },
                            });
                        }}
                    />
                </Filters>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    {tabs?.map((tab: string, index: number) => (
                        <TabPanel key={index} className={tab}>
                            {clients?.data?.length > 0 ? (
                                clients?.data?.map((client: ObjectKeys) => {
                                    return (
                                        <div
                                            key={client.id}
                                            onClick={() => {
                                                !client.deleted_at && handleChange(client.id);
                                            }}
                                        >
                                            {tabIndex !== 2 ? (
                                                <ClientCard
                                                    cardData={client}
                                                    cardType={getClientType(index)}
                                                    handleChange={() => handleChange(client.id)}
                                                    handleDelete={(
                                                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    ) => {
                                                        handleArchiveModal(client?.id);
                                                        e.stopPropagation();
                                                    }}
                                                    handleClick={() => handleClickedClient(client.id)}
                                                />
                                            ) : (
                                                <ClientCard
                                                    cardData={client}
                                                    cardType={getClientType(index)}
                                                    handleActivate={() => handleActivate(client?.id)}
                                                    handleClick={() => handleClickedClient(client.id)}
                                                />
                                            )}
                                        </div>
                                    );
                                })
                            ) : tabIndex === 2 ? (
                                <NoItems text={t('pages.clients.noInactiveClients').text} />
                            ) : (
                                <NoItems text={t('pages.clients.noItems.description').text} />
                            )}
                        </TabPanel>
                    ))}
                </Tabs>
                {clients?.data?.length > 0 && clients?.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={clients?.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            clients?.meta?.pagination?.current_page === clients?.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${clients?.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={clients?.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
interface DarkThemeProps {
    isDarkTheme?: boolean;
}
const PageWrapper = styled.div``;
const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    button {
        margin-left: 35px;
        height: 35px;
        font-size: 12px;
        line-height: initial;
    }
    > div {
        display: flex;
        align-items: center;
    }
    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        justify-content: space-between;
    }
`;
const AddButtonContainer = styled.div`
    position: relative;
`;

const TabsItems = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    text-transform: uppercase;
    padding: 10px 30px;
    margin-bottom: 20px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        display: flex;
        flex-direction: column;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    > p {
        margin-right: 6px;
        font-size: 14px;
    }
    .react-tabs__tab-list {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        .react-tabs__tab {
            padding: 11px 20px;
            font-size: 14px;
            border-bottom: 3px solid transparent;
            list-style: none;
            cursor: pointer;
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                border-color: var(--white);
                color: white;
                border-radius: 0;
                border-bottom: 3px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'white')};
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
`;
const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
        height: 42px;
    }
`;
const ShowSelect = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: none;
        }
    }
`;
const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
export default Clients;
