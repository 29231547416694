import { FunctionComponent, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import TaxCalendar from './tax';
import EcoTaxCalendar from './ecotax';

import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';

const BankAccounts: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const isDarkTheme = useTheme();

    const handleTabSelect = (i: number): void => {
        setSelectedTab(i);
    };

    return (
        <>
            <Header isDarkTheme={isDarkTheme}>
                <h1>{t('pages.transfers.payment_lists.title').text}</h1>
            </Header>
            <Content>
                <StyledTabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                    <StyledTabList isDarkTheme={isDarkTheme}>
                        <StyledTab isDarkTheme={isDarkTheme}>{t('pages.transfers.title').text}</StyledTab>
                        <StyledTab isDarkTheme={isDarkTheme}>{t('navbar.pausal.ecotax').text}</StyledTab>
                    </StyledTabList>
                    <StyledTabPanel value={0}>
                        <TaxCalendar />
                    </StyledTabPanel>
                    <StyledTabPanel value={1}>
                        <EcoTaxCalendar />
                    </StyledTabPanel>
                </StyledTabs>
            </Content>
        </>
    );
};
export default BankAccounts;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const StyledTabs = styled(Tabs)``;

const StyledTabList = styled(TabList)<DarkThemeProps>`
    display: flex;
    list-style: none;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 14px;
    height: auto;

    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
`;

const StyledTab = styled(Tab)<DarkThemeProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 0;
    &.react-tabs__tab--selected {
        color: white;
        border-bottom: 3px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'white')};
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    }
`;

const StyledTabPanel = styled(TabPanel)`
    border: 1px solid #ccc;
    border: none;
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;
const Header = styled.div<DarkThemeProps>`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    .back {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        &:hover {
            background: var(--purple);
            border: 1px solid var(--purple);
            color: var(--white);
        }
    }

    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4C595F')};
    }
`;
