import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import { ObjectKeys } from '../../../types/objectKeys';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Loader from '../../../components/loader';
import Checkbox from '../../../components/checkbox';
import communication from '../../../communication';
import { formatTwoDecimals } from '../../../functions/format';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import useServicesStore from '../../../store/services';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import Input, { InputComponentType } from '../../../components/input';
import useResponsive from '../../../hooks/responsive/useResponsive';
import ServiceCard from './serviceCard';
import ConsultationsCard from './consultationsCard';

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

interface StepOneProps {
    nextStep: Function;
    finallCart: Function;
    mainCart: ObjectKeys;
}

const StepOne: React.FC<StepOneProps> = ({ nextStep, finallCart, mainCart }) => {
    const { currentLang } = useLanguageStore();
    const lang = getCurrentLanguage();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const [searchParams, setSearchParams] = useSearchParams();

    const { isTabletAndDown } = useResponsive();

    const [loader, setLoader] = useState<boolean>(false);
    const [services, setServices] = useState<Service[]>([]);

    const [totalAmount, setTotalAmount] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');
    const [expandedServiceId, setExpandedServiceId] = useState('');

    const [validationError, setValidationError] = useState<boolean>(false);

    const [filteredServices, setFilteredServices] = useState<any[]>([]);

    const [renderKey, setRenderKey] = useState(0);

    const [servicePackageValidationErrorId, setServicePackageValidationErrorId] = useState<string>('');
    const [consultationPackageValidationErrorId, setConsultationPackageValidationErrorId] = useState<string>('');

    const [chosenPackage_id, setChosenPackage_id] = useState<string>('');
    const [chosenConsultaionPackage_id, setChosenConsultaionPackage_id] = useState<string>('');

    const [cart, setCart] = useState<ObjectKeys>({
        status: '',
        total_price: 0,
        type: 'service',
        email: '',
        pib: '',
        first_name: '',
        last_name: '',
        language: lang,
        note: '',
        items: [],
    });

    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

    const toggleSelectAll = (): void => {
        setSelectAllChecked(!selectAllChecked);
        if (selectAllChecked) {
            setCart((prev) => {
                return {
                    ...prev,
                    items: [],
                };
            });
        } else {
            setCart((prev) => {
                return {
                    ...prev,
                    items: filteredServices.map((item) => {
                        return { ...item, quantity: 1 };
                    }),
                };
            });
        }
        setRenderKey(renderKey + 1);
    };

    const resetCart = (): void => {
        setCart({
            status: '',
            total_price: 0,
            type: 'service',
            email: '',
            pib: '',
            first_name: '',
            last_name: '',
            language: lang,
            note: '',
            items: [],
        });
    };

    const getAllServices = (): void => {
        setLoader(true);
        const params = {
            page: 1,
            limit: 1000,
            search: 'is_consultation:0',
            searchFields: 'is_consultation:=',
            searchJoin: 'and',
            orderBy: `name_${lang}`,
            sortedBy: 'asc',
        };
        communication
            .getAllGuestServices(params)
            .then((response: ObjectKeys) => {
                useServicesStore.setState(() => ({
                    servicesData: response.data.data,
                }));

                const servicesWithQuantitiesAndFree = response.data.data.map((service: any) => {
                    return {
                        ...service,
                        quantity: 0, // Set the initial quantity for each service
                        price: service.price, // Set price to 0 if it's a free service
                        type: 'service',
                    };
                });

                setServices(servicesWithQuantitiesAndFree);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getQuantityForService = (row: ObjectKeys): String => {
        const rowId = row.id;
        const cartItem = cart.items.find((item: ObjectKeys) => item.id === rowId);
        return cartItem ? cartItem.quantity : 0;
    };

    const getCheckedForService = (row: ObjectKeys): boolean => {
        const rowId = row.id;
        const cartItem = cart.items.find((item: ObjectKeys) => item.id === rowId);
        return cartItem ? true : false;
    };

    const handleCheckbox = (service: ObjectKeys): void => {
        setSelectAllChecked(false);

        const serviceId = service.id;

        // Check if the service is already in the cart
        const foundInCartIndex = cart.items.findIndex((item: ObjectKeys) => item.id === serviceId);

        // If found, remove it; if not found, add it
        if (foundInCartIndex !== -1) {
            // Service found in cart, remove it
            const updatedItems = cart.items.filter((item: ObjectKeys) => item.id !== serviceId);

            // Update the cart with the item removed
            setCart((prevCart) => ({
                ...prevCart,
                items: updatedItems,
            }));
        } else {
            // Service not found in cart, add it with quantity 1
            const newItem = {
                ...service, // Assuming the service object structure matches cart item structure
                quantity: 1, // Set initial quantity
            };

            // Update the cart with the new item added
            setCart((prevCart) => ({
                ...prevCart,
                items: [...prevCart.items, newItem],
            }));
        }
    };

    const handleCounterIncrease = (service: ObjectKeys): void => {
        const serviceId = service.id;

        // Check if the service is already in the cart
        const existingItemIndex = cart.items.findIndex((item: ObjectKeys) => item.id === serviceId);

        const updatedItems = [...cart.items]; // Clone the current items array to prepare for mutation
        if (existingItemIndex !== -1) {
            // If the service is already in the cart, update its quantity
            const existingItem = updatedItems[existingItemIndex];
            if (existingItem.quantity < 20) {
                updatedItems[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity + 1,
                };
            }
        } else {
            // Service not in cart, add as new item
            const newItem = {
                ...service,
                quantity: 1,
            };
            updatedItems.push(newItem);
        }

        // Update the cart state with the updated items
        setCart((prevCart) => ({
            ...prevCart,
            items: updatedItems,
        }));

        // Trigger any necessary re-renders or side effects
        setRenderKey((prevRenderKey) => prevRenderKey + 1);
    };

    const handleCounterDecrease = (service: ObjectKeys): void => {
        const serviceId = service.id;

        // Check if the service is already in the cart
        const existingItemIndex = cart.items.findIndex((item: ObjectKeys) => item.id === serviceId);

        // Only proceed if the item exists in the cart
        if (existingItemIndex !== -1) {
            const updatedItems = [...cart.items]; // Clone the current items array to prepare for mutation
            const existingItem = updatedItems[existingItemIndex];

            if (existingItem.quantity > 1) {
                // Decrease the quantity by 1 if it's more than 1
                updatedItems[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity - 1,
                };
            } else {
                // Remove the item from the cart if its quantity becomes 0
                updatedItems.splice(existingItemIndex, 1);
            }

            // Update the cart state with the updated items
            setCart((prevCart) => ({
                ...prevCart,
                items: updatedItems,
            }));

            // Trigger any necessary re-renders or side effects
            setRenderKey((prevRenderKey) => prevRenderKey + 1);
        }
    };

    const handleServiceClick = (serviceId: string): void => {
        setExpandedServiceId(serviceId === expandedServiceId ? '' : serviceId);
    };

    useEffect(() => {
        if (mainCart && mainCart.items && mainCart.items.length > 0) {
            setCart(mainCart);
        }
    }, [mainCart]);

    useEffect(() => {
        finallCart(cart);
        const newTotalAmount = cart.items.reduce(
            (total: any, service: ObjectKeys) => total + service.price * service.quantity,
            0,
        );
        setTotalAmount(newTotalAmount);
    }, [cart]);

    useEffect(() => {
        setCart((prevCart) => {
            const updatedCart = { ...prevCart };
            updatedCart.total_price = totalAmount;
            return updatedCart;
        });
    }, [totalAmount]);

    useEffect(() => {
        getAllServices();
    }, []);

    useEffect(() => {
        if (services.length > 0) {
            const updatedServices = services.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );
            setFilteredServices(updatedServices);
        }
    }, [services]);

    useEffect(() => {
        if (services.length > 0) {
            const updatedServices = services.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );
            setFilteredServices(updatedServices);
        }
        setCart((prevCart) => {
            return {
                ...prevCart,
                language: lang,
            };
        });
    }, [searchQuery, lang]);

    // *************** Service packages section ***************

    const fetchServicePackages = (): void => {
        setLoader(true);

        const params = {
            page: 1,
            limit: 100,
            search: 'type:service;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                const originalData = response?.data?.data || [];
                setServicePackagesArray(originalData);
                // Determine free services for the user

                setLoader(false);
            })
            .catch((error: any) => {
                setServicePackagesArray([]);
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const [tabIndex, setTabIndex] = useState<number>(0);
    const tabParam = searchParams.get('tab');
    const tabs = [
        t('pages.agency.extra_services.packageServices').text,
        t('pages.agency.extra_services.consultationsPackages').text,
        t('pages.agency.extra_services.separateServices').text,
    ];

    const [servicePackagesArray, setServicePackagesArray] = useState<string[]>([]);

    // *************** Consultation Service packages section ***************

    const [consultationPackagesArray, setConsultationPackagesArray] = useState<string[]>([]);

    const fetchConsultationPackages = (): void => {
        setLoader(true);

        const params = {
            page: 1,
            limit: 100,
            search: 'type:consultation;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                const originalData = response?.data?.data || [];
                setConsultationPackagesArray(originalData);
                // Determine free services for the user

                setLoader(false);
            })
            .catch((error: any) => {
                setConsultationPackagesArray([]);
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const resetIds = (): void => {
        setServicePackageValidationErrorId('');
        setConsultationPackageValidationErrorId('');
        setChosenPackage_id('');
        setChosenConsultaionPackage_id('');
    };

    useEffect(() => {
        resetIds();
        setValidationError(false);
        if (tabIndex === 0) {
            resetCart();
            fetchServicePackages();
        } else if (tabIndex === 1) {
            resetCart();
            fetchConsultationPackages();
        } else if (tabIndex === 2) {
            resetCart();
            getAllServices();
        }
    }, [tabIndex]);

    useEffect(() => {
        if (tabParam === 'packages') {
            setTabIndex(0);
        } else if (tabParam === 'consultations') {
            setTabIndex(1);
        } else if (tabParam === 'allPackages') {
            setTabIndex(2);
        }
    }, [tabParam]);

    useEffect(() => {
        if (state && state.tabIndex) {
            setTabIndex(state.tabIndex);
        }
    }, [state]);

    return (
        <>
            {loader && <Loader />}
            <Content>
                <ChooseFeature>
                    <div className="notes">
                        <div className="ad">
                            <p>{t('pages.agency.extra_services.packageAd').text}</p>
                        </div>
                        <div className="search-services">
                            {/* <div className="title-tooltip-wrapper">
                                <p className="chooseService">{t('pages.agency.extra_services.choose_service').text}</p>
                                <div className="tooltip-wrapper">
                                    <Tooltip
                                        text={t('pages.agency.extra_services.tooltip_text').text}
                                        position={!isTabletAndDown ? 'right' : 'bottom'}
                                        label={''}
                                    />
                                </div>
                            </div> */}
                            <div className="tabs">
                                {tabs.map((tab, index) => (
                                    <span
                                        className={`serviceHeader ${
                                            tabIndex === index && 'itemSelected'
                                        } smallerPadding`}
                                        key={index}
                                        onClick={() => {
                                            setTabIndex(index);
                                            if (index === 0) {
                                                setSearchParams({ tab: 'packages' });
                                            } else if (index === 1) {
                                                setSearchParams({ tab: 'consultations' });
                                            } else if (index === 2) {
                                                setSearchParams({ tab: 'allPackages' });
                                            }
                                        }}
                                    >
                                        <p>{tab}</p>
                                    </span>
                                ))}
                            </div>
                            {tabIndex === 2 && (
                                <div className="search-input">
                                    <FontAwesomeIcon icon={faSearch} />
                                    <Input
                                        type={InputComponentType.Text}
                                        onChange={(value: string) => {
                                            setSearchQuery(value);
                                        }}
                                        value={searchQuery}
                                        maxLength={50}
                                        placeholder={t('pages.agency.extra_services.search').text}
                                    />
                                </div>
                            )}
                        </div>
                        {tabIndex === 0 && (
                            <MainServicesWrapper>
                                <h1 className="header">{t('pages.agency.extra_services.packageServicesTitle').text}</h1>
                                <div className="cardWrapper">
                                    {servicePackagesArray.map((separatePackage: any, index: number) => (
                                        <ServiceCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys, chosenPackageId: string) => {
                                                setChosenPackage_id(chosenPackageId);
                                                setCart({
                                                    ...cart,
                                                    type: 'service',
                                                    items: chosenServices,
                                                });
                                            }}
                                            nextStep={() => {
                                                if (
                                                    cart.items.length === 0 ||
                                                    chosenPackage_id !== separatePackage.id
                                                ) {
                                                    setValidationError(true);
                                                    setServicePackageValidationErrorId(separatePackage.id);
                                                    return;
                                                }
                                                setServicePackageValidationErrorId('');
                                                setValidationError(false);
                                                nextStep();
                                                navigate('/guest/2', {
                                                    state: { fromTab: 'packages', tabIndex: 0 }, // Passing state
                                                });
                                            }}
                                            emptyCartError={servicePackageValidationErrorId === separatePackage.id}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 1 && (
                            <MainServicesWrapper>
                                <h1 className="header">
                                    {t('pages.agency.extra_services.consultationsPackagesTitle').text}
                                </h1>
                                <div className="cardWrapper">
                                    {consultationPackagesArray.map((separatePackage: any, index: number) => (
                                        <ConsultationsCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys, chosenPackageId: string) => {
                                                setChosenConsultaionPackage_id(chosenPackageId);
                                                setCart({
                                                    ...cart,
                                                    type: 'service',
                                                    items: [chosenServices],
                                                });
                                            }}
                                            nextStep={() => {
                                                if (
                                                    cart.items.length === 0 ||
                                                    chosenConsultaionPackage_id !== separatePackage.id
                                                ) {
                                                    setConsultationPackageValidationErrorId(separatePackage.id);
                                                    setValidationError(true);
                                                    return;
                                                }
                                                setConsultationPackageValidationErrorId('');
                                                setValidationError(false);
                                                nextStep();
                                                navigate('/guest/2', {
                                                    state: { fromTab: 'consultations', tabIndex: 1 }, // Passing state
                                                });
                                            }}
                                            emptyCartError={consultationPackageValidationErrorId === separatePackage.id}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 2 && (
                            <MainServicesWrapper>
                                <h1 className="header">
                                    {t('pages.agency.extra_services.separateServicesTitle').text}
                                </h1>
                                <div className="select-all">
                                    <Checkbox defaultChecked={selectAllChecked} onChange={toggleSelectAll} />
                                    <p>{t('pages.agency.extra_services.select_all').text}</p>
                                </div>
                                {services && filteredServices?.length > 0 ? (
                                    <FilteredContainer>
                                        {filteredServices.map((row: any) => {
                                            return (
                                                <>
                                                    <div
                                                        key={row.id}
                                                        className="rowHover"
                                                        onClick={() => handleServiceClick(row.id)}
                                                        style={{
                                                            backgroundColor:
                                                                expandedServiceId === row.id ? '#cebcff' : '',
                                                        }}
                                                    >
                                                        <span className="left" key={renderKey}>
                                                            <Checkbox
                                                                defaultChecked={getCheckedForService(row)}
                                                                onChange={() => {
                                                                    handleCheckbox(row);
                                                                }}
                                                            />
                                                            <span>{row[`name_${lang}`]}</span>
                                                        </span>
                                                        <div className="counter-parent">
                                                            <span className="right">
                                                                <span>
                                                                    {formatTwoDecimals(row.price.toString())} RSD
                                                                </span>
                                                            </span>
                                                            <div className="counter">
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterDecrease(row);
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                                <span>{getQuantityForService(row)}</span>
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterIncrease(row);
                                                                    }}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {expandedServiceId === row.id && (
                                                        <div className="description">{row[`description_${lang}`]}</div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </FilteredContainer>
                                ) : (
                                    <p>{t('pages.agency.extra_services.no_services').text}</p>
                                )}
                            </MainServicesWrapper>
                        )}
                    </div>
                    {validationError && tabIndex === 2 && cart.items.length === 0 ? (
                        <p className="validation-error">{t('pages.agency.extra_services.one_service_error').text}</p>
                    ) : null}
                    {tabIndex === 2 && (
                        <div className="services-footer-wrapper">
                            <div className="info">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <p>{t('pages.agency.extra_services.click_more_info').text}</p>
                            </div>
                        </div>
                    )}
                    <div className="services-footer-wrapper">
                        <StepControlButton
                            step={'login'}
                            handleClick={() => {
                                navigate('/login');
                            }}
                        />
                        {tabIndex === 2 && (
                            <StepControlButton
                                step={'next'}
                                handleClick={() => {
                                    if (cart.items.length === 0) {
                                        setValidationError(true);
                                        return;
                                    } else {
                                        setValidationError(false);
                                        nextStep();
                                        navigate('/guest/2', {
                                            state: { fromTab: 'all-services', tabIndex: 2 }, // Passing state
                                        });
                                    }
                                }}
                            />
                        )}
                    </div>
                </ChooseFeature>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    background: white;
    padding: 30px;
    p {
        font-weight: 400;
    }
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        padding: 0 0 1rem;
    }

    @media screen and (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 89.8vh;
    }
    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        min-height: 70vh;
    }
`;
const FilteredContainer = styled.div`
    max-height: 470px;
    overflow-y: auto;
    width: 100%;

    /* Styles for WebKit browsers */
    &::-webkit-scrollbar {
        width: 12px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #9b7fe9; /* Color of the track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a0a0a0; /* Color of the scrollbar thumb */
        border-radius: 6px; /* Roundness of the scrollbar thumb */
        border: 3px solid #9b7fe9; /* Creates padding around the scrollbar thumb */
    }

    /* Styles for Firefox */
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #9b7fe9 #f0f0f0; /* Thumb and track color */
`;
const ChooseFeature = styled.div`
    padding: 20px 0;
    text-align: center;
    @media only screen and (max-width: ${breakpoints.laptopM - 1}px) {
        padding: 0;
    }
    .select-all {
        display: flex;
        border-bottom: 1px solid var(--purple);
        p {
            font-size: 14px;
            cursor: default;
        }
    }
    .ad {
        text-align: left;
        padding-bottom: 10px;
        color: var(--purple);
        @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
            padding-bottom: 0;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            padding: 0 10px;

            @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
                padding: 20px 10px;
            }

            @media screen and (min-width: ${breakpoints.tablet}px) {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
    .notes {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .search-services {
            background-color: var(--purple);
            margin-bottom: 20px;
            text-align: -webkit-center;
            width: 100%;
            color: var(--white);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            height: 65px;
            padding: 10px 20px;
            font-size: 14px;
            text-transform: uppercase;
            .tabs {
                display: flex;
                gap: 20px;
                flex-direction: column;

                @media screen and (min-width: ${breakpoints.tablet}px) {
                    flex-direction: row;
                }
            }
            @media screen and (max-width: ${breakpoints.tablet - 1}px) {
                align-items: center;
                justify-content: center;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                align-items: center;
                flex-direction: column;
                gap: 0.5rem;
                height: auto;
            }
            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px) {
                flex-direction: row;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            .title-tooltip-wrapper {
                display: flex;
                align-items: center;
                gap: 1rem;
                .chooseService {
                    color: var(--white);
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .tooltip-wrapper {
                    div {
                        > span {
                            background-color: var(--white);
                            color: var(--purple);
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            display: block;
                            position: relative;
                            &::after {
                                content: '?';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-weight: 900;
                                font-size: 17px;
                            }
                            span {
                                width: 200px;
                            }
                        }
                    }
                }
            }

            .search-input {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                padding: 0 10px;
                background-color: var(--white);
                width: 100%;
                @media only screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
                    width: 30%;
                    justify-content: start;
                }
                @media only screen and (min-width: ${breakpoints.laptop - 1}px) {
                    width: auto;
                }
                svg {
                    color: var(--gray);
                    font-size: 15px;
                }
                .input {
                    width: 100%;
                    .input-container {
                        margin-bottom: 0;
                        input {
                            border-bottom: 0;
                            font-size: 15px;
                        }
                    }
                }
            }

            .serviceHeader {
                font-size: 14px;
                padding: 10px 20px;
                cursor: pointer;

                p {
                    font-size: 14px;
                }

                @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                    display: flex;
                    align-items: center;
                }
            }

            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                .smallerPadding {
                    padding: 0;
                }
            }

            .itemSelected {
                border-bottom: 3px solid var(--white);
                padding-top: 1px;
                padding: 10px 20px;
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            &:last-child {
                border-bottom: none;
            }
        }
        .rowHover {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            :hover {
                cursor: pointer;
                background-color: #cebcff;
                .left,
                .right {
                    color: var(--black);
                }
            }
        }
        .description {
            padding: 5px 5% 5px 4%;
            border-radius: 20px;
            margin-top: 5px;
            font-size: 13px;
            text-align: justify;
            font-style: italic;
        }
        .left {
            text-align: left;
            display: flex;
            max-width: 280px;
            color: var(--gray);
            font-size: 15px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 150px;
            }
            > div {
                > div {
                    margin-bottom: 0;
                }
            }
        }
        .right {
            text-align: right;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            color: var(--gray);
            font-size: 15px;
            .crosedText {
                text-decoration: line-through;
            }
        }
        .counter {
            display: flex;
            align-items: center;
            span {
                padding: 0 5px;
                border: 2px solid var(--purple);
            }
        }
        .counterBtn {
            border: none;
            padding: 5px 8px;
            cursor: pointer;
            margin: 0 5px;
            color: var(--purple);
            font-weight: bold;
            background-color: transparent;
        }
        .counterBtn:hover {
            color: var(--white);
        }
        .counter-parent {
            display: flex;
            align-items: center;
        }
    }
    .validation-error {
        color: red;
        margin-top: 1.5rem;
        font-size: 14px;
    }
    .services-footer-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            gap: 1.5rem;
        }
        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: ${colors.purple};
            justify-content: flex-start;
            p {
                font-size: 14px;
            }
        }
    }
`;

const MainServicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 50vh;

    .header {
        font-weight: 400;
        font-size: 32px;
        margin: 20px 0 40px 0;
    }

    .cardWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

        @media screen and (min-width: ${breakpoints.desktop}px) {
            justify-content: left;
        }
        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            justify-content: center;
        }
    }
`;

export default StepOne;
