import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../../../constants/breakpoints';
import { MQ_BREAKPOINTS } from '../../../../../constants/breakpoints';
import 'react-quill/dist/quill.snow.css';
import useAgencyStore from '../../../../../store/agency';

interface MemorandumProps {
    created_at?: string;
    heading?: string;
    id?: string;
    object?: string;
    status?: string;
    template: string;
    updated_at?: string;
    user?: any;
    user_id?: string;
}

const PreviewMemoModal: FunctionComponent<{ memorandum: MemorandumProps }> = ({ memorandum }) => {
    const { agency } = useAgencyStore();
    const appUrl = process.env.REACT_APP_URL;
    const logoUrl = agency.logo ? `${appUrl}/${agency.logo}` : null;
    const [renderKey, setRenderKey] = useState<number>(0);
    const [template, setTemplate] = useState<string>('');

    useEffect(() => {
        // Ensures the template refreshes when updated
        setRenderKey(renderKey + 1);

        // Process the template with the logo
        if (memorandum?.template) {
            const updatedTemplate = processTemplateWithLogo(memorandum.template);
            setTemplate(updatedTemplate); // Update the processed template
        }
    }, [memorandum]);

    const processTemplateWithLogo = (html: string): string => {
        const imgTag = logoUrl ? `<p class="ql-align-center"><img src="${logoUrl}" /></p>` : '';
        const regex =
            /<(div|p)[^>]*class=["']ql-align-center["'][^>]*>\s*<img[^>]*src=["'][^"']*["'][^>]*>\s*<\/(div|p)>/gi;

        // Remove any existing <div> or <p> with <img>
        const cleanedHtml = html.replace(regex, '');

        if (logoUrl) {
            // Insert the new <p> tag at the beginning
            return `${imgTag}\n${cleanedHtml}`;
        }

        // If logoUrl doesn't exist, just return the cleaned HTML
        return cleanedHtml;
    };

    return (
        <Container>
            <TemplateContent dangerouslySetInnerHTML={{ __html: template }} key={renderKey}></TemplateContent>
        </Container>
    );
};

export default PreviewMemoModal;

const Container = styled.div`
    background-color: #fff;
    padding: 20px 40px 40px;
    color: black;
    font-family: Arial, sans-serif;
    min-height: 95vh;
    max-height: 95vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ::-webkit-scrollbar {
        width: 10px; /* Adjust the width of the scrollbar */
    }

    /* Scrollbar Track - the area the thumb moves in */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* Track color */
        border-radius: 10px; /* Rounded corners for the track */
    }
    /* Scrollbar Thumb - the draggable part */
    ::-webkit-scrollbar-thumb {
        background-color: var(--purple); /* Thumb color */
        border-radius: 10px; /* Rounded corners for the thumb */
        border: 2px solid #f1f1f1; /* Add border around the thumb */
    }

    .ql-align-center {
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 10px;
    }

    @media only screen and (min-width: ${breakpoints.mobileL + 1}px) {
        min-width: 80vw;
        max-width: 90vw;
    }
    @media only screen and (min-width: ${breakpoints.tablet}px) {
        min-width: 70vw;
        max-width: 70vw;
        padding: 15px;
    }
    @media only screen and (min-width: ${breakpoints.laptop}px) {
        min-width: 50vw;
        max-width: 50vw;
        min-height: 105vh;
        max-height: 105vh;
    }
    @media only screen and (min-width: ${breakpoints.laptopL}px) {
        min-width: 35vw;
        max-width: 35vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}px) {
        min-width: 35vw;
        max-width: 35vw;
        min-height: 95vh;
        max-height: 95vh;
    }
    @media only screen and (min-width: ${breakpoints.desktopL}px) {
        min-width: 35vw;
        max-width: 35vw;
    }
    @media only screen and (min-width: ${breakpoints.ultraWide}px) {
        min-width: 30vw;
        max-width: 30vw;
    }
`;

const TemplateContent = styled.div`
    img {
        max-width: 150px;
    }
    /* Preserve Quill's styles */
    .ql-align-center {
        text-align: center;
        font-size: 12px;
    }

    /* Avoid overriding Quill's styles */
    all: unset;
    display: block;

    * {
        all: revert;
    }
`;
